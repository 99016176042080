import { AnimatePresence } from "framer-motion";
import { useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import Login from "../src/app/login/index";
import Onboarding from "../src/app/onboarding/index";
import AboutUs from "./app/aboutUs";
import ContactUS from "./app/contactUs";
import Home from "./app/home";
import Community from "./app/home/Sections/community";
import ResetPasswordScreen from "./app/reset-password";
import LoadingSpinner from "./components/loading/LoadingSpinner";
import { ROLE } from "./constants/common";
import {
    ABOUT,
    ADMIN,
    AUDIT,
    BLOGS,
    CALCULATOR,
    COMMUNITY,
    CONTACTUS,
    DASHBOARD,
    LANDING,
    LEARN,
    LEASE_DETAILS,
    LOGIN,
    NOT_FOUND,
    ONBOARDING,
    PAYMENT,
    PRICING_LABELS,
    PRIVACY,
    PROFILE,
    PROPOSAL,
    PURCHASE_DETAILS,
    RESET_SCREEN,
    SURVEY_VIEW,
    TERMS_N_CONDITIONS,
    UN_AUTHORIZED,
    UPDATE_MASTER_DATA,
} from "./constants/routes";
import { useContextState } from "./context/ContextProvider";
import useAuth from "./hooks/useAuth";
import Blogs from "./pages/blogs";
import BlogDetails from "./pages/blogs/BlogDetails";

import Commercial from "./app/pricing/commercial";
import IndividualHomes from "./app/pricing/individual-homes";
import Industrial from "./app/pricing/industrial";
import ResidentialSociety from "./app/pricing/residential-society";
import Profile from "./app/profile";
import Proposal from "./app/proposal";
import TermsAndConditions from "./app/terms-conditions/index";
import ProposalCalculator from "./pages/calculator/proposal";
import Learn from "./pages/learn";
import NotFound from "./pages/notfound";
import AdminSurvey from "./pages/onboarding/admin";
import AgentSurvey from "./pages/onboarding/agent";
import Audit from "./pages/portal/admin/audit";
import Payment from "./pages/portal/admin/payment";
import PaymentView from "./pages/portal/admin/payment/paymentView";
import GenerateProposal from "./pages/portal/admin/proposal/GenerateProposal";
import SaveProposal from "./pages/portal/admin/proposal/SaveProposal";
import ViewProposal from "./pages/portal/admin/proposal/ViewProposal";
import AgentSurveyOverview from "./pages/portal/agent/survey";
import SurveyOverview from "./pages/portal/agent/survey/SurveyOverview";

import DashBoard from "./app/dashboard/index.js";
import LeaseProposalDetails from "./app/proposal/lease/index.js";
import PurchaseProposalDetails from "./app/proposal/purchase/index.js";

import Admin from "./app/admin/index.js";
import PropertyDetails from "./app/property/index.js";
import RevisionProposal from "./app/proposal/revision/index.js";
import TicketsManager from "./app/ticketsManager/index.js";
import EditProposalData from "./pages/portal/edit-proposal";
import ProposalMasterData from "./pages/portal/proposal/ProposalMasterData";
import Revision from "./pages/portal/revision";
import Privacy from "./pages/privacy";
import UnAuthorized from "./pages/unahorized";
import PrivateRoute from "./routes/PrivateRoute";
import auth from "./services/auth";
const routeConfigs = [
    {
        path: LANDING,
        RenderComponent: Home,
        isAuthReq: false,
        exact: true,
    },
    {
        path: `${PROPOSAL}/:id`,
        RenderComponent: Proposal,
        isAuthReq: false,
        exact: true,
    },
    {
        path: `${PROPOSAL}/:plantId/:proposalId/revision`,
        RenderComponent: RevisionProposal,
        isAuthReq: false,
        exact: true,
    },
    {
        path: `${PURCHASE_DETAILS}/:plantId/:proposalId`,
        RenderComponent: PurchaseProposalDetails,
        isAuthReq: false,
        exact: true,
    },
    {
        path: `${LEASE_DETAILS}/:plantId/:proposalId`,
        RenderComponent: LeaseProposalDetails,
        isAuthReq: false,
        exact: true,
    },
    {
        path: LOGIN,
        RenderComponent: Login,
        isAuthReq: false,
        exact: true,
    },
    {
        path: ONBOARDING,
        RenderComponent: Onboarding,
        isAuthReq: false,
        exact: true,
    },
    {
        path: ABOUT,
        RenderComponent: AboutUs,
        isAuthReq: false,
        exact: true,
    },
    {
        path: BLOGS,
        RenderComponent: Blogs,
        isAuthReq: false,
        exact: true,
    },
    {
        path: COMMUNITY,
        RenderComponent: Community,
        isAuthReq: false,
        exact: true,
    },
    {
        path: `${BLOGS}/:id`,
        RenderComponent: BlogDetails,
        isAuthReq: false,
        exact: true,
    },
    {
        path: PRICING_LABELS.INDIVIDUAL_HOMES,
        RenderComponent: IndividualHomes,
        isAuthReq: false,
        exact: true,
    },
    {
        path: PRICING_LABELS.RESIDENTIAL_SOCIETIES,
        RenderComponent: ResidentialSociety,
        isAuthReq: false,
        exact: true,
    },
    {
        path: PRICING_LABELS.COMMERCIAL,
        RenderComponent: Commercial,
        isAuthReq: false,
        exact: true,
    },
    {
        path: PRICING_LABELS.INDUSTRIAL,
        RenderComponent: Industrial,
        isAuthReq: false,
        exact: true,
    },
    {
        path: LEARN,
        RenderComponent: Learn,
        isAuthReq: false,
        exact: true,
    },
    // {
    //     path: FAQ,
    //     RenderComponent: FrequentQuestion,
    //     isAuthReq: false,
    // },
    {
        path: TERMS_N_CONDITIONS,
        RenderComponent: TermsAndConditions,
        isAuthReq: false,
    },
    {
        path: PRIVACY,
        RenderComponent: Privacy,
        isAuthReq: false,
    },
    {
        path: CONTACTUS,
        RenderComponent: ContactUS,
        isAuthReq: false,
    },
    // {
    //     path: PROJECTS,
    //     RenderComponent: Projects,
    //     isAuthReq: false, // change
    //     exact: true,
    // },
    // {
    //     path: `${PROJECTS}/:id`,
    //     RenderComponent: ProjectDetails,
    //     isAuthReq: false, // change
    //     exact: true,
    // },
    // {
    //     path: PORTFOLIO,
    //     RenderComponent: Portfolio,
    //     isAuthReq: false, // change
    //     exact: true,
    // },
    // {
    //     path: `${PORTFOLIO}/:id`,
    //     RenderComponent: PortfolioDetails,
    //     isAuthReq: false, // change
    //     exact: true,
    // },
    {
        path: DASHBOARD,
        RenderComponent: DashBoard,
        isAuthReq: true,
        exact: true,
        allowed: [ROLE.SALES_MANAGER, ROLE.SUPER_ADMIN, ROLE.OPERATION_MANAGER],
    },
    {
        path: "/revision-request",
        RenderComponent: Revision,
        isAuthReq: false,
        exact: true,
    },
    {
        path: UPDATE_MASTER_DATA,
        RenderComponent: ProposalMasterData,
        isAuthReq: true,
        exact: true,
        allowed: [ROLE.SUPER_ADMIN],
    },
    {
        path: AUDIT,
        RenderComponent: Audit,
        isAuthReq: false,
        exact: true,
        allowed: [ROLE.SUPER_ADMIN, ROLE.SALES_MANAGER],
    },
    {
        path: PAYMENT,
        RenderComponent: Payment,
        isAuthReq: false,
        exact: true,
        allowed: [ROLE.SUPER_ADMIN, ROLE.SALES_MANAGER],
    },
    {
        path: `${PAYMENT}/:paymentId`,
        RenderComponent: PaymentView,
        isAuthReq: false,
        exact: true,
        allowed: [ROLE.SUPER_ADMIN, ROLE.SALES_MANAGER],
    },
    {
        path: SURVEY_VIEW,
        RenderComponent: AgentSurveyOverview,
        isAuthReq: false,
        exact: true,
        allowed: [ROLE.SUPER_ADMIN, ROLE.SALES_MANAGER],
    },
    {
        path: `${DASHBOARD}/:propertyId`,
        RenderComponent: PropertyDetails,
        isAuthReq: true,
        exact: true,
        allowed: [ROLE.SUPER_ADMIN, ROLE.SALES_MANAGER],
    },
    {
        path: `/:generateId/generate`,
        RenderComponent: GenerateProposal,
        isAuthReq: true,
        exact: true,
        allowed: [ROLE.SUPER_ADMIN, ROLE.SALES_MANAGER],
    },
    {
        path: `/:generateId/save`,
        RenderComponent: SaveProposal,
        isAuthReq: true,
        exact: true,
        allowed: [ROLE.SUPER_ADMIN, ROLE.SALES_MANAGER],
    },
    {
        path: `/:generateId/viewProposal`,
        RenderComponent: ViewProposal,
        isAuthReq: true,
        exact: true,
        allowed: [ROLE.SUPER_ADMIN, ROLE.SALES_MANAGER],
    },

    {
        path: "/:generateId/editProposal",
        RenderComponent: EditProposalData,

        isAuthReq: true,
        exact: true,
        allowed: [ROLE.SUPER_ADMIN, ROLE.SALES_MANAGER],
    },

    {
        path: `/:generateId/update`,
        RenderComponent: EditProposalData,
        isAuthReq: true,
        exact: true,
        allowed: [ROLE.SUPER_ADMIN, ROLE.SALES_MANAGER],
    },
    {
        path: `${ADMIN}`,
        RenderComponent: Admin,
        isAuthReq: true,
        exact: true,
    },
    {
        path: `/tickets`,
        RenderComponent: TicketsManager,
        isAuthReq: true,
        exact: true,
    },
    {
        path: `${SURVEY_VIEW}/:surveyId`,
        RenderComponent: SurveyOverview,
        isAuthReq: false,
        exact: true,
        allowed: [ROLE.SUPER_ADMIN, ROLE.SALES_MANAGER],
    },
    {
        path: `${CALCULATOR}${PROPOSAL}`,
        RenderComponent: ProposalCalculator,
        isAuthReq: true,
        exact: true,
        allowed: [ROLE.SALES_MANAGER, ROLE.SUPER_ADMIN, ROLE.OPERATION_MANAGER],
    },

    // {
    //     path: WALLET,
    //     RenderComponent: Wallet,
    //     isAuthReq: true,
    // },
    {
        path: PROFILE,
        RenderComponent: Profile,
        isAuthReq: false,
        exact: true,
    },
    // {
    //     path: VERIFY_EMAIL,
    //     RenderComponent: VerifyEmail,
    //     isAuthReq: false,
    // },
    // {
    //     path: OFFSET,
    //     RenderComponent: Offset,
    //     isAuthReq: false,
    //     exact: true,
    // },
    // {
    //     path: ERROR_SCREEN,
    //     RenderComponent: ErrorFallback,
    //     isAuthReq: false,
    // },
    // {
    //     path: THANK_YOU,
    //     RenderComponent: ThankYou,
    //     isAuthReq: false,
    // },

    {
        path: `${ONBOARDING}/:onboardTopic`,
        RenderComponent: Onboarding,
        isAuthReq: false,
        exact: true,
    },
    {
        path: `/survey/:plantId`,
        RenderComponent: AgentSurvey,
        isAuthReq: true,
        exact: true,
        allowed: [ROLE.SALES_MANAGER, ROLE.SUPER_ADMIN, ROLE.OPERATION_MANAGER],
    },
    {
        path: `/admin/survey/:plantId`,
        RenderComponent: AdminSurvey,
        isAuthReq: true,
        exact: true,
        allowed: [ROLE.SALES_MANAGER, ROLE.SUPER_ADMIN],
    },
    // {
    //     path: SURVEY,
    //     RenderComponent: AgentSurvey,
    //     isAuthReq: false,
    // },

    {
        path: NOT_FOUND,
        RenderComponent: NotFound,
        isAuthReq: false,
    },
    {
        path: UN_AUTHORIZED,
        RenderComponent: UnAuthorized,
        isAuthReq: false,
    },
    {
        path: RESET_SCREEN,
        RenderComponent: ResetPasswordScreen,
        isAuthReq: false,
    },
];

const RouteComponent = ({
    RenderComponent,
    isAuthReq,
    redirectIfAuth,
    allowed,
    checkPlant = false,
    ...restProps
}) => {
    const { state, dispatch } = useContextState();
    const { isAuth } = useAuth(restProps?.location?.pathname, dispatch);
    const [showContent, setShowContent] = useState(false);

    useEffect(() => {
        // Fetch user state on reload on a page when user is loggedin
        if (!state.user && isAuth) {
            auth.getUserProfile(dispatch);
        }
    }, [dispatch, state.user, isAuth]);

    // useEffect(() => {
    //     if (isAuth !== null && !showContent) {
    //         setTimeout(() => {
    //             setShowContent(true);
    //         }, 5000);
    //     }
    // }, [showContent, isAuth]);

    if (isAuth === null) {
        return (
            <div>
                <LoadingSpinner />
            </div>
        );
    }

    // if (isAuth !== null && !showContent) {
    //     return (
    //         <div>
    //             <IntroScreen />
    //         </div>
    //     );
    // }
    return (
        <AnimatePresence>
            <Route
                {...restProps}
                render={(props) =>
                    isAuth ? (
                        <PrivateRoute
                            exact={true}
                            path={restProps?.location?.pathname}
                            component={RenderComponent}
                            allowed={allowed}
                            checkPlant={checkPlant}
                            {...restProps}
                        />
                    ) : isAuthReq ? (
                        <Redirect
                            to={{
                                pathname: LANDING,
                                state: { from: props.location },
                            }}
                        />
                    ) : (
                        <RenderComponent {...props} />
                    )
                }
            />
        </AnimatePresence>
    );
};

export const ScrollToTop = () => {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

const Routes = () => {
    return (
        <>
            <ScrollToTop />
            <Switch>
                {routeConfigs.map((routeConfig) => (
                    <RouteComponent {...routeConfig} />
                ))}
                <Redirect to={NOT_FOUND} />
            </Switch>
        </>
    );
};

export default Routes;
