import React from "react";
import P from "../../../shared/typography/P";

const Confirmation = ({ img, heading, message, children }) => {
    return (
        <div className="flex flex-col items-center justify-center  rounded-sm border border-gray-250 p-2 xl:p-2.5 2xl:p-3.2 bg-white h-full">
            <P className="">{heading}</P>
            <P className=" text-secondary text-sm sm:text-sm xl:text-base 2xl:text-base  mt-0.8">
                {message}
            </P>
            <div className=" w-[100px]  xl:w-[120px] 2xl:w-[150px] xl:mt-2 2xl:mt-2.4">
                <img className="w-full" src={img} alt="confirmation" />
            </div>

            <div className="flex gap-1 xl:gap-2 sm:mt-2 lg:mt-2.4 2xl:mt-3 w-full">
                {children}
            </div>
        </div>
    );
};

export default Confirmation;
