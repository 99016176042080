import React from "react";
import { ROLE } from "../../../constants/common";
import { useContextState } from "../../../context/ContextProvider";
import { checkIsAllowed } from "../../../helpers/helpers";
import { envelopeClosed, phoneOutline } from "../../../resources/js/icons";
import H3 from "../../../shared/typography/H3";
import P from "../../../shared/typography/P";
import Tag from "../../../shared/ui/tag/Tag";

const ProfileCard = () => {
    const { state } = useContextState();
    const user = state?.user;
    return (
        <div className="bg-white shadow-md rounded p-1.6 ">
            <div className="flex  flex-wrap justify-between  items-center w-full  ">
                <div className=" flex xl:items-center flex-wrap  gap-1 w-full xl:w-[55%] ">
                    <div className=" flex items-center justify-center w-[32px] h-[32px] xl:w-[80px] xl:h-[80px] rounded-full bg-gray-300 ">
                        <H3 className="text-white xl:text-5.8xl ">
                            {user?.name.charAt(0)}
                        </H3>
                    </div>
                    <div className="flex-1">
                        <div className="flex justify-between gap-0.5 items-center w-full">
                            <P className="line-clamp-1 overflow-hidden">
                                {user?.name}
                            </P>
                            <Tag
                                text={
                                    checkIsAllowed(user?.roles, [
                                        ROLE.CONSUMER_OWNER,
                                    ])
                                        ? "Owner"
                                        : "User"
                                }
                                className="bg-gray-120 text-gray-140 bg-opacity-10 rounded-full border border-gray-120 xl:text-sm 2xl:text-sm"
                            />
                        </div>

                        <div className="sm:flex sm:gap-2 xl:block xl:mt-1 2xl:mt-0.5 ">
                            <div className="flex items-center gap-0.5">
                                <img
                                    className="h-[14px]"
                                    src={phoneOutline}
                                    alt="envelop_closed"
                                />
                                <P className="text-secondary text-sm md:text-sm xl:text-sm 2xl:text-sm">
                                    {user?.phoneNumber}
                                </P>
                            </div>
                            <div className="flex items-center gap-0.5 mt-0.5 sm:mt-0 xl:mt-0.6">
                                <img
                                    className="h-[14px]"
                                    src={envelopeClosed}
                                    alt="envelop_closed"
                                />
                                <P className="text-secondary text-sm md:text-sm xl:text-sm 2xl:text-sm">
                                    {user?.email}
                                </P>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="xl:inline-block w-full h-px xl:h-[50px]  xl:w-px bg-blue-300 my-1.2  xl:my-1.6"></div>
                <div className="flex flex-col xl:w-[40%]  xl:justify-end xl:items-end">
                    <div>
                        {user?.plant && (
                            <Tag
                                text={user?.plant?.name}
                                className="bg-orange text-orange bg-opacity-10 rounded-full border border-orange xl:text-sm 2xl:text-sm inline-block"
                            />
                        )}
                    </div>

                    <P className="mt-0.8 xl:text-right text-sm md:text-sm xl:text-sm 2xl:text-sm text-secondary">
                        {user?.address}
                    </P>
                </div>
            </div>
        </div>
    );
};

export default ProfileCard;
