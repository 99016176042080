import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
    connectionTypeToCategory,
    propertyList,
} from "../../constants/InvestmentConstant";
import { SALES_PROPOSAL_BASE_ENDPOINT } from "../../constants/routes";
import request from "../../services/request";
import Container from "../../shared/Container";
import ProposalTable from "./ProposalTable";
import SideBar from "./SideBar";

const PropertyContent = () => {
    const [selectedConnectionType, setSelectedConnectionType] = useState(
        connectionTypeToCategory.RESIDENTIAL_SOCIETY
    );
    const [consumersBill, setConsumersBill] = useState(null);
    const [totalUnits, setTotalUnits] = useState(0);
    const [isProposalGenerated, setIsProposalGenerated] = useState(false);

    const initialCheckedState =
        selectedConnectionType === connectionTypeToCategory.RESIDENTIAL_SOCIETY
            ? { purchase: true, lease: true }
            : selectedConnectionType ===
                  connectionTypeToCategory.INDIVIDUAL_HOMES ||
              selectedConnectionType === connectionTypeToCategory.INDUSTRIAL
            ? { purchase: true, loan: true }
            : { purchase: true, lease: true, loan: true };

    const [checkedState, setCheckedState] = useState(initialCheckedState);
    const selectedOptions = Object.keys(checkedState)
        .filter((key) => checkedState[key])
        .map((option) => option.charAt(0).toUpperCase() + option.slice(1));

    const handleCheckboxChange = (key) => {
        const checkedCount = Object.values(checkedState).filter(Boolean).length;

        if (checkedCount === 1 && checkedState[key]) {
            toast.warn("At least one option must remain selected.");
            return;
        }
        setCheckedState((prevState) => ({
            ...prevState,
            [key]: !prevState[key],
        }));
    };

    const isServerCalculation =
        selectedConnectionType ===
            connectionTypeToCategory.RESIDENTIAL_SOCIETY ||
        selectedConnectionType === connectionTypeToCategory.INDIVIDUAL_HOMES;

    const fetchBillAmount = useCallback(async () => {
        try {
            if (isServerCalculation && !isNaN(totalUnits)) {
                let res = await request.authGet({
                    endpoint: `${SALES_PROPOSAL_BASE_ENDPOINT}/getBill/residential/${totalUnits}`,
                });
                let bill = res?.data?.billAmount.toFixed(2);
                if (bill) {
                    setConsumersBill(bill);
                } else {
                    toast.error(
                        "Can't fetch bill amount. Please try again later"
                    );
                }
            }
        } catch (error) {
            toast.error("An error occurred while fetching the bill amount.");
        }
    }, [totalUnits, isServerCalculation]);

    const handleConnectionTypeChange = (selected) => {
        setSelectedConnectionType(selected);
    };

    const handleTotalUnitsChange = (e) => {
        setTotalUnits(e.target.value);
    };

    useEffect(() => {
        fetchBillAmount();
    }, [totalUnits]);

    useEffect(() => {
        setCheckedState(
            selectedConnectionType ===
                connectionTypeToCategory.RESIDENTIAL_SOCIETY
                ? { purchase: true, lease: true }
                : selectedConnectionType ===
                      connectionTypeToCategory.INDIVIDUAL_HOMES ||
                  selectedConnectionType === connectionTypeToCategory.INDUSTRIAL
                ? { purchase: true, loan: true }
                : { purchase: true, lease: true, loan: true }
        );
    }, [selectedConnectionType]);

    const generateProposal = () => {
        setIsProposalGenerated(true);
    };

    return (
        <Container className="px-0 mb-4">
            <div className="bg-white rounded-lg items-stretch p-1 border md:px-1.5 md:py-1.5 flex flex-col md:flex-row gap-1.6 border-gray-200">
                <SideBar
                    selectedConnectionType={selectedConnectionType}
                    onConnectionTypeChange={handleConnectionTypeChange}
                    consumersBill={consumersBill}
                    generateProposal={generateProposal}
                    totalUnits={totalUnits}
                    onTotalUnitsChange={handleTotalUnitsChange}
                    propertyList={propertyList}
                    checkedState={checkedState}
                    handleCheckboxChange={handleCheckboxChange}
                />
                <ProposalTable
                    connectionType={
                        isProposalGenerated ? selectedConnectionType : "XXXX"
                    }
                    totalUnits={isProposalGenerated ? totalUnits : "XXXX"}
                    consumersBill={isProposalGenerated ? consumersBill : "XXXX"}
                    selectedOptions={
                        isProposalGenerated
                            ? selectedOptions
                            : ["Purchase", "Lease", "Loan"]
                    }
                    isProposalGenerated={isProposalGenerated}
                    checkedState={
                        isProposalGenerated
                            ? checkedState
                            : { purchase: false, lease: false, loan: false }
                    }
                />
            </div>
        </Container>
    );
};

export default PropertyContent;
