import React from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useHistory, useParams } from "react-router-dom";
import Icon from "../../../components/Icon/Icon";
import Space from "../../../components/space/Space";
import Container from "../../../components/styles/Container.styles";
import { Section } from "../../../components/styles/Elements.styles";
import { H4, P } from "../../../components/styles/Typography.styles";
import PropsalEdit from "../proposal/ProposalEdit";

const EditProposalData = () => {
    const { generateId } = useParams();
    const history = useHistory();

    return (
        <div>
            <Section height="auto">
                <Container>
                    <Space
                        xxsm={["30px 100rem 30px 0"]}
                        xlm={["50px 100rem 30px 0 "]}
                    >
                        <div
                            onClick={() =>
                                history.push(`/${generateId}/generate`)
                            }
                            style={{
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Space xxsm={["0 1rem 0 0"]} stm={["0 2rem 0 0"]}>
                                <div>
                                    <Icon component={BsArrowLeft} lg="l" />
                                </div>
                            </Space>
                            <div>
                                <P>Back</P>
                            </div>
                        </div>
                    </Space>

                    <Space m={["10px 0 30px"]}>
                        <H4> Update Proposal</H4>
                    </Space>
                    <PropsalEdit />
                </Container>
            </Section>
        </div>
    );
};

export default EditProposalData;
