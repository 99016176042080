import { toast } from "react-toastify";
import { ERROR_MSG } from "../constants/common";
import { SET_ERROR } from "../constants/contexConstant";
import { emailRegEx } from "../constants/regularExpression";
import auth from "./auth";

export const handleResentOTP = async (
    mode,
    credentials,
    setLoading,
    dispatch
) => {
    setLoading(true);

    let val = emailRegEx.test(credentials) ? credentials : `91${credentials}`;
    try {
        await auth.loginOTP({
            username: val,
            type: "retry",
            mode,
        });
        if (mode === "text") {
            toast.success("OTP has been sent", {
                containerId: "recentOtp",
            });
        } else {
            toast.success("Call has been sent", {
                containerId: "recentOtp",
            });
        }

        setLoading(false);
    } catch (error) {
        setLoading(false);

        dispatch({
            type: SET_ERROR,
            payload: typeof error === "string" ? error : ERROR_MSG,
        });
    }
};
