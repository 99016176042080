import {
    faCheckCircle,
    faTimesCircle,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import Button from "../../shared/ui/Button";
import { Flex } from "../styles/Elements.styles";
import { InlineText } from "../text/HeadingText";
import Text from "../text/Text";
const AlertBox = styled.div`
    background: ${(props) => props.theme.color.pureWhite};
    padding: 7px 20px;
    border-left: 5px solid
        ${(props) =>
            props.error
                ? props.theme.color.error
                : props.theme.color.lightPrimary};
    border-radius: 5px;
    margin: ${(props) => props.margin};
    box-shadow: rgba(99, 99, 99, 0.2) 1px 3px 6px 2px;
`;

const AlertMessage = ({
    message,
    error = false,
    onClick,
    margin = "30px 0px",
}) => {
    return (
        <AlertBox margin={margin} error={error}>
            <Flex.Container align="center">
                <Flex.Item>
                    <InlineText
                        size="xl"
                        color={error ? "red200" : "lightPrimary"}
                    >
                        <FontAwesomeIcon
                            icon={error ? faTimesCircle : faCheckCircle}
                        />
                    </InlineText>
                </Flex.Item>
                <Flex.Item style={{ margin: " 0px 20px", flex: "1" }}>
                    <Text weight="bold" color="sharkGrey" size="m">
                        {error ? "Error" : "Success"}
                    </Text>
                    <Text color="grey" size="s">
                        {message}
                    </Text>
                </Flex.Item>
                {onClick && (
                    <Flex.Item>
                        <Button onClick={onClick}>Close</Button>
                    </Flex.Item>
                )}
            </Flex.Container>
        </AlertBox>
    );
};

export default AlertMessage;
