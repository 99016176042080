import React from "react";
import DetailsHolder from "../proposalDetails/DetailsHolder";
import PurchaseWrapper from "./PurchaseWrapper";

const PurchaseProposalDetails = () => {
    return (
        <DetailsHolder>
            <PurchaseWrapper />
        </DetailsHolder>
    );
};

export default PurchaseProposalDetails;
