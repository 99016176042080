import React, { useCallback, useState } from "react";
import { IoIosAddCircleOutline, IoMdRemoveCircleOutline } from "react-icons/io";
import cn from "../../lib/cn";
import P from "../../shared/typography/P";

const wrapperBase = "bg-white p-1.6 mb-3.2";

const Accordion = ({
    isControlled,
    label,
    labelClass,
    wrapperClass,
    contentMargin = "mt-3.2",
    isOpen,
    closedIcon: ClosedIcon,
    rotateClass = "rotate-180",
    children,
}) => {
    const [openState, setOpenState] = useState(isOpen || false);

    const clickHandler = useCallback(() => {
        if (isControlled) return;
        setOpenState(!openState);
    }, [isControlled, openState]);

    const open = isControlled ? isOpen : openState;

    return (
        <div className={cn(wrapperBase, wrapperClass)} active={openState}>
            <div
                className="flex justify-between items-center gap-5 hover:cursor-pointer"
                onClick={clickHandler}
            >
                <P className={` ${labelClass}`}>{label}</P>
                {isControlled ? null : (
                    <div
                        className={`${
                            open ? rotateClass : " rotate-0"
                        } transition-all duration-500 text-xl`}
                    >
                        {ClosedIcon && <ClosedIcon />}
                        {!ClosedIcon && (
                            <React.Fragment>
                                {open ? (
                                    <IoMdRemoveCircleOutline className="text-blue-400" />
                                ) : (
                                    <IoIosAddCircleOutline className="text-blue-400" />
                                )}
                            </React.Fragment>
                        )}
                    </div>
                )}
            </div>
            <div
                style={{
                    maxHeight: open ? "5000px" : 0,
                }}
                className={`${
                    open ? contentMargin : "mt-0"
                } ] overflow-hidden transition-all ease-in duration-300 `}
            >
                <div>{children}</div>
            </div>
        </div>
    );
};

export default Accordion;
