import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AlertMessage from "../../../components/alert/AlertMessage";
import Loading from "../../../components/loading/Loading";
import {
    LANDING,
    PROPOSAL_DOCUMENT_META_ENDPOINT,
} from "../../../constants/routes";
import useNetwork from "../../../hooks/useNetwork";
import {
    IconDuration,
    IconInsurance,
    IconInvestment,
    IconMaintenance,
    IconSubsidy,
    IconTransfer,
} from "../../../resources/js/icons";
import Container from "../../../shared/Container";
import H1 from "../../../shared/typography/H1";
import TableComponent from "../../../shared/ui/Table";
import BillSummary from "../proposalDetails/BillSummary";
import BorderedContainer from "../proposalDetails/BorderedContainer";
import DetailsTable from "../proposalDetails/DetailsTable";
import DetailsWrapper from "../proposalDetails/DetailsWrapper";
import Features from "../proposalDetails/Features";
import Heading from "../proposalDetails/Heading";
import Savings from "../proposalDetails/Savings";
import TermsAndConditions from "../proposalDetails/TermsAndConditions";

let getOfferingFeatures = (availSubsidy, plantDuration) => {
    return [
        {
            icon: IconInvestment,
            title: "Upfront Investment",
            value: "ZERO",
        },
        {
            icon: IconSubsidy,
            title: "Avail Subsidy",
            value: availSubsidy,
        },
        {
            icon: IconTransfer,
            title: "Plan Duration",
            value: plantDuration,
        },
        {
            icon: IconDuration,
            title: "Ownership Transfer",
            value: `After ${plantDuration} Years`,
        },
        {
            icon: IconMaintenance,
            title: "Maintenance",
            value: "FREE",
        },
        {
            icon: IconInsurance,
            title: "Insurance",
            value: "FREE",
        },
    ];
};
const columns = ["Description", "Plan Details"];

let getLeasePlantDetails = (data) => {
    return [
        {
            Description: "Fixed Monthly Rental",
            "Plan Details": (
                <>
                    <span className="line-through text-gray-400 mr-2">
                        {data?.averageMonthlyBill}
                    </span>
                    <span className="font-bold text-gray-900">
                        {data?.fixedMonthlyRental}
                    </span>
                </>
            ),
        },
        { Description: "Bank Guarantee", "Plan Details": data?.bankGuarantee },
        {
            Description: "Savings on Existing Bills",
            "Plan Details": `${data?.savingsOnExistingBill}%`,
        },
        {
            Description: "Government Subsidy",
            "Plan Details": data?.governmentSubsidy,
        },
        {
            Description: "Effective Savings Per Month",
            "Plan Details": data?.effectiveSavingsPerMonth,
        },
    ];
};

let buildBillingSummary = (
    averageMonthlyBill,
    unitConsumption,
    solarPlantRequired
) => {
    return [
        {
            title: "Average monthly Bill",
            value: `${averageMonthlyBill}`,
            unit: "",
        },
        {
            title: "Unit Consumption",
            value: `${unitConsumption}`,
            unit: "kWh",
        },
        {
            title: "Solar Plant Required",
            value: solarPlantRequired,
            unit: "KWp",
        },
    ];
};

let buildSavingsAtGlanceTableData = (data) => {
    return [
        {
            title: "Monthly Saving",
            value: data?.monthlySavings,
            unit: "",
        },
        {
            title: "Effective Savings Percentage",
            value: data?.effectiveSavingsPerMonth,
            unit: "",
        },
        {
            title: "Saving in 5 Years",
            value: `${data?.savingsWithPlan} + ${data?.savingsWithGovernmentSubsidy}`,
            unit: "",
        },
        {
            title: "Lifetime Saving*",
            value: data?.lifeTimeSavings,
            unit: "",
        },
    ];
};
const LeaseWrapper = () => {
    const { plantId } = useParams();
    const { proposalId } = useParams();
    const [plant] = useState(plantId);
    const history = useHistory();
    const [proposal] = useState(proposalId);
    const [lease, setLease] = useState(null);

    const { data, error, loading } = useNetwork(
        `${PROPOSAL_DOCUMENT_META_ENDPOINT}/lease/${plant}/${proposal}`
    );
    useEffect(() => {
        if (data) setLease(data?.data?.data);
    }, [data]);

    return (
        <>
            {loading ? (
                <Container className="flex justify-center">
                    <H1 className="w-[80%] md:w-[500px] flex justify-center">
                        <Loading />
                    </H1>
                </Container>
            ) : error ? (
                <Container>
                    <div className="flex justify-center ">
                        <div className="w-full  sm:w-[80%] lg:w-[500px]">
                            <AlertMessage
                                onClick={() => history.push(LANDING)}
                                message={error}
                                error
                            >
                                {error}
                            </AlertMessage>
                        </div>
                    </div>
                </Container>
            ) : lease ? (
                <DetailsWrapper>
                    {/* @TODO => Show loading and error here @habib610 Thu August 15,2024 */}

                    <Heading
                        date={lease?.date}
                        propertyName={lease?.propertyName}
                        street={lease?.streetAddress}
                    />
                    <BillSummary
                        summary={buildBillingSummary(
                            lease?.averageMonthlyBill,
                            lease?.unitConsumption,
                            lease?.solarPlantRequired
                        )}
                    />
                    <div className="flex flex-col xl:flex-row gap-2 items-stretch">
                        <DetailsTable heading="Solar Lease Plan Details">
                            <TableComponent
                                columns={columns}
                                data={getLeasePlantDetails(lease)}
                                headerColor="bg-gray-170 rounded-xl"
                                oddRowColor=""
                                additionalRowClassName="bg-white"
                                borderStyle="border border-gray-200"
                                fontSize="text-xs md:text-sm"
                                rowStyles={{
                                    boxShadow:
                                        "inset 0.5px -4px rgba(133, 133, 133, 0.07)",
                                }}
                            />
                        </DetailsTable>
                        <Features
                            details={getOfferingFeatures(
                                lease?.availSubsidy,
                                lease?.plantDuration
                            )}
                        />
                    </div>
                    <Savings data={buildSavingsAtGlanceTableData(lease)} />
                    <BorderedContainer>
                        {lease?.onboardingSteps && (
                            <TermsAndConditions
                                heading="Onboarding Steps"
                                terms={lease?.onboardingSteps}
                            />
                        )}
                    </BorderedContainer>
                    <BorderedContainer>
                        {lease?.termsAndConditions && (
                            <TermsAndConditions
                                heading="Terms & Conditions"
                                terms={lease?.termsAndConditions}
                            />
                        )}
                    </BorderedContainer>
                </DetailsWrapper>
            ) : null}
        </>
    );
};

export default LeaseWrapper;
