import React from "react";
import P from "../../../shared/typography/P";
import TermsAndConditions from "./TermsAndConditions";

const Features = ({ details, paymentData }) => {
    return (
        <div className="bg-white w-full  gap-3.2 p-1 xl:w-[70%] border-2 border-gray-220 rounded-xl border-dashed">
            <p className=" text-xs  md:text-base lg:text-lg text-primary mb-2 font-medium">
                Offering Features
            </p>
            <div className="grid grid-cols-2 sm:grid-cols-3 gap-2  xl:gap-1 items-start  lg:pt-2">
                {details.map((item, index) => (
                    <div
                        key={index}
                        className="flex flex-col items-center justify-center"
                    >
                        <div className="inline-block bg-blue-200 rounded-lg p-1 mb-2">
                            <img
                                src={item.icon}
                                alt={item.title}
                                className="h-12 w-12"
                            />
                        </div>
                        <P className=" text-center text-xs sm:text-sm md:text-sm xl:text-sm">
                            {item.title}
                        </P>
                        <p className="text-gray-500 text-xs xl:text-sm text-nowrap">
                            {item.value}
                        </p>
                    </div>
                ))}
            </div>
            {paymentData && (
                <TermsAndConditions
                    terms={paymentData?.terms}
                    heading={paymentData?.heading}
                    className="mt-4 md:mt-5 xl:mt-5"
                />
            )}
        </div>
    );
};

export default Features;
