import React from "react";
import { Link } from "react-router-dom";
import cn from "../../lib/cn";
let menuBaseStyles =
    "z-10  hidden font-normal bg-gray-250 w-fit divide-y divide-gray-100 rounded-lg shadow";
const HeaderMenu = ({ label, links, isVisible }) => {
    return (
        <div className="hidden lg:block group">
            <div className="" id="navbar-multi-level">
                <ul className="flex flex-col font-medium p-4 md:p-0 mt-4 border border-gray-100 rounded-lg  md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0">
                    <li>
                        <button
                            id="dropdownNavbarLink"
                            data-dropdown-toggle="dropdownNavbar"
                            className={`flex items-center justify-between 
                            text-btn-s font-normal leading-6  hover:scale-110 duration-150  gap-2
                            ${isVisible ? "text-primary" : "text-white"}`}
                        >
                            {label}
                            <svg
                                className="w-[17px] h-[9px]"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 10 6"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="m1 1 4 4 4-4"
                                />
                            </svg>
                        </button>
                        <div
                            id="dropdownNavbar"
                            className={cn(
                                menuBaseStyles,
                                "group-hover:absolute group-hover:block"
                            )}
                        >
                            <ul
                                className="py-2 text-sm text-gray-700 "
                                aria-labelledby="dropdownLargeButton"
                            >
                                {links.map((item) => (
                                    <li key={item.name}>
                                        <Link
                                            to={item.path}
                                            className="text-base font-normal leading-6 text-primary hover:scale-110 duration-150 px-6 py-2"
                                        >
                                            {item.name}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default HeaderMenu;
