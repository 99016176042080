import moment from "moment";
import React, { useCallback } from "react";
import AlertMessage from "../../components/alert/AlertMessage";
import Pagination from "../../components/pagination";
import cn from "../../lib/cn";
import Container from "../../shared/Container";
import TableComponent from "../../shared/ui/Table";
const columns = [
    "Ticket ID",
    "Subject",
    "Date",
    "Assignee",
    "Status",
    "ETA",
    "Priority",
];

const TicketTable = ({ toggleSidebar, data }) => {
    const buildData = useCallback((data) => {
        if (!data?.tickets) return [];
        else {
            let rows = data?.tickets.map((item) => ({
                "Ticket ID": item.ticketId,
                Subject: item.subject,
                Date: (
                    <span className="text-nowrap">
                        {moment(item?.createdAt * 1000).format("DD-MM-YYYY")}
                    </span>
                ),
                Assignee: item?.assignee,
                Status: item?.status,
                ETA: (
                    <span
                        className={cn("text-nowrap font-semibold ", {
                            "text-red": item?.eta < 0,
                            "text-green-500": item?.eta > 0,
                        })}
                    >
                        {item?.eta < 0 && "-"}{" "}
                        {moment(item?.eta * 1000).format("hh:ss")}
                    </span>
                ),
                Priority: item?.priority,
            }));
            return rows;
        }
    }, []);
    return (
        <Container className="px-0 my-2">
            <div className="bg-gray-170 w-full  rounded-2xl p-1 md:p-1.5">
                <div className="bg-white border-2 border-dashed rounded-xl px-1 py-1 md:px-1 md:py-1.5  border-gray-220">
                    {!data ? (
                        <AlertMessage
                            error={false}
                            message="No tickets available at the moment"
                        />
                    ) : (
                        <TableComponent
                            columns={columns}
                            data={buildData(data)}
                            headerColor="bg-blue-300"
                            oddRowColor="bg-gray-170"
                            additionalRowClassName="rounded-xl"
                            borderStyle="border-collapse border border-gray-200"
                            fontSize={`text-xs md:text-sm`}
                            onClick={(val) => {
                                toggleSidebar(val);
                            }}
                        />
                    )}
                </div>
            </div>

            {data && (
                <Pagination
                    isFirst={true}
                    isLast={false}
                    pageCount={8}
                    onChange={() => {}}
                    forcePage={1}
                />
            )}
        </Container>
    );
};

export default TicketTable;
