import React from "react";
import cn from "../../../lib/cn";

const BorderedContainer = ({ className, children }) => {
    return (
        <div
            className={cn(
                "bg-white p-2 border-2 border-gray-220 rounded-xl border-dashed",
                className
            )}
        >
            {children}
        </div>
    );
};

export default BorderedContainer;
