import React from "react";
import Hero from "../../../app/contactUs/Hero";
import {
    CURRENT_MASTER_DATA_ENDPOINT,
    SAVE_MASTER_DATA_ENDPOINT,
} from "../../../constants/routes";
import { background } from "../../../resources/js/images";
import Container from "../../../shared/Container";
import H4 from "../../../shared/typography/H4";
import WrapperMaster from "./WrapperMaster";

const HeroDetails = () => {
    return (
        <div>
            <Container>
                <div className="flex justify-center items-end min-h-[140px] lg:min-h-[180px] xl:min-h-[250px]">
                    <H4 className="text-white text-lg sm:text-xl xl:text-2xl 2xl:text-5xl mb-4 xl:mb-7">
                        Proposal Master Data
                    </H4>
                </div>
            </Container>
        </div>
    );
};

const ProposalMasterData = () => {
    const fetchData = CURRENT_MASTER_DATA_ENDPOINT;
    const editData = SAVE_MASTER_DATA_ENDPOINT;
    return (
        <div>
            <Hero
                content={<HeroDetails />}
                wrapperClass={"bg-blue-500"}
                url={background}
                containerClass={" mb-0 sm:mb-0 xl:mb-0 2xl:mb-0 lg:mb-0"}
            />
            <WrapperMaster
                sectionClass="-mt-2 lg:-mt-3 xl:-mt-5"
                fetchData={fetchData}
                editData={editData}
            />
        </div>
    );
};

export default ProposalMasterData;
