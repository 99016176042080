import React, { useCallback, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import BulletList from "../../components/bullet-list/BulletList";
import Icon from "../../components/Icon/Icon";
import { aboutUsBg } from "../../resources/js/images";
import HeroDetails from "../dashboard/HeroDetails";
import Hero from "../home/Hero";
import AdminTable from "./AdminTable";
import Filters from "./Filters";

const Admin = () => {
    const history = useHistory();
    const [formData, setFormData] = useState({
        contact: "",
        id: "",
    });
    const handleSearchValueChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, []);

    const tableData = [
        {
            Name: "Divyanshu",

            "Email Id": "Divyanshu@hypersrot.com",

            "Phone No.": "9322589658",

            "Property Id": "NA",

            Role: (
                <ul>
                    <BulletList title={"Admin"} color="black" fontSize="s" />
                    <BulletList title={"Consumer"} color="black" fontSize="s" />
                    <BulletList title={"Investor"} color="black" fontSize="s" />
                    <BulletList
                        title={"Operation"}
                        color="black"
                        fontSize="s"
                    />
                    <BulletList title={"Sales"} color="black" fontSize="s" />
                </ul>
            ),
            Action: (
                <Icon
                    onClick={() => history.push("tickets")}
                    component={FaRegEdit}
                    lg="l"
                />
            ),
        },
        {
            Name: "Aman",

            "Email Id": "Divyanshu@hypersrot.com",

            "Phone No.": "9322589658",

            "Property Id": "SNN458",

            Role: (
                <ul>
                    <BulletList title={"Consumer"} color="black" fontSize="s" />
                </ul>
            ),

            Action: (
                <Icon
                    onClick={() => history.push("tickets")}
                    component={FaRegEdit}
                    lg="l"
                />
            ),
        },
    ];
    const columns = [
        "Name",
        "Email Id",
        "Phone No.",
        "Property Id",
        "Role",
        "Action",
    ];
    return (
        <div>
            <Hero
                containerClass={"mb-5 xl:mb-5 2xl:mb-5"}
                wrapperClass="h-[550px] sm:h-[500px] md:h-[350px] xl:h-[250px]"
                align={"items-end px-2 relative bottom-0"}
                content={<HeroDetails />}
                url={aboutUsBg}
            />
            <Filters
                formData={formData}
                onSearchValueChange={handleSearchValueChange}
            />
            <AdminTable columns={columns} tableData={tableData} />
        </div>
    );
};

export default Admin;
