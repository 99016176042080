import React, { useCallback, useEffect, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useHistory, useParams } from "react-router-dom";
import Icon from "../../../../components/Icon/Icon";
import Space from "../../../../components/space/Space";
import Container from "../../../../components/styles/Container.styles";
import { Flex, Section } from "../../../../components/styles/Elements.styles";
import { P } from "../../../../components/styles/Typography.styles";

import styled from "styled-components";
import Button from "../../../../components/button/Button";
import Input from "../../../../components/input/Input";

import { faPaperPlane } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import DropDown from "../../../../components/dropdown/DropDown";
import Tabs, { Tab } from "../../../../components/tabs/Tabs";
import { ERROR_MSG, Year_Terms } from "../../../../constants/common";
import {
    DASHBOARD,
    GET_PLANT_BY_ID_ENDPOINT,
    SALES_PROPOSAL_BASE_ENDPOINT,
} from "../../../../constants/routes";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import request from "../../../../services/request";
import theme from "../../../../theme";
import GenerateForm from "./Components/GenerateForm";
import SurveyForm from "./Components/SurveyForm";
import ProposalDeatilsContainer from "./ProposalDetailsContainer";

const ButtonStyled = styled(Button)`
    border: none;
    padding: 1px 55px;
    margin-top: ${(props) => (props.margin ? props.margin : "0")};
    margin-bottom: 3rem;
    height: 100%;
    font-size: 15px;
    :hover,
    :focus,
    :active {
        box-shadow: none;
    }
`;
const connectionTypeToCategory = {
    RESIDENTIAL_SOCIETY: "Residential Society",
    COMMERCIAL: "Commercial",
    INDUSTRIAL: "Industrial",
    INDIVIDUAL_HOMES: "Individual Homes",
};

function GenerateProposal() {
    const { generateId } = useParams();

    const initialState2 = fields2.reduce((acc, field) => {
        acc[field.label] = field.selected || "";
        return acc;
    }, {});
    const [proposalData, setProposalData] = useState(null);
    const [currentProposal, setCurrentProposal] = useState();
    const [checkboxes, setCheckboxes] = useState({
        Purchase: currentProposal
            ? currentProposal.purchase !== "REJECTED"
            : true,
        Lease: currentProposal ? currentProposal.lease !== "REJECTED" : true,
        Loan: currentProposal ? currentProposal.loan !== "REJECTED" : true,
    });
    const [plantName, setPlantName] = useState("");
    const breakpoint = parseInt(
        theme.breakPoints.smallTablet.replace("px", ""),
        10
    );
    const windowWidth = useWindowDimensions().width;
    const isSmallTablet = windowWidth < breakpoint;
    const [formData2, setFormData2] = useState(initialState2);
    const [optionArr, setOptionArr] = useState(["Purchase", "Lease", "Loan"]);
    useEffect(() => {
        const fetchData = async () => {
            const endpoint = `/sales/proposal/${generateId}/all`;
            const data = await request.authGet({ endpoint });
            const plantData = await request.authGet({
                endpoint: `${GET_PLANT_BY_ID_ENDPOINT}/${generateId}`,
            });
            setPlantName(plantData?.data?.plant?.propertyName);

            setProposalData(data.data.proposal);
            const foundProposal = data.data.proposal.find(
                (proposal) => proposal.isCurrentVersion === true
            );

            // Set the found proposal as the current proposal
            setCurrentProposal(foundProposal);

            setFormData2(
                fields2.reduce((acc, field) => {
                    switch (field.label) {
                        case "Connection Type":
                            acc[field.label] = foundProposal
                                ? connectionTypeToCategory[
                                      foundProposal.category
                                  ]
                                : field.selected;

                            break;
                        case "Consumer’s Bill":
                            acc[field.label] = foundProposal?.consumerBill;
                            break;
                        case "Consumer’s Total Unit":
                            acc[field.label] =
                                foundProposal?.consumerTotalUnits;
                            break;
                        case "Plan Duration":
                            acc[field.label] = foundProposal?.plantDuration
                                ? `${foundProposal?.plantDuration} Years`
                                : field.selected;
                            break;
                        default:
                            acc[field.label] = "";
                    }
                    return acc;
                }, {})
            );
            setCheckboxes({
                Purchase: foundProposal
                    ? foundProposal.purchase !== "REJECTED"
                    : true,
                Lease: foundProposal
                    ? foundProposal.lease !== "REJECTED"
                    : true,
                Loan: foundProposal ? foundProposal.loan !== "REJECTED" : true,
            });
            setOptionArr(
                foundProposal.category === "INDIVIDUAL_HOMES" ||
                    foundProposal.category === "INDUSTRIAL"
                    ? ["Purchase", "Loan"]
                    : foundProposal.category === "RESIDENTIAL_SOCIETY"
                    ? ["Purchase", "Lease"]
                    : ["Purchase", "Lease", "Loan"]
            );
        };
        fetchData().catch((err) => {
            toast.error(typeof err === "string" ? err : ERROR_MSG);
        });
    }, []);

    const initialState1 = fields1.reduce((acc, field) => {
        acc[field.label] = "";
        return acc;
    }, {});
    const [formData1, setFormData1] = useState(initialState1);

    const handleInputChange = (label, value) => {
        setFormData1((prevState) => ({
            ...prevState,
            [label]: value,
        }));
    };
    const history = useHistory();

    const [connectionType, setConnectionType] = useState(
        formData2["Connection Type"]
    );

    const [intro, setIntro] = useState(
        currentProposal?.subsidy && currentProposal?.tenant
            ? connectionType === "Commercial"
                ? currentProposal?.tenant
                    ? "Yes"
                    : "No"
                : currentProposal?.subsidy
                ? "Yes"
                : "No"
            : "Yes"
    );

    const handleInputChange2 = (label, value) => {
        if (label === "Connection Type") {
            setConnectionType(value);
            if (
                value === connectionTypeToCategory.INDIVIDUAL_HOMES ||
                value === connectionTypeToCategory.INDUSTRIAL
            ) {
                setOptionArr(["Purchase", "Loan"]);
            } else if (value === connectionTypeToCategory.RESIDENTIAL_SOCIETY) {
                setOptionArr(["Purchase", "Lease"]);
            } else {
                setOptionArr(["Purchase", "Lease", "Loan"]);
            }

            setFormData2((prevState) => ({
                ...prevState,
                "Consumer’s Bill": "",
                [label]: value,
            }));
        } else {
            setFormData2((prevState) => ({
                ...prevState,
                [label]: value,
            }));
        }
    };

    const onChange = (e) => {
        const name = e.target.value;
        setCheckboxes((prevState) => ({
            ...prevState,
            [name]: !prevState[name],
        }));
    };
    const OnChange = (value) => {
        setIntro(value);
    };

    let isServerCalculation =
        connectionType === connectionTypeToCategory.RESIDENTIAL_SOCIETY ||
        connectionType === connectionTypeToCategory.INDIVIDUAL_HOMES;

    const fetchBillAmount = useCallback(async () => {
        try {
            if (
                isServerCalculation &&
                !isNaN(formData2["Consumer’s Total Unit"])
            ) {
                let res = await request.authGet({
                    endpoint: `${SALES_PROPOSAL_BASE_ENDPOINT}/getBill/residential/${formData2["Consumer’s Total Unit"]}`,
                });
                let bill = res?.data?.billAmount.toFixed(2);
                if (bill) {
                    setFormData2({ ...formData2, "Consumer’s Bill": bill });
                } else {
                    toast.error(
                        "Can't fetch bill amount. Please try again later"
                    );
                }
            }
        } catch (error) {
            toast.error(typeof error === "string" ? error : ERROR_MSG);
        }
    }, [formData2, isServerCalculation]);

    const renderIcon = useCallback(
        (name) => {
            if (currentProposal) return null;
            else if (
                isServerCalculation &&
                name === "Consumer’s Total Unit" &&
                formData2["Consumer’s Total Unit"] &&
                !isNaN(formData2["Consumer’s Total Unit"])
            )
                return faPaperPlane;
        },
        [isServerCalculation, currentProposal, formData2]
    );

    useEffect(() => {
        if (isServerCalculation && !formData2["Consumer’s Total Unit"]) {
            setFormData2({ ...formData2, "Consumer’s Bill": "" });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isServerCalculation, formData2["Consumer’s Total Unit"]]);

    return (
        <Section height="auto">
            <Container>
                <Space xxsm={["30px 0 30px 0 "]} xlm={["50px 0 30px 0"]}>
                    <Flex.Container
                        onClick={() =>
                            history.push(`${DASHBOARD}/${generateId}`)
                        }
                        align="center"
                        style={{ cursor: "pointer" }}
                    >
                        <Space xxsm={["0 1rem 0 0"]} stm={["0 2rem 0 0"]}>
                            <Flex.Item>
                                <Icon component={BsArrowLeft} lg="l" />
                            </Flex.Item>
                        </Space>
                        <Flex.Item>
                            <P>Property Details</P>
                        </Flex.Item>
                    </Flex.Container>
                </Space>

                <ProposalDeatilsContainer>
                    <Flex.Container justify="space-between" align="start">
                        <Flex.Item xl="30%" t="100%" xxs="100%">
                            <DetailsFiled
                                label={"Property Id : "}
                                placeholder=""
                                value={generateId}
                                textAlign="center"
                                backgroundColor="white"
                                disabled={true}
                            />
                        </Flex.Item>

                        <Flex.Item xl="40%" t="100%" xxs="100%">
                            <DetailsFiled
                                label={"Total Proposals Count"}
                                placeholder=""
                                value={
                                    proposalData
                                        ? proposalData?.length > 9
                                            ? proposalData?.length
                                            : `0${proposalData?.length}`
                                        : "00"
                                }
                                textAlign="center"
                                backgroundColor="white"
                                disabled={true}
                            />
                        </Flex.Item>

                        <Space xxsm={["20px 50px"]} stm={["0"]} tm={["0"]}>
                            <ButtonStyled>View All Proposals</ButtonStyled>
                        </Space>
                    </Flex.Container>
                </ProposalDeatilsContainer>

                {isSmallTablet ? (
                    <>
                        <Tabs active={1}>
                            <Tab label="Survey Overview">
                                <SurveyForm
                                    fields1={fields1}
                                    formData1={formData1}
                                    handleInputChange={handleInputChange}
                                />
                            </Tab>
                            <Tab label="Generate Proposal">
                                <GenerateForm
                                    fields2={fields2}
                                    fetchBillAmount={fetchBillAmount}
                                    formData2={formData2}
                                    onChange={onChange}
                                    handleInputChange2={handleInputChange2}
                                    intro={intro}
                                    isServerCalculation={isServerCalculation}
                                    renderIcon={renderIcon}
                                    optionArr={optionArr}
                                    OnChange={OnChange}
                                    currentProposal={currentProposal}
                                    checkboxes={checkboxes}
                                    generateId={generateId}
                                    plantName={plantName}
                                />
                            </Tab>
                        </Tabs>
                    </>
                ) : (
                    <Flex.Container justify="space-between" align="center">
                        <Flex.Item xl="45%" t="100%" xxs="100%">
                            <SurveyForm
                                fields1={fields1}
                                formData1={formData1}
                                handleInputChange={handleInputChange}
                            />
                        </Flex.Item>
                        <Flex.Item xl="50%" t="100%" xxs="100%">
                            <GenerateForm
                                fields2={fields2}
                                fetchBillAmount={fetchBillAmount}
                                formData2={formData2}
                                onChange={onChange}
                                handleInputChange2={handleInputChange2}
                                intro={intro}
                                isServerCalculation={isServerCalculation}
                                renderIcon={renderIcon}
                                optionArr={optionArr}
                                OnChange={OnChange}
                                currentProposal={currentProposal}
                                checkboxes={checkboxes}
                                generateId={generateId}
                                plantName={plantName}
                            />
                        </Flex.Item>
                    </Flex.Container>
                )}
            </Container>
        </Section>
    );
}

export default GenerateProposal;

export const DetailsFiled = ({
    label,
    isSelect,
    data,
    selected,
    textAlign,
    handleInputChange,
    ...rest
}) => {
    return (
        <Space margin={["0 0 30px"]} tm={["0 0 25px"]} xsm={["0 0 10px"]}>
            <Flex.Container justify="space-between" align="center">
                <Space xxsm={["10px 0 0"]} stm={["0"]}>
                    <Flex.Item xl="45%" st="60%" s="100%" xxs="100%">
                        <P color="black">{label}</P>
                    </Flex.Item>
                </Space>
                {isSelect ? (
                    <FlexItem s="100%" xxs="100%" xl="100%">
                        <DropDown
                            data={data}
                            onChange={(value) =>
                                handleInputChange(label, value)
                            }
                            selected={selected}
                            {...rest}
                        />
                    </FlexItem>
                ) : (
                    <FlexItem s="100%" xxs="100%" xl="30rem">
                        <Input
                            shadow={false}
                            style={{
                                paddingBottom: "7px",
                                paddingTop: "7px",
                                textAlign: textAlign,
                            }}
                            onChange={(value) => {
                                handleInputChange(label, value.target.value);
                            }}
                            {...rest}
                        />
                    </FlexItem>
                )}
            </Flex.Container>
        </Space>
    );
};

const FlexItem = styled(Flex.Item)`
    @media (min-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        flex: 1;
    }
`;

const fields1 = [
    {
        label: "Property Name",
        name: "name1",
    },
    { label: "Booking Date", name: "name2" },
    { label: "Survey Date", name: "name3" },
    { label: "Survey Person", name: "name4" },
    { label: "Site Count", name: "name5" },
];

export const fields2 = [
    {
        label: "Connection Type",
        name: "Connection Type",
        selected: "Residential Society",
        isSelect: true,
        data: [
            "Residential Society",
            "Commercial",
            "Industrial",
            "Individual Homes",
        ],
    },
    {
        label: "Consumer’s Bill",
        name: "Consumer’s Bill",
        placeholder: "Enter Total Bill Amount",
    },
    {
        label: "Consumer’s Total Unit",
        name: "Consumer’s Total Unit",
        placeholder: "Total Electricity Unit Consumed",
        helperText: true,
        helperTextAlign: "start",
    },
    {
        label: "Plan Duration",
        name: "Plan Duration",
        selected: "5 Years",
        isSelect: true,
        data: Year_Terms,
    },
];
