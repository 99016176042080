import { useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MSG } from "../constants/common";
import { UPLOAD_TICKET_FILE_DOCUMENT_ENDPOINT } from "../constants/routes";
import { optimizeImageSize } from "../lib";
import request from "../services/request";

const useFileUploadAndRemove = () => {
    const [file, setFile] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [uploadedDoc, setUploadedDoc] = useState([]);
    /* @TODO => Handle File restrictions @habib610 Fri August 23,2024 */
    const handleFileChange = async (e) => {
        let newFile = e.target.files[0];
        e.preventDefault();
        setUploading(true);
        const imageFile = await optimizeImageSize(newFile);
        if (imageFile instanceof File) {
            try {
                let formData = new FormData();
                formData.append("multipartFile", imageFile);

                let res = await request.authPost({
                    body: formData,
                    endpoint: UPLOAD_TICKET_FILE_DOCUMENT_ENDPOINT,
                    isFormData: true,
                });

                setUploadedDoc([
                    ...uploadedDoc,
                    {
                        index: file.length,
                        file: res?.data?.fileDocument,
                    },
                ]);
                setFile([...file, newFile]);
                toast.success(res?.message || "Document has been uploaded");
                setUploading(false);
                return true;
            } catch (error) {
                setUploading(false);
                toast.error(typeof error === "string" ? error : ERROR_MSG);
                return false;
            }
        } else {
            setUploading(false);
            toast.error("Something went wrong while compressing image");
            return false;
        }
    };
    const removeFile = (index) => {
        setFile(file.filter((_, i) => i !== index));
        setUploadedDoc(uploadedDoc.filter((item) => item.index !== index));
    };

    return {
        file,
        setFile,
        uploadedDoc,
        setUploadedDoc,
        uploading,
        handleFileChange,
        removeFile,
    };
};

export default useFileUploadAndRemove;
