import React from "react";
import cn from "../../../lib/cn";

const TermsAndConditions = ({
    heading = "Terms & Conditions",
    terms,
    className,
}) => {
    return (
        <div className={cn("flex flex-col gap-0.5 xl:gap-3.2", className)}>
            <p className="text-xs md:text-base lg:text-lg  font-medium text-primary">
                {heading}
            </p>
            <div
                dangerouslySetInnerHTML={{
                    __html: terms,
                }}
                className="!list-disc !marker:text-gray-50 space-y-2 pl-6 !text-gray-700 text-sm list__disc font-urbanist"
            ></div>
        </div>
    );
};

export default TermsAndConditions;
