import React, { useState } from "react";
import { ERROR_MSG } from "../../../constants/common";
import {
    RESET_LOADER,
    SET_ERROR,
    SET_LOADER,
} from "../../../constants/contexConstant";
import { CREATE_TICKET_FEEDBACK_ENDPOINT } from "../../../constants/routes";
import { useContextState } from "../../../context/ContextProvider";
import cn from "../../../lib/cn";
import { check } from "../../../resources/js/images";
import request from "../../../services/request";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import Confirmation from "../sections/Confirmation";
import FeedbackStars from "./FeedbackStars";

const SupportFeedback = ({ meta, ticketId, onClose, feedback }) => {
    const [feedbackTags, setFeedbackTags] = useState([]);
    const [comment, setComment] = useState("");
    const { dispatch } = useContextState();
    const [filledStars, setFilledStars] = useState(0);
    const [selectedStars, setSelectedStars] = useState(0);
    const [showConfirmation, setShowConfirmation] = useState(false);

    const handleOnMousesOver = (val) => {
        setFilledStars(val);
    };
    const handleOnMousesLeave = () => {
        setFilledStars(0);
    };

    const handleSubmitReview = async (e) => {
        e.preventDefault();
        try {
            dispatch({ type: SET_LOADER });

            let res = await request.authPost({
                endpoint: `${CREATE_TICKET_FEEDBACK_ENDPOINT}/${ticketId}`,
                body: {
                    rating: selectedStars,
                    comment,
                    feedbackTags,
                },
            });
            setShowConfirmation(true);
            dispatch({ type: RESET_LOADER });
            setTimeout(() => {
                onClose(res?.data?.feedback);
            }, [10000]);
        } catch (error) {
            dispatch({ type: RESET_LOADER });
            dispatch({
                type: SET_ERROR,
                payload: typeof error === "string" ? error : ERROR_MSG,
            });
        }
    };

    const handleSelectedStars = (val) => {
        setSelectedStars(val);
        setFeedbackTags([]);
    };

    const handleSelectTags = (val) => {
        if (feedbackTags.includes(val)) return;
        setFeedbackTags([...feedbackTags, val]);
    };

    const renderFeedbacks = (data, enabled) => {
        return data.map((item) => (
            <div
                role="button"
                onClick={() => handleSelectTags(item)}
                className={cn(
                    "px-2 py-0.5 text-sm bg-blue-400 rounded-0.7 text-white duration-150 text-center",
                    {
                        "bg-gray-220 text-primary":
                            feedbackTags.includes(item) || !enabled,
                        " hover:bg-gray-220 hover:text-primary": enabled,
                    }
                )}
            >
                {item}
            </div>
        ));
    };

    return showConfirmation ? (
        <Confirmation
            img={check}
            heading="Feedback has been submitted"
            message="Thanks for your valuable feedback"
        >
            <div className="flex  w-full items-center justify-center">
                <Button onClick={onClose} className="px-4 w-8/12">
                    Done
                </Button>
            </div>
        </Confirmation>
    ) : (
        <form
            onSubmit={handleSubmitReview}
            className="flex justify-center items-center h-full  "
        >
            <div className="flex flex-col gap-2 w-full md:w-10/12  xl:w-8/12  p-1 xl:p-2">
                <P className="text-center">
                    {feedback?.comment ||
                        "Share your experience with Support Staff"}
                </P>

                {feedback ? (
                    <FeedbackStars filledStars={feedback?.rating} />
                ) : (
                    <FeedbackStars
                        onMouseLeave={handleOnMousesLeave}
                        onMouseOver={(val) => handleOnMousesOver(val)}
                        onClick={(val) => handleSelectedStars(val)}
                        selectedStars={selectedStars}
                        filledStars={filledStars}
                    />
                )}

                {meta.length !== 0 && !feedback && (
                    <div className="grid grid-cols-2 gap-1 xl:gap-2  ">
                        {selectedStars !== 0
                            ? renderFeedbacks(meta[selectedStars], true)
                            : null}
                    </div>
                )}
                {feedback && (
                    <div className="grid grid-cols-2 gap-1 xl:gap-2  ">
                        {renderFeedbacks(feedback?.feedbackTags, false)}
                    </div>
                )}

                {feedbackTags.length !== 0 && !feedback && (
                    <>
                        <textarea
                            className="border border-blue-400 focus:border-blue-400 text-primary text-sm xl:text-base w-full resize-none rounded py-0.2 px-1"
                            rows={4}
                            placeholder="Comment"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />

                        <Button
                            disabled={comment.length < 10}
                            className="w-full"
                            type="submit"
                        >
                            Submit
                        </Button>
                    </>
                )}
            </div>
        </form>
    );
};

export default SupportFeedback;
