import React, { useEffect, useRef } from "react";
import cn from "../../lib/cn";
import Container from "../Container";

import { FaChevronRight } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import Accordion from "../../components/accordion/Accordion";
import { LOGIN, ONBOARDING, ONBOARDING_LABEL } from "../../constants/routes";
import Button from "../ui/Button";

const Hamburger = ({ showBurger, onClick, links, user, handleLogout }) => {
    const history = useHistory();
    const burgerMenu = useRef(null);

    useEffect(() => {
        const closeOpenMenus = (e) => {
            if (showBurger && !burgerMenu.current?.contains(e.target)) {
                onClick(false);
            }
        };

        document.addEventListener("mousedown", closeOpenMenus);

        return () => {
            document.removeEventListener("mousedown", closeOpenMenus);
        };
    }, [showBurger, onClick]);

    return (
        <div
            ref={burgerMenu}
            className={cn(
                "lg:hidden bg-white fixed z-[80] ease-in transition-all duration-300 left-0 right-0 top-[-200px]  pt-4 ",
                {
                    "h-auto  top-0": showBurger,
                    "h-0 overflow-hidden pt-0  top-[-200px]": !showBurger,
                }
            )}
        >
            <Container>
                <div className="flex justify-between items-center !pt-[7rem]">
                    <div>
                        <button
                            onClick={() => onClick(false)}
                            className="text-primary text-sm md:text-xl flex items-center justify-center"
                        >
                            <svg
                                className="flex items-center sm:hidden"
                                width="25"
                                height="26"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M17 17.5L9 9.5M9 9.5L1 1.5M9 9.5L17 1.5M9 9.5L1 17.5"
                                    stroke="#171D39"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>

                            <svg
                                className="hidden sm:block xl:hidden"
                                width="22"
                                height="23"
                                viewBox="0 0 22 23"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M17 17.5L9 9.5M9 9.5L1 1.5M9 9.5L17 1.5M9 9.5L1 17.5"
                                    stroke="#171D39"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>
                        </button>
                    </div>
                    <div>
                        {user && (
                            <Button
                                onClick={() => {
                                    user
                                        ? handleLogout()
                                        : history.push(`${LOGIN}`);
                                    onClick(false);
                                }}
                            >
                                {user ? "Logout" : "Login"}
                            </Button>
                        )}
                    </div>
                </div>
                <div>
                    <div className="my-4">
                        {links.map((item, index) =>
                            item?.dropdown ? (
                                <Accordion
                                    wrapperClass="px-0 mt-2.4 pb-0.5"
                                    label={item.dropdown}
                                    labelClass="text-lg font-medium  text-primary"
                                    contentMargin="mt-0"
                                    closedIcon={() => <FaChevronRight />}
                                    rotateClass="rotate-90"
                                    key={index}
                                >
                                    <div className="ml-0.7">
                                        {item.links.map((innerLink) => (
                                            <div className="mt-1">
                                                <button
                                                    onClick={() => {
                                                        history.push(
                                                            innerLink.path
                                                        );
                                                        onClick(false);
                                                    }}
                                                    key={innerLink.name}
                                                    className="text-sm font-normal  text-primary  "
                                                >
                                                    {innerLink.name}
                                                </button>
                                            </div>
                                        ))}
                                    </div>
                                </Accordion>
                            ) : (
                                <div className="mt-2.4">
                                    <button
                                        onClick={() => {
                                            history.push(item.path);
                                            onClick(false);
                                        }}
                                        key={index}
                                        className="text-btn-s font-normal leading-6 text-primary hover:scale-110 duration-150 "
                                    >
                                        {item.name}
                                    </button>
                                </div>
                            )
                        )}
                        {!user && (
                            <Button
                                variant="primary"
                                className=" mt-2.4 flex text-white"
                                onClick={() => {
                                    history.push(ONBOARDING);
                                }}
                            >
                                {ONBOARDING_LABEL}
                            </Button>
                        )}
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default Hamburger;
