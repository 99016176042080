import React from "react";
import { GoArrowLeft } from "react-icons/go";
import { useHistory } from "react-router-dom";
import Container from "../../../shared/Container";
import P from "../../../shared/typography/P";

const DetailsHolder = ({ children }) => {
    const history = useHistory();

    return (
        <div className="mt-2 md:mt-5 pt-[8rem] bg-white">
            <Container className="mb-2">
                <div
                    className="flex items-center gap-2"
                    role="button"
                    onClick={() => history.goBack()}
                >
                    <P>
                        <GoArrowLeft />
                    </P>
                    <P>Go Back</P>
                </div>
            </Container>
            {children}
        </div>
    );
};

export default DetailsHolder;
