import React, { useEffect, useMemo, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import Icon from "../../../../components/Icon/Icon";
import Button from "../../../../components/button/Button";
import DropDown from "../../../../components/dropdown/DropDown";
import Input from "../../../../components/input/Input";
import Space from "../../../../components/space/Space";
import Container from "../../../../components/styles/Container.styles";
import { Flex, Section } from "../../../../components/styles/Elements.styles";
import { H4, P } from "../../../../components/styles/Typography.styles";
import ProposalDeatilsContainer from "./ProposalDetailsContainer";

import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Accordion from "../../../../components/accordion/Accordion.js";
import { ERROR_MSG } from "../../../../constants/common.js";
import {
    CURRENT_MASTER_DATA_ENDPOINT,
    SAVE_PROPOSAL_DATA_ENDPOINT,
} from "../../../../constants/routes.js";
import useWindowDimensions from "../../../../hooks/useWindowDimensions.js";
import request from "../../../../services/request.js";
import theme from "../../../../theme/index.js";
import ConsumerDataForm from "./Components/ConsumerDataForm.js";

export const ButtonStyled = styled(Button)`
    border: none;
    padding: 10px 30px;
    margin-top: 2rem;
    background-color: ${(props) =>
        props.isActive
            ? props.theme.color.primary
            : props.isCancel
            ? props.theme.color.error
            : props.theme.color.grey500};
    color: ${(props) => props.theme.white};
    margin-bottom: 3rem;

    font-size: 15px;
    :hover,
    :focus,
    :active {
        box-shadow: none;
    }
`;

export const StyleLink = styled.a`
    text-decoration: underline;
    cursor: pointer;
    color: ${(props) =>
        props.color ? props.color : props.theme.color.primary};
`;

export const TableContainer = styled.table`
    width: 100%;
    padding: 0;
    border-spacing: 0;
    font-family: ${(props) => props.fonFamily || "'Montserrat', sans-serif"};
`;
export const Header = styled.th`
    text-align: start;
    padding: 20px 30px;
    margin: 0;

    color: ${(props) =>
        props.color ? props.theme.color[props.color] : props.theme.color.white};
    background: ${(props) =>
        props.bg ? props.theme.color[props.bg] : props.theme.color.primary};
    border: 0.1px solid
        ${(props) =>
            props.borderColor ? props.borderColor : props.theme.color.primary};
`;
export const Cell = styled.td`
    border: 0.1px solid
        ${(props) =>
            props.borderColor
                ? props.theme.color[props.borderColor]
                : props.theme.color.darkGrey};
    text-align: ${(props) => (props.align ? props.align : "center")};
    padding: 20px 30px;
    color: ${(props) =>
        props.color
            ? props.theme.color[props.color]
            : props.theme.color.darkGrey};
    background: ${(props) =>
        props.bg ? props.theme.color[props.bg] : props.theme.color.grey100};
`;
function SaveProposal() {
    const { generateId } = useParams();
    const location = useLocation();
    const { formData2, checkboxes, intro } = location.state;

    const [connectionType, setConnectionType] = useState(
        formData2["Connection Type"]
    );
    const [showSummary, setShowSummary] = useState(false);
    const [active, setActive] = useState(
        checkboxes["Purchase"]
            ? "Purchase"
            : checkboxes["Lease"] &&
              connectionType !== "Individual Homes" &&
              connectionType !== "Industrial"
            ? "Lease"
            : "Loan"
    );

    const [solarData, setSolarData] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            const endpoint = CURRENT_MASTER_DATA_ENDPOINT;
            const data = await request.authGet({ endpoint });
            setSolarData(JSON.parse(data.data.masterData.data));
        };
        fetchData().catch(console.error);
    }, []);

    const saveProposal = async () => {
        // Define the body of the POST request
        const connectionTypeToCategory = {
            "Residential Society": "RESIDENTIAL_SOCIETY",
            Commercial: "COMMERCIAL",
            Industrial: "INDUSTRIAL",
            "Individual Homes": "INDIVIDUAL_HOMES",
        };

        const body = {
            plantId: generateId,
            category: connectionTypeToCategory[connectionType],
            consumerBill: `${parseInt(formData2["Consumer’s Bill"])}`,
            consumerTotalUnits: `${parseInt(
                formData2["Consumer’s Total Unit"]
            )}`,
            plantDuration: formData2["Plan Duration"].split(" ")[0],
            subsidy:
                (connectionType === "Residential Society" ||
                    connectionType === "Individual Homes") &&
                intro === "Yes",
            purchase: checkboxes["Purchase"] ? "INTERESTED" : "REJECTED",
            lease: checkboxes["Lease"] ? "INTERESTED" : "REJECTED",
            loan: checkboxes["Loan"] ? "INTERESTED" : "REJECTED",
            tenant: connectionType === "Commercial" && intro === "Yes",
        };

        try {
            const response = await request.authPost({
                endpoint: SAVE_PROPOSAL_DATA_ENDPOINT,
                body: body,
            });

            // Handle response according to your needs
            if (response.status === "SUCCESS") {
                history.push(`/${generateId}/viewProposal`, {
                    formData2,
                    checkboxes,
                    intro,
                });
            } else {
                toast.error("Failed to save proposal:");
                return false;
            }
        } catch (error) {
            toast.error(typeof error === "string" ? error : ERROR_MSG);

            return false;
        }
    };

    const [consumerTotalUnits, setConsumerTotalUnits] = useState(
        formData2["Consumer’s Total Unit"]
    );
    const [consumerBills, setConsumerBills] = useState(
        formData2["Consumer’s Bill"]
    );
    const [averageMonthlyBill, setAverageMonthlyBill] = useState(
        Number(consumerBills)
    );
    const [solarPlantSize, setSolarPlantSize] = useState(0);
    const [subsidyAmount, setSubsidyAmount] = useState(0);

    const [SavedAmout, setSavedAmout] = useState(0);
    const [upfront, setUpfront] = useState(0);
    const [totalAmt, setTotalAmt] = useState(0);
    const [gst, setGST] = useState(0);
    const [leaseTable, setLeaseTable] = useState({});

    useEffect(() => {
        if (consumerTotalUnits > 0) {
            const size = consumerTotalUnits / 120;
            setSolarPlantSize(size.toFixed(2));
            calculateSubsidy(size.toFixed(2));
            calculateTotalCost(size.toFixed(2));
            setLeaseTable(
                calculateLeasePricingTable(size, Number(consumerBills))
            );
        }
    }, [consumerTotalUnits, active, connectionType, consumerBills, solarData]);

    const planDurationYears = useMemo(() => {
        const yearsString = formData2["Plan Duration"].split(" ")[0]; // gets "8" from "8 Years"
        return yearsString + "Y"; // returns "8Y"
    }, [formData2]);

    const billPayableToHypersrot = useMemo(() => {
        return (
            solarPlantSize *
            (solarData?.charges.commercial.investor_rate[planDurationYears] +
                solarData?.charges.commercial.hypersrot_rate.per_KW) *
            120
        );
    }, [solarPlantSize, solarData]);
    const billWithoutGST = useMemo(() => {
        return averageMonthlyBill / 1.18;
    }, [averageMonthlyBill]);
    const Gst = useMemo(() => {
        return billPayableToHypersrot * 0.18;
    }, [billPayableToHypersrot, solarData]);

    const totalBillPayable = useMemo(() => {
        return billPayableToHypersrot + Gst;
    }, [billPayableToHypersrot, gst]);

    const monthlySavings = useMemo(() => {
        if (intro === "Yes") {
            return billWithoutGST * 0.18;
        } else {
            return averageMonthlyBill - billPayableToHypersrot;
        }
    }, [averageMonthlyBill, billPayableToHypersrot]);

    const savingsPercentage = useMemo(() => {
        return (monthlySavings.toFixed(0) / averageMonthlyBill) * 100;
    }, [monthlySavings, averageMonthlyBill]);

    // Commercial With Tenants Calculations

    const tenantGST = useMemo(() => {
        return billWithoutGST * 0.18;
    }, [billWithoutGST]);

    const savingsOnCollection = useMemo(() => {
        return billWithoutGST.toFixed(0) - billPayableToHypersrot.toFixed(0);
    }, [billWithoutGST, billPayableToHypersrot]);

    const gstPayableToHypersrot = useMemo(() => {
        return (totalBillPayable / 1.18) * 0.18;
    }, [totalBillPayable]);

    const gstPayableToGovernment = useMemo(() => {
        return tenantGST - gstPayableToHypersrot;
    }, [tenantGST, gstPayableToHypersrot]);
    // Calculate Subsidy
    const calculateSubsidy = (size) => {
        if (connectionType === "Residential Society") {
            let subAmt =
                solarData?.government_subsidy["residential-society"]["0-500"] *
                size;
            const formattedSubsidyAmount = subAmt.toLocaleString();
            setSubsidyAmount(formattedSubsidyAmount);
        } else if (connectionType === "Individual Homes") {
            let subAmt;
            const subsidyData =
                solarData?.government_subsidy["individual-homes"];
            if (subsidyData) {
                Object.keys(subsidyData).forEach((range) => {
                    const [min, max] = range.split("-").map(Number);
                    if (size >= min && size <= max) {
                        subAmt = subsidyData[range];
                    }
                });
            }
            if (subAmt) {
                const totalSubsidyAmount = subAmt;
                const formattedSubsidyAmount =
                    totalSubsidyAmount.toLocaleString(); // Format with commas
                setSubsidyAmount(formattedSubsidyAmount);
            }
        }
    };

    // Calculate Total Cost
    const calculateTotalCost = (size) => {
        let costPerKW;

        const pricingData =
            (connectionType === "Residential Society" ||
                connectionType === "Individual Homes") &&
            intro === "Yes"
                ? solarData?.pricing.loan_and_purchase.subsidy
                : solarData?.pricing.loan_and_purchase["non-subsidy"];

        // Iterate through the keys to find the appropriate range
        if (pricingData) {
            Object.keys(pricingData).forEach((range) => {
                const [min, max] = range.split("-").map(Number);
                if (size > min && size <= max) {
                    costPerKW = pricingData[range];
                }
            });
        }

        if (costPerKW) {
            const savemt = size * costPerKW;
            const formatsaveAmt = savemt.toLocaleString();
            const totalCost = savemt * 1.138;
            setSavedAmout(formatsaveAmt);
            const gstAmount = savemt * 0.138;
            const formattedGST = gstAmount.toLocaleString();
            setGST(formattedGST);
            const total = savemt + gstAmount;
            const formattedTotal = total.toLocaleString();
            setTotalAmt(formattedTotal);
            const payment = totalCost * 0.3;
            const formattedUpfront = payment.toLocaleString();
            setUpfront(formattedUpfront);
        } else {
            console.error(
                "Could not find appropriate range for the provided size"
            );
        }
    };
    const calculateLeasePricingTable = (solarPlantSize, averageMonthlyBill) => {
        // Calculate fixed monthly rental
        const inverstorRate = Number(
            solarData?.charges["residential-society"].investor_rate["5Y"]
        );
        const hypersrotRate = Number(
            solarData?.charges["residential-society"].hypersrot_rate.per_KW
        );

        const fixedMonthlyRental =
            (inverstorRate + hypersrotRate) * 120 * solarPlantSize;

        // Calculate bank guarantee
        const bankGuarantee = fixedMonthlyRental * 4;

        // Calculate savings on existing bills percentage
        const savingsOnExistingBillsPercentage =
            ((averageMonthlyBill - fixedMonthlyRental) / averageMonthlyBill) *
            100;

        // Calculate government subsidy
        const governmentSubsidy =
            solarData?.government_subsidy["residential-society"]["0-500"] *
            solarPlantSize;

        // Calculate effective savings per month
        const planDuration = formData2["Plan Duration"];
        let pricingYearWithPlanDuration;

        if (planDuration.includes("Years")) {
            const years = parseInt(planDuration.split(" ")[0], 10); // Extract the number of years
            pricingYearWithPlanDuration = years * 12; // Add the number of years to pricingyear
        }

        const effectiveSavingsPerMonth =
            ((averageMonthlyBill -
                fixedMonthlyRental +
                governmentSubsidy / pricingYearWithPlanDuration) /
                averageMonthlyBill) *
            100;

        // Return the calculated values
        return {
            fixedMonthlyRental: fixedMonthlyRental.toLocaleString(),
            bankGuarantee: bankGuarantee.toLocaleString(),
            savingsOnExistingBillsPercentage:
                savingsOnExistingBillsPercentage.toFixed(2),
            governmentSubsidy: governmentSubsidy.toLocaleString(),
            effectiveSavingsPerMonth: effectiveSavingsPerMonth.toFixed(2),
        };
    };
    const breakpoint = parseInt(
        theme.breakPoints.smallTablet.replace("px", ""),
        10
    );
    const windowWidth = useWindowDimensions().width;
    const isSmallTablet = windowWidth < breakpoint;
    const history = useHistory();
    return (
        <Section height="auto">
            <Container>
                <Space xxsm={["30px 0 30px 0 "]}>
                    <Flex.Container
                        onClick={() => history.goBack()}
                        align="center"
                        style={{ cursor: "pointer" }}
                    >
                        <Space xxsm={["0 1rem 0 0"]} stm={["0 2rem 0 0"]}>
                            <Flex.Item>
                                <Icon component={BsArrowLeft} lg="l" />
                            </Flex.Item>
                        </Space>
                        <Flex.Item>
                            <P>Back</P>
                        </Flex.Item>
                    </Flex.Container>
                </Space>
                {isSmallTablet ? (
                    <Accordion
                        label="Consumer Data"
                        isControlled
                        isOpen={false}
                    >
                        <ConsumerDataForm
                            fields2={fields2}
                            formData2={formData2}
                            intro={intro}
                            checkboxes={checkboxes}
                        />
                    </Accordion>
                ) : (
                    <>
                        <Space xxsm={["10px 0 30px"]}>
                            <H4>Consumer Data</H4>
                        </Space>
                        <ConsumerDataForm
                            fields2={fields2}
                            formData2={formData2}
                            intro={intro}
                            checkboxes={checkboxes}
                        />
                    </>
                )}
                <Space xxsm={["10px 0 30px"]}>
                    <H4>Proposal Summary</H4>
                </Space>
                <ProposalDeatilsContainer>
                    <Flex.Container>
                        {checkboxes.Purchase && (
                            <Space xxsm={["0 2rem 0 0"]}>
                                <Flex.Item>
                                    <ButtonStyled
                                        isActive={active === "Purchase"}
                                        onClick={() => setActive("Purchase")}
                                    >
                                        Purchase
                                    </ButtonStyled>
                                </Flex.Item>
                            </Space>
                        )}
                        {connectionType !== "Individual Homes" &&
                            connectionType !== "Industrial" &&
                            checkboxes.Lease && (
                                <Space xxsm={["0 2rem 0 0"]}>
                                    <Flex.Item>
                                        <ButtonStyled
                                            isActive={active === "Lease"}
                                            onClick={() => setActive("Lease")}
                                        >
                                            Lease
                                        </ButtonStyled>
                                    </Flex.Item>
                                </Space>
                            )}
                        {checkboxes.Loan && (
                            <Space xxsm={["0 2rem 0 0"]}>
                                <Flex.Item>
                                    <ButtonStyled
                                        isActive={active === "Loan"}
                                        onClick={() => setActive("Loan")}
                                    >
                                        Loan
                                    </ButtonStyled>
                                </Flex.Item>
                            </Space>
                        )}
                    </Flex.Container>
                    {connectionType === "Commercial" &&
                        intro === "Yes" &&
                        active === "Lease" && (
                            <P weight={"m"}>Tenant Savings</P>
                        )}
                    <ProposalDeatilsContainer backgroundColor={"whitesmoke"}>
                        <Flex.Container justify="space-between" align="start">
                            {connectionType === "Commercial" &&
                            intro === "Yes" &&
                            active === "Lease" ? (
                                <Flex.Item xl="25%" t="100%" xxs="100%">
                                    <DetailsFiled
                                        label={"Bill (Without GST)"}
                                        value={billWithoutGST.toLocaleString()}
                                        disabled={true}
                                        direction={"column"}
                                    />
                                </Flex.Item>
                            ) : (
                                <Flex.Item xl="25%" t="100%" xxs="100%">
                                    <DetailsFiled
                                        label={"Solar Plant Size"}
                                        value={`${solarPlantSize} KWp`}
                                        disabled={true}
                                        direction={"column"}
                                    />
                                </Flex.Item>
                            )}
                            {active === "Lease" &&
                                connectionType === "Commercial" &&
                                intro === "Yes" && (
                                    <Flex.Item xl="25%" t="100%" xxs="100%">
                                        <DetailsFiled
                                            label={"GST (18%)"}
                                            value={`${
                                                intro === "Yes"
                                                    ? tenantGST.toLocaleString()
                                                    : Gst.toLocaleString()
                                            }`}
                                            disabled={true}
                                            direction={"column"}
                                        />
                                    </Flex.Item>
                                )}
                            {active === "Lease" &&
                            connectionType === "Commercial" &&
                            intro === "Yes" ? (
                                <Flex.Item xl="25%" t="100%" xxs="100%">
                                    <DetailsFiled
                                        label={"Total Bill Payable"}
                                        value={`${
                                            intro === "Yes"
                                                ? averageMonthlyBill.toLocaleString()
                                                : totalBillPayable.toLocaleString()
                                        }`}
                                        disabled={true}
                                        direction={"column"}
                                    />
                                </Flex.Item>
                            ) : (
                                active === "Lease" && (
                                    <Flex.Item xl="25%" t="100%" xxs="100%">
                                        <DetailsFiled
                                            label={"Units Consumed"}
                                            value={`${Number(
                                                formData2[
                                                    "Consumer’s Total Unit"
                                                ]
                                            ).toLocaleString()}
                                                    `}
                                            disabled={true}
                                            direction={"column"}
                                        />
                                    </Flex.Item>
                                )
                            )}
                            {active === "Lease" &&
                            connectionType === "Commercial" &&
                            intro === "Yes" ? (
                                <Flex.Item xl="25%" t="100%" xxs="100%">
                                    <DetailsFiled
                                        label={"Monthly Savings"}
                                        value={`${monthlySavings.toLocaleString()}`}
                                        disabled={true}
                                        direction={"column"}
                                    />
                                </Flex.Item>
                            ) : (
                                active === "Lease" && (
                                    <Flex.Item xl="25%" t="100%" xxs="100%">
                                        <DetailsFiled
                                            label={"Average Monthly Bill"}
                                            value={`${Number(
                                                consumerBills
                                            ).toLocaleString()}`}
                                            disabled={true}
                                            direction={"column"}
                                        />
                                    </Flex.Item>
                                )
                            )}
                            {(connectionType === "Residential Society" ||
                                connectionType === "Individual Homes") &&
                                intro === "Yes" && (
                                    <Flex.Item xl="25%" t="100%" xxs="100%">
                                        <DetailsFiled
                                            label={"Subsidy Amount"}
                                            value={`${subsidyAmount}`}
                                            disabled={true}
                                            direction={"column"}
                                        />
                                    </Flex.Item>
                                )}
                            {(connectionType === "Commercial" ||
                                connectionType === "Industrial") &&
                                active !== "Lease" && (
                                    <Flex.Item xl="25%" t="100%" xxs="100%">
                                        <DetailsFiled
                                            label={"GST"}
                                            value={gst.toLocaleString()}
                                            disabled={true}
                                            direction={"column"}
                                        />
                                    </Flex.Item>
                                )}
                            {connectionType === "Commercial" &&
                                active === "Lease" && (
                                    <Flex.Item xl="25%" t="100%" xxs="100%">
                                        <DetailsFiled
                                            label={"Savings Percentage"}
                                            value={`${savingsPercentage.toFixed(
                                                2
                                            )}%`}
                                            disabled={true}
                                            direction={"column"}
                                        />
                                    </Flex.Item>
                                )}
                            {active === "Loan" && (
                                <Flex.Item xl="35%" t="100%" xxs="100%">
                                    <DetailsFiled
                                        label={"Upfront Payment (30%)"}
                                        value={upfront}
                                        disabled={true}
                                        direction={"column"}
                                    />
                                </Flex.Item>
                            )}
                        </Flex.Container>
                    </ProposalDeatilsContainer>
                    {connectionType === "Commercial" &&
                        intro === "Yes" &&
                        active === "Lease" && <P weight={"m"}>Owner Savings</P>}
                    {connectionType === "Commercial" &&
                    intro === "Yes" &&
                    active === "Lease" ? (
                        <ProposalDeatilsContainer
                            backgroundColor={"whitesmoke"}
                        >
                            <Flex.Container
                                justify="space-between"
                                align="start"
                            >
                                <Flex.Item xl="30%" t="100%" xxs="100%">
                                    <DetailsFiled
                                        label={"Bill Collected From Tenant"}
                                        value={billWithoutGST.toLocaleString()}
                                        disabled={true}
                                        direction={"column"}
                                    />
                                </Flex.Item>

                                <Flex.Item xl="30%" t="100%" xxs="100%">
                                    <DetailsFiled
                                        label={"Bill Payable to Hypersrot"}
                                        value={`${billPayableToHypersrot.toLocaleString()}
                                            `}
                                        disabled={true}
                                        direction={"column"}
                                    />
                                </Flex.Item>

                                <Flex.Item xl="30%" t="100%" xxs="100%">
                                    <DetailsFiled
                                        label={"Savings on Collection"}
                                        value={`${savingsOnCollection.toLocaleString()}`}
                                        disabled={true}
                                        direction={"column"}
                                    />
                                </Flex.Item>

                                <Flex.Item xl="30%" t="100%" xxs="100%">
                                    <DetailsFiled
                                        label={"GST Collected From Tenant"}
                                        value={`${tenantGST.toLocaleString()}`}
                                        disabled={true}
                                        direction={"column"}
                                    />
                                </Flex.Item>

                                <Flex.Item xl="30%" t="100%" xxs="100%">
                                    <DetailsFiled
                                        label={"GST Payable to Hypersrot"}
                                        value={gstPayableToHypersrot.toLocaleString()}
                                        disabled={true}
                                        direction={"column"}
                                    />
                                </Flex.Item>

                                <Flex.Item xl="30%" t="100%" xxs="100%">
                                    <DetailsFiled
                                        label={"GST Payable to Government"}
                                        value={gstPayableToGovernment.toLocaleString()}
                                        disabled={true}
                                        direction={"column"}
                                    />
                                </Flex.Item>
                            </Flex.Container>
                        </ProposalDeatilsContainer>
                    ) : (
                        <TableContainer borderColor={"black"}>
                            <thead>
                                <tr>
                                    <Header borderColor="black">
                                        <P
                                            xxs="xs"
                                            st="s"
                                            lg="m"
                                            color="white"
                                            size="s"
                                        >
                                            Description
                                        </P>
                                    </Header>
                                    <Header borderColor="black">
                                        {" "}
                                        <P
                                            xxs="xs"
                                            st="s"
                                            lg="m"
                                            color="white"
                                            size="s"
                                        >
                                            Amount
                                        </P>
                                    </Header>
                                </tr>
                            </thead>
                            {active !== "Lease" ? (
                                <tbody>
                                    <tr>
                                        <Cell>
                                            <Flex.Container
                                                justify="space-between"
                                                align="baseline"
                                            >
                                                <Flex.Item>
                                                    <P xxs="xs" st="s" lg="m">
                                                        Solar Plant Size:{" "}
                                                        {solarPlantSize} KWp
                                                    </P>
                                                </Flex.Item>
                                                <Flex.Item>
                                                    <P xxs="xs" st="s">
                                                        <StyleLink href="">
                                                            Specification
                                                        </StyleLink>
                                                    </P>
                                                </Flex.Item>
                                            </Flex.Container>
                                        </Cell>
                                        <Cell align="end">
                                            <P xxs="xs" st="s" lg="m">
                                                {SavedAmout}
                                            </P>
                                        </Cell>
                                    </tr>
                                    <tr>
                                        <Cell align="start">
                                            <P xxs="xs" st="s" lg="m">
                                                GST @ 13.8%
                                            </P>
                                        </Cell>
                                        <Cell align="end">
                                            <P xxs="xs" st="s" lg="m">
                                                {gst}
                                            </P>
                                        </Cell>
                                    </tr>
                                    <tr>
                                        <Cell align="start">
                                            <P xxs="xs" st="s" lg="m">
                                                Total Amount
                                            </P>
                                        </Cell>
                                        <Cell align="end">
                                            <P xxs="xs" st="s" lg="m">
                                                {totalAmt}
                                            </P>
                                        </Cell>
                                    </tr>
                                </tbody>
                            ) : connectionType !== "Commercial" ? (
                                <tbody>
                                    <tr>
                                        <Cell align="start">
                                            <P xxs="xs" st="s" lg="m">
                                                Fixed Monthly Rental
                                            </P>
                                        </Cell>
                                        <Cell align="end">
                                            <P xxs="xs" st="s" lg="m">
                                                {leaseTable.fixedMonthlyRental}
                                            </P>
                                        </Cell>
                                    </tr>
                                    <tr>
                                        <Cell align="start">
                                            <P xxs="xs" st="s" lg="m">
                                                Bank Guarantee
                                            </P>
                                        </Cell>
                                        <Cell align="end">
                                            <P xxs="xs" st="s" lg="m">
                                                {leaseTable.bankGuarantee}
                                            </P>
                                        </Cell>
                                    </tr>
                                    <tr>
                                        <Cell align="start">
                                            <P xxs="xs" st="s" lg="m">
                                                Savings on Existing Bills
                                            </P>
                                        </Cell>
                                        <Cell align="end">
                                            <P xxs="xs" st="s" lg="m">
                                                {
                                                    leaseTable.savingsOnExistingBillsPercentage
                                                }
                                                %
                                            </P>
                                        </Cell>
                                    </tr>
                                    <tr>
                                        <Cell align="start">
                                            <P xxs="xs" st="s" lg="m">
                                                Government Subsidy
                                            </P>
                                        </Cell>
                                        <Cell align="end">
                                            <P xxs="xs" st="s" lg="m">
                                                {leaseTable.governmentSubsidy}
                                            </P>
                                        </Cell>
                                    </tr>
                                    <tr>
                                        <Cell align="start">
                                            <P xxs="xs" st="s" lg="m">
                                                Effective Savings Per Month
                                            </P>
                                        </Cell>
                                        <Cell align="end">
                                            <P xxs="xs" st="s" lg="m">
                                                {
                                                    leaseTable.effectiveSavingsPerMonth
                                                }
                                                %
                                            </P>
                                        </Cell>
                                    </tr>
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <Cell align="start">
                                            <P xxs="xs" st="s" lg="m">
                                                Bill Payable to Hypersrot
                                            </P>
                                        </Cell>
                                        <Cell align="end">
                                            <P xxs="xs" st="s" lg="m">
                                                {billPayableToHypersrot.toLocaleString()}
                                            </P>
                                        </Cell>
                                    </tr>
                                    <tr>
                                        <Cell align="start">
                                            <P xxs="xs" st="s" lg="m">
                                                GST (18%)
                                            </P>
                                        </Cell>
                                        <Cell align="end">
                                            <P xxs="xs" st="s" lg="m">
                                                {Gst}
                                            </P>
                                        </Cell>
                                    </tr>
                                    <tr>
                                        <Cell align="start">
                                            <P xxs="xs" st="s" lg="m">
                                                Total Bill Payable
                                            </P>
                                        </Cell>
                                        <Cell align="end">
                                            <P xxs="xs" st="s" lg="m">
                                                {totalBillPayable.toLocaleString()}
                                            </P>
                                        </Cell>
                                    </tr>
                                    <tr>
                                        <Cell align="start">
                                            <P xxs="xs" st="s" lg="m">
                                                Bill Without Solar
                                            </P>
                                        </Cell>
                                        <Cell align="end">
                                            <P xxs="xs" st="s" lg="m">
                                                {Number(
                                                    consumerBills
                                                ).toLocaleString()}
                                            </P>
                                        </Cell>
                                    </tr>
                                    <tr>
                                        <Cell align="start">
                                            <P xxs="xs" st="s" lg="m">
                                                Monthly Savings
                                            </P>
                                        </Cell>
                                        <Cell align="end">
                                            <P xxs="xs" st="s" lg="m">
                                                {monthlySavings.toLocaleString()}
                                            </P>
                                        </Cell>
                                    </tr>
                                </tbody>
                            )}
                        </TableContainer>
                    )}
                    {connectionType === "Commercial" &&
                        intro === "Yes" &&
                        active === "Lease" && (
                            <ProposalDeatilsContainer
                                backgroundColor={"primary"}
                            >
                                <Flex.Container justify="center" align="center">
                                    <P color="white">
                                        Owner Earning with Tenants using Solar:{" "}
                                        {(
                                            savingsOnCollection.toFixed(0) -
                                            gstPayableToGovernment.toFixed(0)
                                        ).toLocaleString()}
                                    </P>
                                </Flex.Container>
                            </ProposalDeatilsContainer>
                        )}
                    <Space xxsm={[" 2rem 0"]}>
                        <Flex.Container justify="space-between" align="center">
                            <Flex.Container align="center">
                                <Space xxsm={["0 2rem 0 0"]}>
                                    <ButtonStyled
                                        isActive={true}
                                        onClick={() => saveProposal()}
                                    >
                                        Save Proposals
                                    </ButtonStyled>
                                </Space>
                                <Space xxsm={["0 2rem 0 0"]}>
                                    <ButtonStyled
                                        isActive={false}
                                        disabled={true}
                                    >
                                        Download Proposals
                                    </ButtonStyled>
                                </Space>
                            </Flex.Container>

                            <ButtonStyled
                                isActive={false}
                                isCancel={true}
                                onClick={() => setShowSummary(true)}
                            >
                                Revise Proposals
                            </ButtonStyled>
                        </Flex.Container>
                    </Space>
                </ProposalDeatilsContainer>
            </Container>
        </Section>
    );
}

export default SaveProposal;
const fields2 = [
    {
        label: "Connection Type",
        name: "Connection Type",
        value: "Residential Society",
    },
    {
        label: "Consumer’s Bill",
        name: "Consumer’s Bill",
        placeholder: "Enter Total Bill Amount",
        value: "18,00,000",
    },
    {
        label: "Consumer’s Total Unit",
        name: "Consumer’s Total Unit",
        placeholder: "Total Electricity Unit Consumed",
        value: "00",
        helperText: true,
        helperTextAlign: "end",
    },
    {
        label: "Plan Duration",
        name: "Plan Duration",
        value: "5 Years",
    },
];

export const DetailsFiled = ({
    label,
    isSelect,
    data,
    selected,
    textAlign,
    direction,
    backgroundColor = "grey300",
    onChange = null,
    xl = "100%",
    st = "60%",
    s = "100%",
    ...rest
}) => {
    return (
        <Space margin={["0 0 30px"]} tm={["0 0 25px"]} xsm={["0 0 10px"]}>
            <Flex.Container
                justify="space-between"
                direction={direction ? direction : "row"}
            >
                <Space xxsm={["10px 0 10px"]} stm={["10px 0 5px"]}>
                    <Flex.Item
                        className="label-wrapper"
                        xl={xl}
                        st={st}
                        s={st}
                        xxs="100%"
                    >
                        <P color="black">{label}</P>
                    </Flex.Item>
                </Space>
                {isSelect ? (
                    <FlexItem
                        className="input-wrapper"
                        // s="100%"
                        xxs="100%"
                        // xl="70%"
                    >
                        <DropDown
                            data={data}
                            onChange={onChange}
                            selected={selected}
                            {...rest}
                        />
                    </FlexItem>
                ) : (
                    <FlexItem
                        className="input-wrapper"
                        // s="100%"
                        xxs="100%"
                        // xl="90%"
                    >
                        <Input
                            backgroundColor={backgroundColor}
                            shadow={false}
                            onChange={onChange}
                            style={{
                                paddingBottom: "7px",
                                paddingTop: "7px",
                                textAlign: textAlign,
                            }}
                            {...rest}
                        />
                    </FlexItem>
                )}
            </Flex.Container>
        </Space>
    );
};

const FlexItem = styled(Flex.Item)`
    @media (min-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        flex: 1;
    }
`;
