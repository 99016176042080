import React from "react";
import { AiOutlineSend } from "react-icons/ai";
import { GoPlus } from "react-icons/go";
import { LiaTimesSolid } from "react-icons/lia";
import Loading from "../../../components/loading/Loading";
import cn from "../../../lib/cn";
import P from "../../../shared/typography/P";

const ChatInputField = ({
    onSubmit,
    value,
    onChange,
    file,
    removeFile,
    handleFileChange,
    uploading,
    disabled,
}) => {
    return (
        <form
            onSubmit={onSubmit}
            className="flex flex-col justify-between border border-blue-400 transition-all duration-150  group focus-within:border-blue-100 h-auto relative bg-white rounded-sm px-px box-border  "
        >
            {file.length > 0 && (
                <div className="absolute bottom-[88px]  xl:bottom-[99px] right-0  left-0 min-h-10 bg-background border border-blue-300 px-1 py-1 rounded-lg max-h-[200px] overflow-y-auto no-scrollbar">
                    {file.map((file, i) => (
                        <div className="px-1 py-0.5 border border-blue-300 rounded flex justify-between gap.0.5 mb-0.2 ">
                            <P className="xl:text-sm 2xl:text-sm text-secondary line-clamp-1">
                                {file.name}
                            </P>
                            <button
                                onClick={() => removeFile(i)}
                                className="hover:cursor-pointer text-sm lg:text-lg xl:text-xl"
                            >
                                <LiaTimesSolid />
                            </button>
                        </div>
                    ))}
                </div>
            )}
            <div className="bg-blue-400 flex-1 rounded-sm overflow-hidden">
                <textarea
                    style={{
                        resize: "none",
                    }}
                    type="text"
                    placeholder="Send Message"
                    className="w-full h-full outline-none text-sm xl:text-base 2xl:text-lg no-scrollbar resize-none px-1 py-0.5"
                    value={value}
                    onChange={onChange}
                />
            </div>

            <div className="flex items-center justify-between mx-1 mb-0.5 mt-0.2">
                <label
                    htmlFor="file_upload_support"
                    className={cn(
                        "flex text-2xl bg-blue-300 text-blue-100 items-center justify-center  hover:bg-blue-400 rounded-full hover:cursor-pointer duration-200 ",
                        { "hover:cursor-not-allowed": uploading }
                    )}
                >
                    {uploading ? (
                        <Loading />
                    ) : (
                        <>
                            <GoPlus />
                            <input
                                className="hidden"
                                type="file"
                                name="file_upload_support"
                                accept="*"
                                id="file_upload_support"
                                onChange={handleFileChange}
                                onClick={(e) => (e.target.value = null)}
                            />
                        </>
                    )}
                </label>
                <button
                    type="submit"
                    disabled={disabled}
                    className="flex  justify-center   text-blue-500 hover:text-blue items-center text-2xl hover:cursor-pointer duration-200 disabled:text-blue-200 disabled:cursor-not-allowed "
                >
                    <AiOutlineSend />
                </button>
            </div>
        </form>
    );
};

export default ChatInputField;
