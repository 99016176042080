import PropTypes from "prop-types";
import React from "react";
import cn from "../../lib/cn"; // Assuming you have a utility for handling conditional class names
import P from "../typography/P";

const CustomButton = ({ icon, text, onClick, color = "primary", disabled }) => {
    const colorClasses = {
        primary: " border border-primary text-primary hover:bg-primary-light",
        secondary:
            "border border-secondary text-secondary hover:bg-secondary-light",
        blue: "border border-blue text-blue hover:bg-gray-50",
        red: "border border-red text-red hover:bg-red-light",
    };
    const baseStyles =
        "font-semibold font-urbanist text-xs sm:text-btn-s  lg:text-btn-s py-0.6 px-0.8 sm:py-0.8 sm:px-1.6  lg:py-1 lg:px-1.6  rounded-0.8 lg:rounded-sm xl:hover:drop-shadow-lg relative group overflow-hidden duration-500 ease transition-all flex justify-center";
    return (
        <div
            role="button"
            onClick={onClick}
            className={cn(
                baseStyles,
                "rounded-sm flex gap-1 items-center  cursor-pointer transition-all duration-300 ease-in-out",
                colorClasses[color],
                {
                    "bg-gray-secondary": disabled,
                }
            )}
        >
            {icon && (
                <P className={`${!text && "text-lg"} text-${color}`}>{icon}</P>
            )}
            {text && <P className={`text-${color}`}>{text}</P>}
        </div>
    );
};

CustomButton.propTypes = {
    icon: PropTypes.node,
    text: PropTypes.node,
    onClick: PropTypes.func.isRequired,
    color: PropTypes.oneOf(["primary", "secondary"]), // Add more if needed
};

CustomButton.defaultProps = {
    icon: null,
    text: null,
    color: "primary",
};

export default CustomButton;
