import moment from "moment";
import React from "react";
import { AiOutlineFile, AiOutlineFileImage } from "react-icons/ai";
import cn from "../../../lib/cn";
import P from "../../../shared/typography/P";

import fileDownload from "js-file-download";
import { AiOutlineFilePdf } from "react-icons/ai";
import { toast } from "react-toastify";
import { ERROR_MSG } from "../../../constants/common";
import { DOWNLOAD_TICKET_ATTACHMENTS_ENDPOINT } from "../../../constants/routes";
import request from "../../../services/request";

const DOCUMENT_ICON = {
    pdf: <AiOutlineFilePdf />,
    png: <AiOutlineFileImage />,
    jpg: <AiOutlineFileImage />,
    jpeg: <AiOutlineFileImage />,
    file: <AiOutlineFile />,
};
const ChatBoxItem = (props) => {
    const renderIcons = (fileName) => {
        let extension = fileName
            .slice(fileName.lastIndexOf(".") + 1)
            .toLocaleLowerCase();
        let icons = DOCUMENT_ICON[extension];
        return icons ? icons : DOCUMENT_ICON["file"];
    };
    const handleDownloadAttachments = async (documentId, documentName) => {
        try {
            let response = await request.authPost({
                endpoint: DOWNLOAD_TICKET_ATTACHMENTS_ENDPOINT,
                body: {
                    ticketId: props.ticketId,
                    documentId: documentId,
                },
                checkStatusCode: true,
            });

            const file = await response.blob();
            fileDownload(file, documentName);
        } catch (error) {
            toast.error(typeof error === "string" ? error : ERROR_MSG);
        }
    };
    return (
        <div
            className={cn("w-[90%]  p-0.8 mb-2 rounded-sm", {
                " bg-gray-200": props.userType === "USER",
                "bg-gray self-end": props.userType !== "USER",
            })}
        >
            <div className="flex flex-wrap gap-0.5 justify-between items-center mb-1 xl:mb-1.2">
                <div className="flex gap-0.5 xl:gap-0.8 mb-0.8">
                    <div className="w-[25px] xl:w-[30px]  h-[25px] xl:h-[30px] rounded-full bg-black text-base flex justify-center items-center font-bold text-white">
                        {props.username.charAt(0)}
                    </div>
                    <P className="2xl:text-base">{props.username}</P>
                </div>
                <p className="text-xs  text-secondary mb-0.8">
                    {moment(props.createdAt * 1000).format(
                        "DD MMM YYYY, h:mm A"
                    )}
                </p>
            </div>
            <P className="xl:text-sm 2xl:text-sm text-secondary mb-0.5 xl:mb-0.8">
                {props.message}
            </P>
            {props?.attachments?.length > 0 && (
                <div className="flex flex-wrap items-center justify-between gap-y-0.2">
                    {props.attachments.map((item) => {
                        return (
                            <div
                                key={item.documentId}
                                role="button"
                                onClick={() =>
                                    handleDownloadAttachments(
                                        item.documentId,
                                        item.documentName
                                    )
                                }
                                className=" border border-gray-230 px-0.5 bg-white rounded-0.8  py-0.5 w-full sm:w-[48%]  hover:bg-gray-110 cursor-pointer"
                            >
                                <div className="flex items-center">
                                    <div className="text-5xl mr-0.5 text-gray-140">
                                        {renderIcons(item.documentName)}
                                    </div>
                                    <p className=" text-gray-140  text-nowrap truncate text-sm">
                                        {item.documentName}
                                    </p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default ChatBoxItem;
