import React from "react";
import cn from "../../../lib/cn";
import { lightStar, star } from "../../../resources/js/icons";

const FeedbackStars = ({
    onMouseLeave,
    onMouseOver,
    onClick,
    selectedStars,
    filledStars,
    className,
}) => {
    return (
        <div
            className={cn("flex gap-0.5 justify-center", {
                "hover:cursor-not-allowed": !onMouseLeave,
            })}
            onMouseLeave={onMouseLeave ? onMouseLeave : null}
        >
            {[...Array(5).keys()].map((_, index) => (
                <img
                    onMouseOver={() =>
                        onMouseOver ? onMouseOver(index + 1) : null
                    }
                    onClick={() => (onClick ? onClick(index + 1) : null)}
                    src={
                        selectedStars >= index + 1 || filledStars >= index + 1
                            ? star
                            : lightStar
                    }
                    alt="star"
                    className={cn(
                        "w-2 h-2 xl:w-4 xl:h-4 hover:cursor-pointer ",
                        className,
                        {
                            "hover:cursor-not-allowed": !onMouseLeave,
                        }
                    )}
                />
            ))}
        </div>
    );
};

export default FeedbackStars;
