import React, { useEffect, useRef } from "react";
import cn from "../../../lib/cn";

const BottomDrawer = ({ isOpen, onClick, children }) => {
    const ref = useRef();

    const handleContainerClicked = (e) => {
        if (!ref.current.contains(e.target)) {
            onClick(false);
        }
    };
    useEffect(() => {
        if (isOpen) {
            document.body.style.overflowY = "hidden";
        } else {
            document.body.style.overflowY = "unset";
        }
    }, [isOpen]);

    return (
        <div
            className={cn(
                "fixed  left-0 bottom-0 right-0 bg-black/30 z-101 h-full pt-[105px] transition-all duration-300 ease-in",
                {
                    "bottom-0 ": isOpen,
                    "top-full": !isOpen,
                }
            )}
            role="button"
            onClick={handleContainerClicked}
        >
            <div className="h-full" ref={ref}>
                {children}
            </div>
        </div>
    );
};

export default BottomDrawer;
