import PropTypes from "prop-types";
import React from "react";
import cn from "../../lib/cn";

const TableComponent = ({
    columns,
    data,
    headerColor,
    oddRowColor,
    additionalRowClassName,
    borderStyle,
    fontSize,
    rowStyles,
    columnClassName = "",
    onClick = () => {},
}) => {
    return (
        <div className="overflow-x-auto">
            <table className={cn("min-w-full text-left", borderStyle)}>
                <thead className={cn(headerColor)}>
                    <tr>
                        {columns.map((column, index) => (
                            <th
                                key={index}
                                className={cn(
                                    "px-4 py-2 font-semibold xl:text-nowrap",
                                    fontSize
                                )}
                            >
                                {column}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowIndex) => (
                        <tr
                            key={rowIndex}
                            className={cn(additionalRowClassName, {
                                [oddRowColor]: rowIndex % 2 !== 0,
                            })}
                            style={rowStyles}
                            onClick={() => onClick(row["Ticket ID"])}
                        >
                            {columns.map((column, colIndex) => (
                                <td
                                    key={colIndex}
                                    className={cn(
                                        "px-4 py-2 md:text-nowrap",

                                        columnClassName,
                                        fontSize
                                    )}
                                >
                                    {row[column]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

TableComponent.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.string).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    headerColor: PropTypes.string,
    oddRowColor: PropTypes.string,
    additionalRowClassName: PropTypes.string,
    borderStyle: PropTypes.string,
    fontSize: PropTypes.string,
    rowStyles: PropTypes.object,
};

TableComponent.defaultProps = {
    headerColor: "bg-gray-170",
    oddRowColor: "",
    additionalRowClassName: "bg-white",
    borderStyle: "border border-gray-200",
    fontSize: "text-xs md:text-sm",
    rowStyles: {},
};

export default TableComponent;
