import React from "react";
import { MdDoNotDisturbAlt } from "react-icons/md";
import Container from "../../../shared/Container";
import Button from "../../../shared/ui/Button";
import CustomButton from "../../../shared/ui/CustomButton";

const DetailsWrapper = ({ children }) => {
    return (
        <Container>
            <div className="border rounded-xl flex flex-col gap-3.2 mb-5 bg-gray-170 p-1 md:p-2">
                {children}
                {/* @DESC:: Common for all three @habib610 Mon August 12,2024 */}
                <div className="flex justify-between items-center mt-2">
                    <CustomButton
                        color="red"
                        icon={<MdDoNotDisturbAlt />}
                        text="Reject"
                        onClick={() => console.log(" Button Clicked")}
                    />
                    <div className="flex items-center gap-1">
                        <CustomButton
                            text="Revise"
                            onClick={() => console.log(" Button Clicked")}
                        />
                        <Button>Approve</Button>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default DetailsWrapper;
