import React from "react";
import Container from "../../shared/Container";
import TableComponent from "../../shared/ui/Table";

const AdminTable = ({ tableData, columns }) => {
    return (
        <Container>
            <div className="bg-gray-170 w-full  rounded-2xl p-1 md:p-2">
                <div className="bg-white border-2 border-dashed rounded-xl px-1 py-1 md:px-2 md:py-1.5 mt-2 border-gray-220">
                    <TableComponent
                        columns={columns}
                        data={tableData}
                        headerColor="bg-blue-300"
                        oddRowColor="bg-gray-170"
                        additionalRowClassName="rounded-xl"
                        borderStyle="border-collapse border border-gray-200"
                        fontSize="text-xs md:text-sm"
                    />
                </div>
            </div>
        </Container>
    );
};

export default AdminTable;
