import { isProdEnv } from "../helpers/helpers";
export const LANDING = "/";
export const LOGIN = "/login";
export const SIGNUP = "/signup";
export const PROFILE = "/profile";
export const REFERRAL = "/referral";
export const FAQ = "/faq";
export const OPPORTUNITY = "/opportunity";
export const OPPORTUNITYDETAILS = "/opportunity/:id";
export const COUPON = "/coupon";
export const WALLET = "/wallet";
export const CONTACTUS = "/contact-us";
export const PORTFOLIO = "/portfolio";
export const PRICING = "/pricing";
export const TERMS_N_CONDITIONS = "/terms-n-condition";
export const PRIVACY = "/privacy";
export const FORGET = "/forget-password";
export const RESET_SCREEN = "/reset";
export const VERIFY_EMAIL = "/verify_email";
export const ERROR_SCREEN = "/error";
export const HOW_TO_SCREEN = "/how-to";
export const NOT_FOUND = "/404";
export const UN_AUTHORIZED = "/401";
export const THANK_YOU = "/thankyou";
export const DASHBOARD = "/dashboard";
export const OFFSET = "/offset";
export const PROJECTS = "/projects";
export const ONBOARDING = "/onboarding";
export const ABOUT = "/about";
export const BLOGS = "/blogs";
export const INVESTMENT = "/investment";
export const LEARN = "/learn";
export const SURVEY = "/survey";
export const BOOKING = "/booking";
export const PROPOSAL = "/proposal";
export const BANKING = "/banking";
export const AGREEMENT = "/agreements";
export const ACTIVATION = "/activation";
export const COMMUNITY = "/community";
export const ADMIN = "/admin";
export const AUDIT = "/audit";
export const PROPERTY_DETAILS = "/property-details";
export const PAYMENT = "/payment";
export const SURVEY_VIEW = "/survey-view";
export const CALCULATOR = "/calculator";
export const PURCHASE_DETAILS = "/purchaseDetails";
export const LEASE_DETAILS = "/leaseDetails";
export const TICKETS = "/tickets";

export const BLOGS_LABEL = "Blogs";
export const LEARN_LABEL = "Learn";
export const PRICING_LABEL = "Pricing";
export const ONBOARDING_LABEL = "Onboarding";
export const ABOUT_US_LABEL = "About Us";
export const LOGIN_LABEL = "Login";
export const FAQ_LABEL = "FAQs";
export const CONTACTUS_LABEL = "Contact Us";
export const OPPORTUNITY_LABEL = "Opportunity";
export const PORTFOLIO_LABEL = "Portfolio";
export const PROFILE_LABEL = "Profile";
export const REFERRAL_LABEL = "Referral";
export const WALLET_LABEL = "Wallet";
export const HOW_TO_LABEL = "How To";
export const DASHBOARD_LABEL = "Dashboard";
export const OFFSET_LABEL = "Impact";
export const PROJECTS_LABEL = "Projects";
export const COMMUNITY_LABEL = "Community";
export const PROPERTY_DETAILS_LABEL = "Property Details";
export const PAYMENT_LABEL = "Payment";
export const SURVEY_VIEW_LABEL = "Survey";
export const AUDIT_LABEL = "Audit";
export const ADMIN_LABEL = "Admin";
export const UPDATE_USER_ROLE_LABEL = "Update User Role";
export const UPDATE_MASTER_DATA_LABEL = "Update Master Data";
export const REVISION_REQ = "Revision Requests";
export const TICKETS_LABEL = "Ticket";
export const REF_CODE = "LAUNCH";

export const PRICING_LABELS = {
    RESIDENTIAL_SOCIETIES: `/solar-power-for-residential-society`,
    INDIVIDUAL_HOMES: `/solar-power-for-home`,
    COMMERCIAL: `/solar-power-for-commercial`,
    INDUSTRIAL: `/solar-power-for-industrial-use`,
};

export const REF_URL = isProdEnv()
    ? "https://srot.app/"
    : "https://test.srot.app/";

export const burgerNavLinks = [
    {
        name: ONBOARDING_LABEL,
        path: ONBOARDING,
    },
    {
        name: ABOUT_US_LABEL,
        path: ABOUT,
    },

    {
        name: BLOGS_LABEL,
        path: BLOGS,
    },
];

export const navLinks = [
    {
        name: ONBOARDING_LABEL,
        path: ONBOARDING,
    },
    {
        name: ABOUT_US_LABEL,
        path: ABOUT,
    },

    {
        name: BLOGS_LABEL,
        path: BLOGS,
    },
];

export const authNavLinks = [
    {
        name: ONBOARDING_LABEL,
        path: ONBOARDING,
    },
    {
        name: ABOUT_US_LABEL,
        path: ABOUT,
    },

    {
        name: BLOGS_LABEL,
        path: BLOGS,
    },
];

export const API_ENDPOINT = isProdEnv()
    ? process.env.REACT_APP_SROT_URI_PROD
    : process.env.REACT_APP_SROT_URI_DEV;
export const SIGNUP_ENDPOINT = "/signup/investor";
export const EMAIL_VERIFICATION_CODE_ENDPOINT =
    "/investor/generate_email_verification_code";
export const VERIFY_EMAIL_ENDPOINT = "/signup/verify_email";
export const LOGIN_ENDPOINT = "/login";
export const LOGOUT_ENDPOINT = "/logout";
export const LOGIN_OTP_ENDPOINT = "/login/otp";
export const REFRESH_TOKEN_ENDPOINT = "/login/refreshToken";
export const CHECK_WALLET_BALANCE_ENDPOINT = "/investor/wallet/getWallet";
export const QR_CODE_ENDPOINT = "https://upiqr.in/api/qr";
export const UPDATE_PAYMENT_ORDER_ENDPOINT = "/investor/wallet/updateOrder";
export const WITHDRAW_WALLET_ENDPOINT = "/investor/wallet/withdraw";
export const UPDATE_EMAIL_ENDPOINT = "/investor/update_email";
export const CREATE_ORDER_ENDPOINT = "/investor/wallet/createOrder";
export const COUPON_API_ENDPOINT = "/investor/questionnaire";
export const PROFILE_API_ENDPOINT = "/investor/profile";
export const OPPORTUNITY_ENDPOINT = "/assets";
export const OPPORTUNITY_DETAILS_ENDPOINT = "/assets/details";
export const OPPORTUNITY_CANCEL_ENDPOINT = "/assets/investment/cancel";
export const VIRTUAL_INTEREST_CANCEL_ENDPOINT = "/investor/virtual/cancel";

export const GENERATE_DOC_ENDPOINT = "/sign/generateDoc";
export const INVESTOR_FUND_STATUS_ENDPOINT = "/assets/asset/fund/status";
export const SIGN_INVESTMENT_ENDPOINT = "/sign/invest";
export const REFERRAL_ENDPOINT = "/referral";
export const CHANGE_PASSWORD_ENDPOINT = "/investor/change_password";
export const FORGET_PASSWORD_ENDPOINT = "/investor/forget_password";
export const DOCUMENT_UPLOAD_ENDPOINT = "/investor/upload/documents";
export const BANK_VERIFICATION_ENDPOINT = "/investor/profile/bank_kyc";
export const CONTACTUS_FORM_ENDPOINT = "/contactus/ask";
export const INVESTOR_OPPORTUNITY_ENDPOINT = "/investor/opportunity";
export const SIGNUP_OPPORTUNITY_ENDPOINT = "/signup/opportunity";

export const GET_PAYMENT_METHOD_ENDPOINT =
    "/investor/wallet/supportedPGMethods";
export const UPDATE_PROFILE_ENDPOINT = "/investor/update";
export const PORTFOLIO_ENDPOINT = "/investor/portfolio";
export const CALLBACK_ENDPOINT = "/investor/profile/callback";
export const RESEND_OTP_ENDPOINT = "/otp/resend_otp";
export const SIGNUP_VIRTUAL_ENDPOINT = "/signup/virtual";
export const RAZORPAY_LOGO_ENDPOINT =
    "https://cdn.razorpay.com/logos/I7e2KpwjikUoNR_medium.png";
export const SIGN_DOCUMENT_DOWNLOAD = "/sign/document";
export const UPDATE_USER_ROLE = "/admin/updateUser";
export const UPDATE_MASTER_DATA = "/admin/updateMasterData";
export const REVISION_REQUEST = "/admin/revisonRequest";

export const RAZOR_SCRIPT_END_POINT = isProdEnv()
    ? process.env.REACT_APP_RAZOR_SCRIPT_URI_PROD
    : process.env.REACT_APP_RAZOR_SCRIPT_URI_DEV;
export const DIGIO_SCRIPT_END_POINT = `${
    isProdEnv()
        ? process.env.REACT_APP_DIGIO_BASE_URL_PROD
        : process.env.REACT_APP_DIGIO_BASE_URL_DEV
}/sdk/v9/digio.js`;

export const WHATSAPP_SDK_END_POINT = `${
    isProdEnv()
        ? process.env.REACT_APP_WHATS_APP_SDK_URI_PROD
        : process.env.REACT_APP_WHATS_APP_SDK_URI_DEV
}${new Date().getTime()}`;
export const WHATSAPP_SDK_SECRET = isProdEnv()
    ? process.env.REACT_APP_WHATS_APP_SDK_SECRET_PROD
    : process.env.REACT_APP_WHATS_APP_SDK_SECRET_DEV;

/* @DESC::  New variables  */
export const DEFAULT_REF_CODE = isProdEnv()
    ? process.env.REACT_APP_REFERRAL_CODE_PROD
    : process.env.REACT_APP_REFERRAL_CODE_DEV;

/* @DESC::  New endpoint for virtual-srot  */
export const VIRTUAL_DATA_ENDPOINT = "/signup/virtual";
export const SIGNUP_CONSUMER_ENDPOINT = "/signup/consumer";
export const QUICK_SIGNUP_ENDPOINT = "/signup/investor/quick";
export const QUICK_VIRTUAL_SIGNUP_ENDPOINT = "/signup/virtual/quick";
export const VALIDATE_API_ENDPOINT = "/signup/validate";
export const INVESTOR_VIRTUAL_API_ENDPOINT = "/investor/virtual";
export const CONSUMER_SIGNUP_API_ENDPOINT = "/signup/consumer";
export const CONSUMER_OWNER_SIGNUP_API_ENDPOINT = "/signup/onboarding";
export const CONSUMER_OWNER_ONBOARDING_API_ENDPOINT = "/consumer/onboarding";
export const BOOKING_SURVEY_API_ENDPOINT = "/consumer/survey";
export const GET_SURVEY_SITE_API_ENDPOINT = "/ops/survey/plant";

export const UPLOAD_SURVEY_IMAGE_ENDPOINT = "/ops/survey/upload";
export const UPLOAD_SITE_IMAGE_ENDPOINT = "/ops/survey/site/upload";

export const AGENT_SITE_ENDPOINT = "/ops/survey";
export const FIND_CONSUMER_API_ENDPOINT = "/consumer/search";
export const ADD_CONSUMER_API_ENDPOINT = "/consumer/add";

export const SURVEY_PROPOSAL_API_ENDPOINT = "/sales/survey/proposal";
export const VIEW_SURVEY_DOCUMENT_API_ENDPOINT = "/sales/survey/view";
export const GET_PROPOSAL_DATA_API_ENDPOINT = "/community/survey/proposal";
export const PLANT_SUBSCRIPTION_API_ENDPOINT = "/community/subscription/plant";
export const PLANT_SUBSCRIPTION_REGISTRATION_API_ENDPOINT =
    "/community/subscription/registration";
export const DOWNLOAD_MOU_DRAFT_DOCUMENT_ENDPOINT =
    "/consumer/survey/generate/mou";

export const PROPOSAL_STATUS_ENDPOINT = "/survey/proposal/status";
export const CONSUMER_BANKING_AUTHORIZATION_ENDPOINT = "/consumer/banking";
export const PROPOSAL_DOCUMENT_DOWNLOAD_ENDPOINT = "/sales/proposal/download";
export const PROPOSAL_DOCUMENT_META_ENDPOINT = "/sales/proposal/meta";
export const SALES_PROPOSAL_BASE_ENDPOINT = "/sales/proposal";
export const GET_ALL_PLANT_ENDPOINT = "/sales/plant/all";
export const GET_PLANT_BY_ID_ENDPOINT = "/sales/plant";

export const GET_PLANT_BY_QUERY_ENDPOINT = "/sales/plants/query";

export const NEW_ENQUIRY_ENDPOINT = "/sales/enquiry";

export const SAVE_MASTER_DATA_ENDPOINT = "/admin/masterData/save";

export const CURRENT_MASTER_DATA_ENDPOINT = "/sales/masterData/current";
export const SAVE_PROPOSAL_DATA_ENDPOINT = "/sales/proposal/save";
export const SALES_SUMMARY_DATA_ENDPOINT = "/sales/summary";
// @DESC::  Chat endpoint
export const CHAT_API_ENDPOINT = "/chat";

export const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

export const PROPOSAL_UPDATE = "/admin/proposal/update";
export const SALES_PROPOSAL_UPDATE = "/sales/proposal/update";

/* @DESC:: Support ticket @habib610 Thu August 22,2024 */
export const GET_SUPPORT_TICKET_QUERY_META_ENDPOINT = "/ticket/meta";
export const UPLOAD_TICKET_FILE_DOCUMENT_ENDPOINT =
    "/ticket/fileDocument/upload";
export const CREATE_TICKET_ENDPOINT = "/ticket/create";
export const GET_TICKET_BY_PLANT_ENDPOINT = "/ticket/plant";
export const GET_MESSAGES_BY_TICKET_ID_ENDPOINT = "/ticket/message/ticket";
export const SEND_MESSAGE_USER_ENDPOINT = "/ticket/message/user/send";
export const SEND_MESSAGE_AGENT_ENDPOINT = "/ticket/message/agent/send";
export const DOWNLOAD_TICKET_ATTACHMENTS_ENDPOINT = "/ticket/download/document";
export const GET_ALL_PROFILE_MEMBERS_ENDPOINT =
    "/consumer/profile/members/plant";
export const DELETE_USER_FROM_PLANT_ENDPOINT =
    "/consumer/profile/members/investor";
export const GET_FEEDBACK_META_ENDPOINT = "/ticket/feedback/meta";
export const CREATE_TICKET_FEEDBACK_ENDPOINT = "/ticket/feedback/create/ticket";
export const GET_ALL_TICKETS_FOR_AGENT_ENDPOINT = "/sales/ticket/all";
export const ENQUIRY_CONSUMER_ENDPOINT = "/enquiry/consumer";
