import React, { useEffect, useState } from "react";
import { GoArrowLeft } from "react-icons/go";
import { LuSendHorizontal } from "react-icons/lu";
import { SlPencil } from "react-icons/sl";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { connectionTypeToCategory } from "../../constants/InvestmentConstant";
import { ERROR_MSG } from "../../constants/common";
import { DASHBOARD, GET_PLANT_BY_ID_ENDPOINT } from "../../constants/routes";
import request from "../../services/request";
import Container from "../../shared/Container";
import Counter from "../../shared/animation/typography/Counter";
import H4 from "../../shared/typography/H4";
import P from "../../shared/typography/P";
import TextField from "../../shared/ui/Form/TextField";
import SwitchingTabs from "../../shared/ui/tab/SwitchingTabs";
import Tag from "../../shared/ui/tag/Tag";

const HeroDetails = ({
    currentTab,
    handleTabClick,
    selected,
    upfront,
    subsidyAmount,
    billWithSolar,
    solarPlantSize,
    options,
    billWithoutGST,
    connectionType,
}) => {
    const [dis, setDis] = useState(true);
    const [nameError, setNameError] = useState("");
    const { id } = useParams();
    const [propertyName, setPropertyName] = useState("");
    const history = useHistory();
    const [formData, setFormData] = useState({});
    const onTabClick = (index) => {
        handleTabClick(index);
    };
    useEffect(() => {
        const fetchData = async () => {
            const endpoint = `${GET_PLANT_BY_ID_ENDPOINT}/${id}`;
            const data = await request.authGet({ endpoint });
            setFormData(data?.data?.plant);
            setPropertyName(data?.data?.plant?.propertyName);
        };
        fetchData().catch((err) => {
            toast.error(typeof err === "string" ? err : ERROR_MSG);
        });
    }, []);

    useEffect(() => {}, [billWithoutGST, subsidyAmount]);
    const updatePropertyNameHandler = async () => {
        if (propertyName === formData.propertyName) {
            setNameError("Property name can't be same");
        } else {
            try {
                let res = await request.authPost({
                    endpoint: `${GET_PLANT_BY_ID_ENDPOINT}/${id}?plantName=${propertyName}`,
                });
                if (res?.data?.Plant?.propertyName) {
                    setFormData((prev) => ({
                        ...prev,
                        propertyName: res?.data?.Plant?.propertyName,
                    }));
                }

                setDis(false);
                setNameError("");
                toast.success(
                    typeof res?.message === "string"
                        ? res?.message
                        : "Plant name has been updated"
                );
            } catch (error) {
                toast.error(typeof error === "string" ? error : ERROR_MSG);

                setDis(false);
                setNameError("");
            }
        }
    };
    const handlePropertyName = (e) => {
        setPropertyName(e.target.value);
    };
    return (
        <Container className="relative bottom-0 top-10  w-full py-1 px-1.5 mt-5 bg-white rounded-lg border border-blue-300 min-h-56">
            <div
                className="flex items-center gap-1 mb-2 md:mb-0"
                role="button"
                onClick={() => history.push(`${DASHBOARD}`)}
            >
                <P>
                    <GoArrowLeft />
                </P>
                <P>Go Back</P>
            </div>
            <div className="flex justify-between items-start md:items-center gap-2 xl:gap-4.6">
                <div className="flex flex-col gap-1 md:flex-row  md:gap-3.2 items-center">
                    <div>
                        <H4 className="text-nowrap">Proposals details</H4>
                        <Tag
                            text={`Plant #${id}`}
                            className={"text-secondary"}
                        />
                    </div>
                    <div>
                        <div className="lg:mt-2">
                            <div>
                                <TextField
                                    label={""}
                                    value={propertyName}
                                    onChange={handlePropertyName}
                                    size={"md:min-w-[24rem]"}
                                    name="name"
                                    placeholder=""
                                    disabled={dis}
                                    errorMsg={nameError}
                                    wrapper={"md:h-[5rem]"}
                                    inputWrapper="rounded-l-md text-clip "
                                    type="text"
                                    icon={
                                        dis ? (
                                            <SlPencil />
                                        ) : (
                                            <LuSendHorizontal />
                                        )
                                    }
                                    iconWrapperClass={`py-[1.4rem] px-[1.5rem]  flex items-center rounded-r-md bg-gray-100  `}
                                    iconChange={() => {
                                        setNameError("");
                                        setDis((prev) => !prev);
                                        if (!dis) {
                                            updatePropertyNameHandler();
                                        }
                                    }}
                                />
                            </div>
                            {!dis && (
                                <div
                                    className="my-1 !border-none text-red cursor-pointer"
                                    onClick={() => {
                                        setDis((prev) => !prev);
                                        setPropertyName(formData?.propertyName);
                                    }}
                                >
                                    Cancel
                                </div>
                            )}
                        </div>
                        <div className="hidden xl:flex text-pretty text-ellipsis w-[35%] xl:w-[55%] line-clamp-2 mt-1">
                            <P> {formData.siteAddress}</P>
                        </div>
                    </div>
                </div>
                <div className="hidden xl:flex">
                    <div className=" flex flex-row justify-between flex-wrap  gap-1 sm:gap-4 lg:gap-1.8 xl:gap-2   my-3.2">
                        <div className="md:text-center ">
                            <H4 className="sm:text-5xl xl:text-4xl">
                                {solarPlantSize} kWp
                            </H4>
                            <P className="  text-nowrap text-secondary">
                                Solar Plant Size
                            </P>
                        </div>
                        <div className="  min-h-[1em] w-0.2 self-stretch bg-gray-100"></div>

                        <div className="md:text-center ">
                            <H4 className="sm:text-5xl xl:text-4xl">
                                ₹ {billWithoutGST.toLocaleString()}
                            </H4>
                            <P className="  text-nowrap text-secondary">
                                Bill (Without GST)
                            </P>
                        </div>

                        {options[currentTab] === "Loan" && (
                            <>
                                <div className=" min-h-[1em] w-0.2 self-stretch bg-gray-100"></div>
                                <div className="md:text-center">
                                    <H4 className="sm:text-5xl xl:text-4xl">
                                        ₹ {upfront}
                                    </H4>
                                    <P className=" text-nowrap text-secondary">
                                        Upfront Payment
                                    </P>
                                </div>
                            </>
                        )}
                        {(connectionType ===
                            connectionTypeToCategory.RESIDENTIAL_SOCIETY ||
                            connectionType ===
                                connectionTypeToCategory.INDIVIDUAL_HOMES) && (
                            <>
                                <div className=" min-h-[1em] w-0.2 self-stretch bg-gray-100"></div>
                                <div className="md:text-center">
                                    <H4 className="sm:text-5xl xl:text-4xl">
                                        ₹ {subsidyAmount}
                                    </H4>
                                    <P className=" text-nowrap text-secondary">
                                        Govt Subsidy
                                    </P>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div>
                    <SwitchingTabs
                        options={options}
                        currentTab={currentTab}
                        onTabClick={onTabClick}
                        border={"!rounded-[3rem] lg:rounded-[3rem]"}
                        bestlabel={false}
                    />
                </div>
            </div>
            <div className="xl:hidden flex mt-2 line-clamp-2 text-ellipsis">
                <P>{formData.siteAddress}</P>
            </div>
            <div className=" xl:hidden flex justify-center">
                <div className=" flex flex-row flex-wrap justify-between sm:justify-normal  gap-1 sm:gap-4 lg:gap-4 xl:gap-5   my-3.2">
                    <div className="md:text-center">
                        <H4>₹ {subsidyAmount}</H4>
                        <P className=" text-nowrap text-secondary">
                            Govt Subsidy
                        </P>
                    </div>
                    <div class=" min-h-[1em] w-0.2 self-stretch bg-gray-100"></div>
                    <div className="md:text-left ">
                        <H4>₹ {billWithoutGST.toLocaleString()}</H4>
                        <P className="  text-nowrap text-secondary">
                            Bill (Without GST)
                        </P>
                    </div>
                    <div class="  min-h-[1em]  w-0.2 self-stretch bg-gray-100"></div>
                    <div className="md:text-center ">
                        <H4>
                            <Counter value={solarPlantSize} /> kWp
                        </H4>
                        <P className="  text-nowrap text-secondary">
                            Solar Plant Size
                        </P>
                    </div>
                    {options[currentTab] === "Loan" && (
                        <>
                            <div className=" min-h-[1em] hidden sm:block w-0.2 self-stretch bg-gray-100"></div>
                            <div className="md:text-center">
                                <H4>₹ {upfront}</H4>
                                <P className=" text-nowrap text-secondary">
                                    Upfront Payment
                                </P>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Container>
    );
};

export default HeroDetails;
