import React, { useEffect, useMemo, useState } from "react";
import { BsArrowLeft } from "react-icons/bs";
import { FaGreaterThan, FaPlus } from "react-icons/fa";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styled from "styled-components";
import Icon from "../../../../components/Icon/Icon";
import Accordion from "../../../../components/accordion/Accordion";
import DropDown from "../../../../components/dropdown/DropDown";
import Input from "../../../../components/input/Input";
import Loading from "../../../../components/loading/Loading";
import ModalAlt from "../../../../components/modal/ModalAlt";
import Space from "../../../../components/space/Space";
import Container from "../../../../components/styles/Container.styles";
import { Flex, Section } from "../../../../components/styles/Elements.styles";
import { H4, P } from "../../../../components/styles/Typography.styles";
import { ROLE } from "../../../../constants/common";
import {
    PROPOSAL_DOCUMENT_DOWNLOAD_ENDPOINT,
    SALES_PROPOSAL_BASE_ENDPOINT,
} from "../../../../constants/routes";
import { useContextState } from "../../../../context/ContextProvider";
import { checkIsAllowed } from "../../../../helpers/helpers";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import request from "../../../../services/request";
import theme from "../../../../theme";
import ConsumerDataForm from "./Components/ConsumerDataForm";
import { fields2 } from "./GenerateProposal";
import ProposalDeatilsContainer from "./ProposalDetailsContainer";
import { ProRadioInput } from "./PropsoalRadioInput";
import {
    ButtonStyled,
    Cell,
    DetailsFiled,
    Header,
    StyleLink,
    TableContainer,
} from "./SaveProposal";
export const IconWrapper = styled.div`
    background-color: ${(props) => props.theme.color.grey500};
    display: flex;
    align-items: center;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    font-size: 2rem;
    cursor: pointer;
`;

const StyleLinkUpdated = styled(StyleLink)`
    font-size: 16px;
`;
const connectionTypeToCategory = {
    RESIDENTIAL_SOCIETY: "Residential Society",
    COMMERCIAL: "Commercial",
    INDUSTRIAL: "Industrial",
    INDIVIDUAL_HOMES: "Individual Homes",
};
function ViewProposal() {
    const { state } = useContextState();
    const [currentProposal, setCurrentProposal] = useState({});
    const [formData2, setFormData2] = useState({});
    const history = useHistory();
    const { generateId } = useParams();
    const [proposalData, setProposalData] = useState(null);
    const [isvisible, setIsVisible] = useState(false);
    const [showSummary, setShowSummary] = useState(false);
    const [checkboxes, setCheckboxes] = useState({
        Purchase: currentProposal
            ? currentProposal?.purchase !== "REJECTED"
            : true,
        Lease: currentProposal ? currentProposal?.lease !== "REJECTED" : true,
        Loan: currentProposal ? currentProposal?.loan !== "REJECTED" : true,
    });
    const [intro, setIntro] = useState("Yes");
    const [connectionType, setConnectionType] = useState("");
    const [consumerTotalUnits, setConsumerTotalUnits] = useState("");
    const [consumerBills, setConsumerBills] = useState("");
    const [duration, setDuration] = useState(0);
    useEffect(() => {
        const fetchData = async () => {
            setIsVisible(true);
            try {
                const endpoint = `${SALES_PROPOSAL_BASE_ENDPOINT}/${generateId}/current`;
                const data = await request.authGet({ endpoint });

                if (data && data.data && data.data.proposal) {
                    setCurrentProposal(data.data.proposal);
                    setProject(`Proposal_${data.data.proposal.id}`);
                    setSolarData(JSON.parse(data.data.proposal.data));
                    setCheckboxes({
                        Purchase: data.data.proposal
                            ? data.data.proposal?.purchase !== "REJECTED"
                            : true,
                        Lease: data.data.proposal
                            ? data.data.proposal?.lease !== "REJECTED"
                            : true,
                        Loan: data.data.proposal
                            ? data.data.proposal?.loan !== "REJECTED"
                            : true,
                    });
                    setConnectionType(
                        connectionTypeToCategory[data.data.proposal?.category]
                    );
                    setConsumerBills(data.data.proposal?.consumerBill);
                    setConsumerTotalUnits(
                        data.data.proposal?.consumerTotalUnits
                    );
                    setDuration(data.data.proposal?.plantDuration);
                    const foundProposal = data.data.proposal;

                    setFormData2(
                        fields2.reduce((acc, field) => {
                            switch (field.label) {
                                case "Connection Type":
                                    acc[field.label] =
                                        foundProposal &&
                                        connectionTypeToCategory[
                                            foundProposal.category
                                        ];

                                    break;
                                case "Consumer’s Bill":
                                    acc[field.label] =
                                        foundProposal?.consumerBill;
                                    break;
                                case "Consumer’s Total Unit":
                                    acc[field.label] =
                                        foundProposal?.consumerTotalUnits;
                                    break;
                                case "Plan Duration":
                                    acc[field.label] =
                                        foundProposal?.plantDuration
                                            ? `${foundProposal?.plantDuration} Years`
                                            : field.selected;
                                    break;
                                default:
                                    acc[field.label] = "";
                            }
                            return acc;
                        }, {})
                    );
                    setIntro(
                        currentProposal?.subsidy && currentProposal?.tenant
                            ? connectionType ===
                              connectionTypeToCategory.COMMERCIAL
                                ? currentProposal?.tenant
                                    ? "Yes"
                                    : "No"
                                : currentProposal?.subsidy
                                ? "Yes"
                                : "No"
                            : "Yes"
                    );
                    setActive(
                        data.data.proposal?.purchase !== "REJECTED"
                            ? "Purchase"
                            : data.data.proposal?.lease !== "REJECTED" &&
                              connectionType !== "Individual Homes" &&
                              connectionType !== "Industrial"
                            ? "Lease"
                            : "Loan"
                    );
                }
                setIsVisible(false);
            } catch (err) {
                setIsVisible(false);
                toast.error(err);
            }
        };

        fetchData();
    }, [generateId]);

    const [active, setActive] = useState(
        checkboxes.Purchase
            ? "Purchase"
            : checkboxes.Lease &&
              connectionType !== "Individual Homes" &&
              connectionType !== "Industrial"
            ? "Lease"
            : "Loan"
    );
    const [solarData, setSolarData] = useState(null);
    const [project, setProject] = useState("");
    useEffect(() => {
        const fetchData = async () => {
            try {
                const endpoint = `${SALES_PROPOSAL_BASE_ENDPOINT}/${generateId}/all`;
                const data = await request.authGet({ endpoint });
                setProposalData(data.data.proposal);
            } catch (error) {
                toast.error("Failed to fetch proposal data:", error);
            }
        };

        fetchData();
    }, [generateId]);

    const handleProposalClick = async (proposal) => {
        try {
            setIsVisible(true);
            const endpoint = `${SALES_PROPOSAL_BASE_ENDPOINT}/${generateId}/${proposal.id}`;
            const data = await request.authGet({ endpoint });
            const proposalAPI = data.data.proposal;

            setProject(`Proposal_${proposal.id}`);
            setConnectionType(connectionTypeToCategory[proposalAPI.category]);
            setCurrentProposal(data.data.proposal);
            setConsumerBills(proposalAPI?.consumerBill);
            setAverageMonthlyBill(Number(proposalAPI?.consumerBill));
            setConsumerTotalUnits(proposalAPI?.consumerTotalUnits);
            setDuration(proposalAPI?.planDuration);
            setSolarData(JSON.parse(proposalAPI?.data));
            setFormData2(
                fields2.reduce((acc, field) => {
                    switch (field.label) {
                        case "Connection Type":
                            acc[field.label] = proposalAPI
                                ? connectionTypeToCategory[proposalAPI.category]
                                : field.selected;

                            break;
                        case "Consumer’s Bill":
                            acc[field.label] = proposalAPI?.consumerBill;
                            break;
                        case "Consumer’s Total Unit":
                            acc[field.label] = proposalAPI?.consumerTotalUnits;
                            break;
                        case "Plan Duration":
                            acc[field.label] = proposalAPI?.plantDuration
                                ? `${proposalAPI?.plantDuration} Years`
                                : field.selected;
                            break;
                        default:
                            acc[field.label] = "";
                    }
                    return acc;
                }, {})
            );
            setIsVisible(false);
        } catch (error) {
            setIsVisible(false);
            toast.error("Error fetching proposal data:");
        }
    };

    const [averageMonthlyBill, setAverageMonthlyBill] = useState(
        Number(consumerBills)
    );
    const [solarPlantSize, setSolarPlantSize] = useState(0);
    const [subsidyAmount, setSubsidyAmount] = useState(0);
    const [SavedAmount, setSavedAmount] = useState(0);
    const [upfront, setUpfront] = useState(0);
    const [totalAmt, setTotalAmt] = useState(0);
    const [gst, setGST] = useState(0);
    const [leaseTable, setLeaseTable] = useState({});

    useEffect(() => {
        if (consumerTotalUnits > 0) {
            const size = consumerTotalUnits / 120;
            setSolarPlantSize(size.toFixed(2));
            calculateSubsidy(size);
            calculateTotalCost(size);
            setLeaseTable(
                calculateLeasePricingTable(size, Number(consumerBills))
            );
        }
    }, [
        consumerTotalUnits,
        active,
        connectionType,
        consumerBills,
        solarData,
        currentProposal,
    ]);

    const planDurationYears = useMemo(() => {
        const yearsString = duration;
        return yearsString + "Y";
    }, [duration]);

    const billPayableToHypersrot = useMemo(() => {
        return (
            solarPlantSize *
            (solarData?.charges.commercial.investor_rate[planDurationYears] +
                solarData?.charges.commercial.hypersrot_rate.per_KW) *
            120
        );
    }, [solarPlantSize, solarData]);

    const billWithoutGST = useMemo(() => {
        return averageMonthlyBill / 1.18;
    }, [averageMonthlyBill]);

    const Gst = useMemo(() => {
        return billPayableToHypersrot * 0.18;
    }, [billPayableToHypersrot, solarData]);

    const totalBillPayable = useMemo(() => {
        return billPayableToHypersrot + Gst;
    }, [billPayableToHypersrot, gst]);

    const monthlySavings = useMemo(() => {
        if (intro === "Yes") {
            return billWithoutGST * 0.18;
        } else {
            return averageMonthlyBill - billPayableToHypersrot;
        }
    }, [averageMonthlyBill, billPayableToHypersrot]);

    const savingsPercentage = useMemo(() => {
        return (monthlySavings / averageMonthlyBill) * 100;
    }, [monthlySavings, averageMonthlyBill]);

    // Commercial With Tenants Calculations

    const tenantGST = useMemo(() => {
        return (billWithoutGST * 0.18).toFixed(2);
    }, [billWithoutGST]);

    const savingsOnCollection = useMemo(() => {
        return billWithoutGST - billPayableToHypersrot;
    }, [billWithoutGST, billPayableToHypersrot]);

    const gstPayableToHypersrot = useMemo(() => {
        return (totalBillPayable / 1.18) * 0.18;
    }, [totalBillPayable]);

    const gstPayableToGovernment = useMemo(() => {
        return tenantGST - gstPayableToHypersrot;
    }, [tenantGST, gstPayableToHypersrot]);
    // Calculate Subsidy
    const calculateSubsidy = (size) => {
        if (connectionType === "Residential Society") {
            let subAmt =
                Number(
                    solarData?.government_subsidy["residential-society"][
                        "0-500"
                    ]
                ) * size;
            const formattedSubsidyAmount = subAmt.toLocaleString();
            setSubsidyAmount(formattedSubsidyAmount);
        } else if (connectionType === "Individual Homes") {
            let subAmt;
            const subsidyData =
                solarData?.government_subsidy["individual-homes"];
            if (subsidyData) {
                Object.keys(subsidyData).forEach((range) => {
                    const [min, max] = range.split("-").map(Number);
                    if (size >= min && size <= max) {
                        subAmt = subsidyData[range];
                    }
                });
            }

            if (subAmt) {
                const totalSubsidyAmount = subAmt;
                const formattedSubsidyAmount =
                    totalSubsidyAmount.toLocaleString(); // Format with commas
                setSubsidyAmount(formattedSubsidyAmount);
            }
        }
    };
    // Calculate Total Cost
    const calculateTotalCost = (size) => {
        let costPerKW;

        const pricingData =
            (connectionType === "Residential Society" ||
                connectionType === "Individual Homes") &&
            intro === "Yes"
                ? solarData?.pricing.loan_and_purchase.subsidy
                : solarData?.pricing.loan_and_purchase["non-subsidy"];

        // Iterate through the keys to find the appropriate range
        if (pricingData) {
            Object.keys(pricingData).forEach((range) => {
                const [min, max] = range.split("-").map(Number);
                if (size > min && size <= max) {
                    costPerKW = pricingData[range];
                }
            });
        }

        if (costPerKW) {
            const savemt = size * costPerKW;
            const formatsaveAmt = savemt.toLocaleString();
            const totalCost = savemt * 1.138;
            setSavedAmount(formatsaveAmt);
            const gstAmount = savemt * 0.138;
            const formattedGST = gstAmount.toLocaleString();
            setGST(formattedGST);
            const total = savemt + gstAmount;
            const formattedTotal = total.toLocaleString();
            setTotalAmt(formattedTotal);
            const payment = totalCost * 0.3;
            const formattedUpfront = payment.toLocaleString();
            setUpfront(formattedUpfront);
        } else {
            // toast.error(
            //     "Could not find appropriate range for the provided size"
            // );
        }
    };
    const calculateLeasePricingTable = (solarPlantSize, averageMonthlyBill) => {
        // Calculate fixed monthly rental
        const inverstorRate = Number(
            solarData?.charges["residential-society"].investor_rate["5Y"]
        );
        const hypersrotRate = Number(
            solarData?.charges["residential-society"].hypersrot_rate.per_KW
        );

        const fixedMonthlyRental =
            (inverstorRate + hypersrotRate) * 120 * solarPlantSize;

        // Calculate bank guarantee
        const bankGuarantee = fixedMonthlyRental * 4;

        // Calculate savings on existing bills percentage
        const savingsOnExistingBillsPercentage =
            ((averageMonthlyBill - fixedMonthlyRental) / averageMonthlyBill) *
            100;
        // Calculate government subsidy
        const governmentSubsidy =
            Number(
                solarData?.government_subsidy["residential-society"]["0-500"]
            ) * solarPlantSize;

        // Calculate effective savings per month
        const planDuration = duration;
        let pricingYearWithPlanDuration = duration * 12;

        const effectiveSavingsPerMonth =
            ((averageMonthlyBill -
                fixedMonthlyRental +
                governmentSubsidy / pricingYearWithPlanDuration) /
                averageMonthlyBill) *
            100;

        // Return the calculated values
        return {
            fixedMonthlyRental: fixedMonthlyRental.toLocaleString(),
            bankGuarantee: bankGuarantee.toLocaleString(),
            savingsOnExistingBillsPercentage:
                savingsOnExistingBillsPercentage.toFixed(2),
            governmentSubsidy: governmentSubsidy.toLocaleString(),
            effectiveSavingsPerMonth: effectiveSavingsPerMonth.toFixed(2),
        };
    };
    const [formData, setFormData] = useState({
        revisionType: "Purchase", // default to 'Purchase'
        interested: "Yes", // default to 'Yes'
        category: "Other", // default to 'Other'
        subject: "Need better discount on quotation",
        document: null, // no document initially
        feedback:
            "Got better proposal from waree and tata power, proposal attached for your reference",
    });

    // Function to handle changes in form inputs
    const handleChange = (name, value) => {
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const [isLoading, setIsLoading] = useState(false);
    const downloadDocument = async () => {
        setIsLoading(true);
        const endpoint = `${PROPOSAL_DOCUMENT_DOWNLOAD_ENDPOINT}/${active.toLowerCase()}/${
            proposalData[0].plantId
        }/${project.split("_")[1]}`;

        const data = await request.authGet({
            endpoint,
            headers: {},
            checkStatusCode: true,
        });

        const blob = await data.blob();
        let filename = data.headers.get("x-custom-filename")
            ? data.headers.get("x-custom-filename")
            : `solar_${active}_document.pdf`;

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setIsLoading(false);
    };
    const breakpoint = parseInt(
        theme.breakPoints.smallTablet.replace("px", ""),
        10
    );
    const windowWidth = useWindowDimensions().width;
    const isSmallTablet = windowWidth < breakpoint;
    const [showAll, setShowAll] = useState(false);
    const [displayCount, setDisplayCount] = useState(5);

    const handleShowMore = () => {
        setShowAll(true);
        setDisplayCount(proposalData?.length || 0);
    };

    return (
        <Section height="auto">
            {isvisible ? (
                <Flex.Container
                    style={{ height: "100vh" }}
                    align="center"
                    justify="center"
                >
                    <P xxs="xml" st="xml" color="primary">
                        <Loading />
                    </P>
                </Flex.Container>
            ) : (
                <Container>
                    <Space xxsm={["30px 0 30px 0 "]} xlm={["50px 0 30px 0"]}>
                        <Flex.Container
                            onClick={() =>
                                history.push(`/${generateId}/generate`)
                            }
                            align="center"
                            style={{ cursor: "pointer" }}
                        >
                            <Space xxsm={["0 1rem 0 0"]} stm={["0 2rem 0 0"]}>
                                <Flex.Item>
                                    <Icon component={BsArrowLeft} lg="l" />
                                </Flex.Item>
                            </Space>
                            <Flex.Item>
                                <P>Back</P>
                            </Flex.Item>
                        </Flex.Container>
                    </Space>
                    {isSmallTablet ? (
                        <Accordion
                            size={["l", "l", "l"]}
                            weight="rg"
                            isOpen={false}
                            label={`Consumer Data`}
                        >
                            <ConsumerDataForm
                                formData2={formData2}
                                intro={intro}
                                checkboxes={checkboxes}
                            />
                        </Accordion>
                    ) : (
                        <>
                            <Space xxsm={["10px 0 30px"]}>
                                <H4>Consumer Data</H4>
                            </Space>
                            <ConsumerDataForm
                                formData2={formData2}
                                intro={intro}
                                checkboxes={checkboxes}
                            />
                        </>
                    )}

                    <Space xxsm={["10px 0 30px"]}>
                        <Flex.Container align="center" justify="space-between">
                            <Flex.Container align="baseline">
                                <H4>Proposal Summary :</H4>
                                <Space xxsm={[" 0 10px"]}>
                                    <P xxs="xs" st="s" lg="m">
                                        {project}
                                    </P>
                                </Space>
                            </Flex.Container>
                            <Flex.Item>
                                <Flex.Container justify="end">
                                    {proposalData
                                        ?.sort((a, b) => b.id - a.id)
                                        .slice(
                                            0,
                                            showAll
                                                ? proposalData.length
                                                : displayCount
                                        )
                                        .map(
                                            (
                                                proposal,
                                                index,
                                                sortedProposals
                                            ) => (
                                                <React.Fragment
                                                    key={proposal?.id}
                                                >
                                                    <Flex.Item>
                                                        <StyleLinkUpdated
                                                            onClick={() =>
                                                                handleProposalClick(
                                                                    proposal
                                                                )
                                                            }
                                                        >
                                                            {`${proposal?.id}`}
                                                        </StyleLinkUpdated>
                                                    </Flex.Item>
                                                    {index <
                                                        proposalData.length -
                                                            1 && (
                                                        <React.Fragment>
                                                            <Space
                                                                xxsm={[
                                                                    " 0 10px",
                                                                ]}
                                                            >
                                                                <Flex.Item>
                                                                    {
                                                                        <FaGreaterThan
                                                                            style={{
                                                                                marginTop:
                                                                                    "7px",
                                                                            }}
                                                                        />
                                                                    }
                                                                </Flex.Item>
                                                            </Space>
                                                        </React.Fragment>
                                                    )}
                                                </React.Fragment>
                                            )
                                        )}
                                    {/* Show more button */}
                                    {!showAll && proposalData?.length > 5 && (
                                        <button onClick={handleShowMore}>
                                            <P
                                                xxs="xs"
                                                st="s"
                                                lg="m"
                                                color="blue"
                                            >
                                                Show more...
                                            </P>
                                        </button>
                                    )}
                                </Flex.Container>
                            </Flex.Item>
                        </Flex.Container>
                    </Space>

                    <ProposalDeatilsContainer>
                        <Flex.Container>
                            {checkboxes.Purchase && (
                                <Space xxsm={["0 2rem 0 0"]}>
                                    <Flex.Item>
                                        <ButtonStyled
                                            isActive={active === "Purchase"}
                                            onClick={() =>
                                                setActive("Purchase")
                                            }
                                        >
                                            Purchase
                                        </ButtonStyled>
                                    </Flex.Item>
                                </Space>
                            )}
                            {connectionType !== "Individual Homes" &&
                                connectionType !== "Industrial" &&
                                checkboxes.Lease && (
                                    <Space xxsm={["0 2rem 0 0"]}>
                                        <Flex.Item>
                                            <ButtonStyled
                                                isActive={active === "Lease"}
                                                onClick={() =>
                                                    setActive("Lease")
                                                }
                                            >
                                                Lease
                                            </ButtonStyled>
                                        </Flex.Item>
                                    </Space>
                                )}
                            {checkboxes.Loan && (
                                <Space xxsm={["0 2rem 0 0"]}>
                                    <Flex.Item>
                                        <ButtonStyled
                                            isActive={active === "Loan"}
                                            onClick={() => setActive("Loan")}
                                        >
                                            Loan
                                        </ButtonStyled>
                                    </Flex.Item>
                                </Space>
                            )}
                        </Flex.Container>
                        {connectionType === "Commercial" &&
                            intro === "Yes" &&
                            active === "Lease" && (
                                <P weight={"m"}>Tenant Savings</P>
                            )}
                        <ProposalDeatilsContainer
                            backgroundColor={"whitesmoke"}
                        >
                            <Flex.Container
                                justify="space-between"
                                align="start"
                            >
                                {connectionType === "Commercial" &&
                                intro === "Yes" &&
                                active === "Lease" ? (
                                    <Flex.Item xl="25%" t="100%" xxs="100%">
                                        <DetailsFiled
                                            label={"Bill (Without GST)"}
                                            value={billWithoutGST.toLocaleString()}
                                            disabled={true}
                                            direction={"column"}
                                        />
                                    </Flex.Item>
                                ) : (
                                    <Flex.Item xl="25%" t="100%" xxs="100%">
                                        <DetailsFiled
                                            label={"Solar Planting"}
                                            value={`${solarPlantSize} KWp`}
                                            disabled={true}
                                            direction={"column"}
                                        />
                                    </Flex.Item>
                                )}
                                {active === "Lease" &&
                                    connectionType === "Commercial" &&
                                    intro === "Yes" && (
                                        <Flex.Item xl="25%" t="100%" xxs="100%">
                                            <DetailsFiled
                                                label={"GST (18%)"}
                                                value={`${
                                                    intro === "Yes"
                                                        ? !isNaN(tenantGST)
                                                            ? tenantGST.toLocaleString()
                                                            : tenantGST
                                                        : !isNaN(Gst)
                                                        ? Gst.toLocaleString()
                                                        : Gst
                                                }`}
                                                disabled={true}
                                                direction={"column"}
                                            />
                                        </Flex.Item>
                                    )}
                                {active === "Lease" &&
                                connectionType === "Commercial" &&
                                intro === "Yes" ? (
                                    <Flex.Item xl="25%" t="100%" xxs="100%">
                                        <DetailsFiled
                                            label={"Total Bill Payable"}
                                            value={`${
                                                intro === "Yes"
                                                    ? !isNaN(averageMonthlyBill)
                                                        ? averageMonthlyBill.toLocaleString()
                                                        : averageMonthlyBill
                                                    : !isNaN(totalBillPayable)
                                                    ? totalBillPayable.toLocaleString()
                                                    : totalBillPayable
                                            }`}
                                            disabled={true}
                                            direction={"column"}
                                        />
                                    </Flex.Item>
                                ) : (
                                    active === "Lease" && (
                                        <Flex.Item xl="25%" t="100%" xxs="100%">
                                            <DetailsFiled
                                                label={"Units Consumed"}
                                                value={`${Number(
                                                    consumerTotalUnits
                                                ).toLocaleString()}
                                                    `}
                                                disabled={true}
                                                direction={"column"}
                                            />
                                        </Flex.Item>
                                    )
                                )}
                                {active === "Lease" &&
                                connectionType === "Commercial" &&
                                intro === "Yes" ? (
                                    <Flex.Item xl="25%" t="100%" xxs="100%">
                                        <DetailsFiled
                                            label={"Monthly Savings"}
                                            value={`${monthlySavings.toLocaleString()}`}
                                            disabled={true}
                                            direction={"column"}
                                        />
                                    </Flex.Item>
                                ) : (
                                    active === "Lease" && (
                                        <Flex.Item xl="25%" t="100%" xxs="100%">
                                            <DetailsFiled
                                                label={"Average Monthly Bill"}
                                                value={`${Number(
                                                    consumerBills
                                                ).toLocaleString()}`}
                                                disabled={true}
                                                direction={"column"}
                                            />
                                        </Flex.Item>
                                    )
                                )}
                                {(connectionType === "Residential Society" ||
                                    connectionType === "Individual Homes") && (
                                    <Flex.Item xl="25%" t="100%" xxs="100%">
                                        <DetailsFiled
                                            label={"Subsidy Amount"}
                                            value={`${subsidyAmount}`}
                                            disabled={true}
                                            direction={"column"}
                                        />
                                    </Flex.Item>
                                )}
                                {(connectionType === "Commercial" ||
                                    connectionType === "Industrial") &&
                                    active !== "Lease" && (
                                        <Flex.Item xl="25%" t="100%" xxs="100%">
                                            <DetailsFiled
                                                label={"GST"}
                                                value={gst.toLocaleString()}
                                                disabled={true}
                                                direction={"column"}
                                            />
                                        </Flex.Item>
                                    )}
                                {connectionType === "Commercial" &&
                                    active === "Lease" && (
                                        <Flex.Item xl="25%" t="100%" xxs="100%">
                                            <DetailsFiled
                                                label={"Savings Percentage"}
                                                value={`${savingsPercentage.toFixed(
                                                    2
                                                )}%`}
                                                disabled={true}
                                                direction={"column"}
                                            />
                                        </Flex.Item>
                                    )}
                                {active === "Loan" && (
                                    <Flex.Item xl="35%" t="100%" xxs="100%">
                                        <DetailsFiled
                                            label={"Upfront Payment (30%)"}
                                            value={upfront}
                                            disabled={true}
                                            direction={"column"}
                                        />
                                    </Flex.Item>
                                )}
                            </Flex.Container>
                        </ProposalDeatilsContainer>
                        {connectionType === "Commercial" &&
                            intro === "Yes" &&
                            active === "Lease" && (
                                <P weight={"m"}>Owner Savings</P>
                            )}
                        {connectionType === "Commercial" &&
                        intro === "Yes" &&
                        active === "Lease" ? (
                            <ProposalDeatilsContainer
                                backgroundColor={"whitesmoke"}
                            >
                                <Flex.Container
                                    justify="space-between"
                                    align="start"
                                >
                                    <Flex.Item xl="30%" t="100%" xxs="100%">
                                        <DetailsFiled
                                            label={"Bill Collected From Tenant"}
                                            value={billWithoutGST.toLocaleString()}
                                            disabled={true}
                                            direction={"column"}
                                        />
                                    </Flex.Item>

                                    <Flex.Item xl="30%" t="100%" xxs="100%">
                                        <DetailsFiled
                                            label={"Bill Payable to Hypersrot"}
                                            value={`${billPayableToHypersrot.toLocaleString()}
                                            `}
                                            disabled={true}
                                            direction={"column"}
                                        />
                                    </Flex.Item>

                                    <Flex.Item xl="30%" t="100%" xxs="100%">
                                        <DetailsFiled
                                            label={"Savings on Collection"}
                                            value={`${savingsOnCollection.toLocaleString()}`}
                                            disabled={true}
                                            direction={"column"}
                                        />
                                    </Flex.Item>

                                    <Flex.Item xl="30%" t="100%" xxs="100%">
                                        <DetailsFiled
                                            label={"GST Collected From Tenant"}
                                            value={`${tenantGST.toLocaleString()}`}
                                            disabled={true}
                                            direction={"column"}
                                        />
                                    </Flex.Item>

                                    <Flex.Item xl="30%" t="100%" xxs="100%">
                                        <DetailsFiled
                                            label={"GST Payable to Hypersrot"}
                                            value={gstPayableToHypersrot.toLocaleString()}
                                            disabled={true}
                                            direction={"column"}
                                        />
                                    </Flex.Item>

                                    <Flex.Item xl="30%" t="100%" xxs="100%">
                                        <DetailsFiled
                                            label={"GST Payable to Government"}
                                            value={gstPayableToGovernment.toLocaleString()}
                                            disabled={true}
                                            direction={"column"}
                                        />
                                    </Flex.Item>
                                </Flex.Container>
                            </ProposalDeatilsContainer>
                        ) : (
                            <TableContainer borderColor={"black"}>
                                <thead>
                                    <tr>
                                        <Header borderColor="black">
                                            <P
                                                xxs="xs"
                                                st="s"
                                                lg="m"
                                                color="white"
                                                size="s"
                                            >
                                                Description
                                            </P>
                                        </Header>
                                        <Header borderColor="black">
                                            {" "}
                                            <P
                                                xxs="xs"
                                                st="s"
                                                lg="m"
                                                color="white"
                                                size="s"
                                            >
                                                Amount
                                            </P>
                                        </Header>
                                    </tr>
                                </thead>
                                {active !== "Lease" ? (
                                    <tbody>
                                        <tr>
                                            <Cell>
                                                <Flex.Container
                                                    justify="space-between"
                                                    align="baseline"
                                                >
                                                    <Flex.Item>
                                                        <P
                                                            xxs="xs"
                                                            st="s"
                                                            lg="m"
                                                        >
                                                            Solar Plant Size:{" "}
                                                            {solarPlantSize} KWp
                                                        </P>
                                                    </Flex.Item>
                                                    <Flex.Item>
                                                        <P xxs="xs" st="s">
                                                            <StyleLink href="">
                                                                Specification
                                                            </StyleLink>
                                                        </P>
                                                    </Flex.Item>
                                                </Flex.Container>
                                            </Cell>
                                            <Cell align="end">
                                                <P xxs="xs" st="s" lg="m">
                                                    {SavedAmount}
                                                </P>
                                            </Cell>
                                        </tr>
                                        <tr>
                                            <Cell align="start">
                                                <P xxs="xs" st="s" lg="m">
                                                    GST @ 13.8%
                                                </P>
                                            </Cell>
                                            <Cell align="end">
                                                <P xxs="xs" st="s" lg="m">
                                                    {gst}
                                                </P>
                                            </Cell>
                                        </tr>
                                        <tr>
                                            <Cell align="start">
                                                <P xxs="xs" st="s" lg="m">
                                                    Total Amount
                                                </P>
                                            </Cell>
                                            <Cell align="end">
                                                <P xxs="xs" st="s" lg="m">
                                                    {totalAmt}
                                                </P>
                                            </Cell>
                                        </tr>
                                    </tbody>
                                ) : connectionType !== "Commercial" ? (
                                    <tbody>
                                        <tr>
                                            <Cell align="start">
                                                <P xxs="xs" st="s" lg="m">
                                                    Fixed Monthly Rental
                                                </P>
                                            </Cell>
                                            <Cell align="end">
                                                <P xxs="xs" st="s" lg="m">
                                                    {
                                                        leaseTable.fixedMonthlyRental
                                                    }
                                                </P>
                                            </Cell>
                                        </tr>
                                        <tr>
                                            <Cell align="start">
                                                <P xxs="xs" st="s" lg="m">
                                                    Bank Guarantee
                                                </P>
                                            </Cell>
                                            <Cell align="end">
                                                <P xxs="xs" st="s" lg="m">
                                                    {leaseTable.bankGuarantee}
                                                </P>
                                            </Cell>
                                        </tr>
                                        <tr>
                                            <Cell align="start">
                                                <P xxs="xs" st="s" lg="m">
                                                    Savings on Existing Bills
                                                </P>
                                            </Cell>
                                            <Cell align="end">
                                                <P xxs="xs" st="s" lg="m">
                                                    {
                                                        leaseTable.savingsOnExistingBillsPercentage
                                                    }
                                                    %
                                                </P>
                                            </Cell>
                                        </tr>
                                        <tr>
                                            <Cell align="start">
                                                <P xxs="xs" st="s" lg="m">
                                                    Government Subsidy
                                                </P>
                                            </Cell>
                                            <Cell align="end">
                                                <P xxs="xs" st="s" lg="m">
                                                    {
                                                        leaseTable.governmentSubsidy
                                                    }
                                                </P>
                                            </Cell>
                                        </tr>
                                        <tr>
                                            <Cell align="start">
                                                <P xxs="xs" st="s" lg="m">
                                                    Effective Savings Per Month
                                                </P>
                                            </Cell>
                                            <Cell align="end">
                                                <P xxs="xs" st="s" lg="m">
                                                    {
                                                        leaseTable.effectiveSavingsPerMonth
                                                    }
                                                    %
                                                </P>
                                            </Cell>
                                        </tr>
                                    </tbody>
                                ) : (
                                    <tbody>
                                        <tr>
                                            <Cell align="start">
                                                <P xxs="xs" st="s" lg="m">
                                                    Bill Payable to Hypersrot
                                                </P>
                                            </Cell>
                                            <Cell align="end">
                                                <P xxs="xs" st="s" lg="m">
                                                    {billPayableToHypersrot.toLocaleString()}
                                                </P>
                                            </Cell>
                                        </tr>
                                        <tr>
                                            <Cell align="start">
                                                <P xxs="xs" st="s" lg="m">
                                                    GST (18%)
                                                </P>
                                            </Cell>
                                            <Cell align="end">
                                                <P xxs="xs" st="s" lg="m">
                                                    {Gst}
                                                </P>
                                            </Cell>
                                        </tr>
                                        <tr>
                                            <Cell align="start">
                                                <P xxs="xs" st="s" lg="m">
                                                    Total Bill Payable
                                                </P>
                                            </Cell>
                                            <Cell align="end">
                                                <P xxs="xs" st="s" lg="m">
                                                    {totalBillPayable.toLocaleString()}
                                                </P>
                                            </Cell>
                                        </tr>
                                        <tr>
                                            <Cell align="start">
                                                <P xxs="xs" st="s" lg="m">
                                                    Bill Without Solar
                                                </P>
                                            </Cell>
                                            <Cell align="end">
                                                <P xxs="xs" st="s" lg="m">
                                                    {Number(
                                                        consumerBills
                                                    ).toLocaleString()}
                                                </P>
                                            </Cell>
                                        </tr>
                                        <tr>
                                            <Cell align="start">
                                                <P xxs="xs" st="s" lg="m">
                                                    Monthly Savings
                                                </P>
                                            </Cell>
                                            <Cell align="end">
                                                <P xxs="xs" st="s" lg="m">
                                                    {monthlySavings.toLocaleString()}
                                                </P>
                                            </Cell>
                                        </tr>
                                    </tbody>
                                )}
                            </TableContainer>
                        )}
                        {connectionType === "Commercial" &&
                            intro === "Yes" &&
                            active === "Lease" && (
                                <ProposalDeatilsContainer
                                    backgroundColor={"primary"}
                                >
                                    <Flex.Container
                                        justify="center"
                                        align="center"
                                    >
                                        <P color="white">
                                            Owner Earning with Tenants using
                                            Solar:{" "}
                                            {(
                                                savingsOnCollection.toFixed(0) -
                                                gstPayableToGovernment.toFixed(
                                                    0
                                                )
                                            ).toLocaleString()}
                                        </P>
                                    </Flex.Container>
                                </ProposalDeatilsContainer>
                            )}
                        <Space xxsm={[" 2rem 0"]}>
                            <Flex.Container
                                justify="space-between"
                                align="center"
                            >
                                <Space xxsm={["0 2rem 0 0"]}>
                                    <ButtonStyled
                                        isActive={true}
                                        onClick={downloadDocument}
                                        isLoading={isLoading}
                                        disabled={isLoading}
                                    >
                                        Download Proposals
                                    </ButtonStyled>
                                </Space>

                                {checkIsAllowed(state?.user?.roles, [
                                    ROLE.SUPER_ADMIN,
                                    ROLE.SALES_MANAGER,
                                ]) && (
                                    <ButtonStyled
                                        onClick={() =>
                                            history.push(
                                                `/${generateId}/editProposal`
                                            )
                                        }
                                    >
                                        Edit Proposal
                                    </ButtonStyled>
                                )}
                            </Flex.Container>
                        </Space>
                    </ProposalDeatilsContainer>
                </Container>
            )}
            {proposalData && proposalData?.length === 1 ? (
                <ModalAlt
                    times={false}
                    onClose={() => setShowSummary(false)}
                    isOpen={showSummary}
                    backgroundColor="limeYellow"
                    width={["90%", "900px", "600"]}
                >
                    <Container>
                        <H4>Revise Proposal Form</H4>
                        <Space xxsm={[" 1rem 0 2rem"]}>
                            <hr />
                        </Space>
                        <Flex.Container justify="space-between" align="center">
                            <Space xxsm={["10px 0 0"]} stm={["10px 0"]}>
                                <Flex.Item xl="30%" st="100%" xxs="100%">
                                    <P>RevisionType</P>
                                </Flex.Item>
                            </Space>

                            <Flex.Item>
                                <Flex.Container
                                    justify="space-between"
                                    align="center"
                                >
                                    <Space stm={[" 0 2rem 0 0 "]}>
                                        <Flex.Item>
                                            <ButtonStyled
                                                isActive={
                                                    formData.revisionType ===
                                                    "Purchase"
                                                }
                                                onClick={() =>
                                                    handleChange(
                                                        "revisionType",
                                                        "Purchase"
                                                    )
                                                }
                                            >
                                                Purchase
                                            </ButtonStyled>
                                        </Flex.Item>
                                    </Space>
                                    {connectionType !== "Industrial" &&
                                        connectionType !==
                                            "Individual Homes" && (
                                            <Space stm={[" 0 2rem"]}>
                                                <Flex.Item>
                                                    <ButtonStyled
                                                        isActive={
                                                            formData.revisionType ===
                                                            "Lease"
                                                        }
                                                        onClick={() =>
                                                            handleChange(
                                                                "revisionType",
                                                                "Lease"
                                                            )
                                                        }
                                                    >
                                                        Lease
                                                    </ButtonStyled>
                                                </Flex.Item>
                                            </Space>
                                        )}
                                    <Space stm={[" 0 2rem"]}>
                                        <Flex.Item>
                                            <ButtonStyled
                                                isActive={
                                                    formData.revisionType ===
                                                    "Loan"
                                                }
                                                onClick={() =>
                                                    handleChange(
                                                        "revisionType",
                                                        "Loan"
                                                    )
                                                }
                                            >
                                                Loan
                                            </ButtonStyled>
                                        </Flex.Item>
                                    </Space>
                                </Flex.Container>
                            </Flex.Item>
                        </Flex.Container>
                        <ProRadioInput
                            onChange={(value) =>
                                handleChange("interested", value)
                            }
                            type={"interested"}
                            title={"Interested"}
                            selected={formData.interested}
                            data={["Yes", "No"]}
                            direction={"row"}
                            align={"center"}
                        />
                        <Space xxsm={["10px 0"]}>
                            <Flex.Item>
                                <Space
                                    margin={["0 0 30px"]}
                                    tm={["0 0 25px"]}
                                    xsm={["0 0 10px"]}
                                >
                                    <Flex.Container justify="space-between">
                                        <Space
                                            xxsm={["10px 0 10px"]}
                                            stm={["10px 0 5px"]}
                                        >
                                            <Flex.Item
                                                xl="40%"
                                                st="60%"
                                                s="100%"
                                                xxs="100%"
                                            >
                                                <P>Category</P>
                                            </Flex.Item>
                                        </Space>
                                        <Flex.Item s="100%" xxs="100%" xl="55%">
                                            <DropDown
                                                data={[
                                                    "Better Quotation",
                                                    "Plant Capacity",
                                                    "Other",
                                                ]}
                                                onChange={(value) =>
                                                    handleChange(
                                                        "category",
                                                        value
                                                    )
                                                }
                                                selected={formData.category}
                                            />
                                        </Flex.Item>
                                    </Flex.Container>
                                </Space>
                            </Flex.Item>
                        </Space>
                        <Space xxsm={["10px 0"]}>
                            <Flex.Item>
                                <Space
                                    margin={["0 0 30px"]}
                                    tm={["0 0 25px"]}
                                    xsm={["0 0 10px"]}
                                >
                                    <Flex.Container justify="space-between">
                                        <Space
                                            xxsm={["10px 0 10px"]}
                                            stm={["10px 0 5px"]}
                                        >
                                            <Flex.Item
                                                xl="40%"
                                                st="60%"
                                                s="100%"
                                                xxs="100%"
                                            >
                                                <P>Subject</P>
                                            </Flex.Item>
                                        </Space>
                                        <Flex.Item s="100%" xxs="100%" xl="55%">
                                            <Input
                                                backgroundColor="white"
                                                shadow={false}
                                                style={{
                                                    paddingBottom: "7px",
                                                    paddingTop: "7px",
                                                }}
                                                value={formData.subject}
                                                onChange={(e) =>
                                                    handleChange(
                                                        "subject",
                                                        e.target.value
                                                    )
                                                }
                                            />
                                        </Flex.Item>
                                    </Flex.Container>
                                </Space>
                            </Flex.Item>
                        </Space>
                        <Space xxsm={["10px 0"]}>
                            <Flex.Item>
                                <Space
                                    margin={["0 0 30px"]}
                                    tm={["0 0 25px"]}
                                    xsm={["0 0 10px"]}
                                >
                                    <Flex.Container justify="space-between">
                                        <Space
                                            xxsm={["10px 0 10px"]}
                                            stm={["10px 0 5px"]}
                                        >
                                            <Flex.Item
                                                xl="40%"
                                                st="60%"
                                                s="100%"
                                                xxs="100%"
                                            >
                                                <P>Upload Document</P>
                                            </Flex.Item>
                                        </Space>
                                        <Flex.Item s="100%" xxs="100%" xl="55%">
                                            <IconWrapper>
                                                <Icon
                                                    component={FaPlus}
                                                    lg="m"
                                                />
                                            </IconWrapper>
                                        </Flex.Item>
                                    </Flex.Container>
                                </Space>
                            </Flex.Item>
                        </Space>
                        <Space xxsm={["10px 0"]}>
                            <Flex.Item>
                                <Space
                                    margin={["0 0 30px"]}
                                    tm={["0 0 25px"]}
                                    xsm={["0 0 10px"]}
                                >
                                    <Flex.Container justify="space-between">
                                        <Space
                                            xxsm={["10px 0 10px"]}
                                            stm={["10px 0 5px"]}
                                        >
                                            <Flex.Item
                                                xl="40%"
                                                st="60%"
                                                s="100%"
                                                xxs="100%"
                                            >
                                                <P>Feedback</P>
                                            </Flex.Item>
                                        </Space>
                                        <Flex.Item s="100%" xxs="100%" xl="55%">
                                            <Input
                                                backgroundColor="grey500"
                                                shadow={false}
                                                style={{
                                                    paddingBottom: "7px",
                                                    paddingTop: "7px",
                                                }}
                                                rows="3"
                                                value={formData.feedback}
                                                onChange={(e) =>
                                                    handleChange(
                                                        "feedback",
                                                        e.target.value
                                                    )
                                                }
                                                componentType="textarea"
                                            />
                                        </Flex.Item>
                                    </Flex.Container>
                                </Space>
                            </Flex.Item>
                        </Space>
                        <Flex.Container justify="center" align="stretch">
                            <Flex.Item>
                                <ButtonStyled isActive={true}>
                                    Submit
                                </ButtonStyled>
                            </Flex.Item>
                        </Flex.Container>
                    </Container>
                </ModalAlt>
            ) : (
                <ModalAlt
                    times={false}
                    onClose={() => setShowSummary(false)}
                    isOpen={showSummary}
                    backgroundColor="limeYellow"
                    width={["90%", "900px", "600"]}
                >
                    <Container>
                        <H4>Revision Summary</H4>
                        <Space xxsm={[" 1rem 0 2rem"]}>
                            <hr />
                        </Space>
                        <Flex.Container justify="space-between" align="center">
                            <Space xxsm={["10px 0 0"]} stm={["10px 0"]}>
                                <Flex.Item xl="30%" st="100%" xxs="100%">
                                    <P>RevisionType</P>
                                </Flex.Item>
                            </Space>

                            <Flex.Item>
                                <Flex.Container
                                    justify="space-between"
                                    align="center"
                                >
                                    <Space stm={[" 0 2rem 0 0 "]}>
                                        <Flex.Item>
                                            <ButtonStyled
                                                isActive={active === "Purchase"}
                                            >
                                                Purchase
                                            </ButtonStyled>
                                        </Flex.Item>
                                    </Space>
                                    {connectionType !== "Industrial" &&
                                        connectionType !==
                                            "Individual Homes" && (
                                            <Space stm={[" 0 2rem"]}>
                                                <Flex.Item>
                                                    <ButtonStyled
                                                        isActive={
                                                            active === "Lease"
                                                        }
                                                    >
                                                        Lease
                                                    </ButtonStyled>
                                                </Flex.Item>
                                            </Space>
                                        )}
                                    <Space stm={[" 0 2rem"]}>
                                        <Flex.Item>
                                            <ButtonStyled
                                                isActive={active === "Loan"}
                                            >
                                                Loan
                                            </ButtonStyled>
                                        </Flex.Item>
                                    </Space>
                                </Flex.Container>
                            </Flex.Item>
                        </Flex.Container>
                        <ProRadioInput
                            type={"interested"}
                            title={"Interested"}
                            selected={"Yes"}
                            data={["Yes", "No"]}
                            direction={"row"}
                            align={"center"}
                            disable={true}
                        />
                        <Space xxsm={["10px 0"]}>
                            <Flex.Item>
                                <Space
                                    margin={["0 0 30px"]}
                                    tm={["0 0 25px"]}
                                    xsm={["0 0 10px"]}
                                >
                                    <Flex.Container justify="space-between">
                                        <Space
                                            xxsm={["10px 0 10px"]}
                                            stm={["10px 0 5px"]}
                                        >
                                            <Flex.Item
                                                xl="40%"
                                                st="60%"
                                                s="100%"
                                                xxs="100%"
                                            >
                                                <P>Category</P>
                                            </Flex.Item>
                                        </Space>
                                        <Flex.Item s="100%" xxs="100%" xl="55%">
                                            <DropDown
                                                data={["1", "2", "Other"]}
                                                onChange={null}
                                                disabled="true"
                                                selected={"Other"}
                                            />
                                        </Flex.Item>
                                    </Flex.Container>
                                </Space>
                            </Flex.Item>
                        </Space>
                        <Space xxsm={["10px 0"]}>
                            <Flex.Item>
                                <Space
                                    margin={["0 0 30px"]}
                                    tm={["0 0 25px"]}
                                    xsm={["0 0 10px"]}
                                >
                                    <Flex.Container justify="space-between">
                                        <Space
                                            xxsm={["10px 0 10px"]}
                                            stm={["10px 0 5px"]}
                                        >
                                            <Flex.Item
                                                xl="40%"
                                                st="60%"
                                                s="100%"
                                                xxs="100%"
                                            >
                                                <P>Subject</P>
                                            </Flex.Item>
                                        </Space>
                                        <Flex.Item s="100%" xxs="100%" xl="55%">
                                            <Input
                                                backgroundColor="grey500"
                                                shadow={false}
                                                style={{
                                                    paddingBottom: "7px",
                                                    paddingTop: "7px",
                                                }}
                                                disabled="true"
                                                value={
                                                    "Need better discount on quotation"
                                                }
                                            />
                                        </Flex.Item>
                                    </Flex.Container>
                                </Space>
                            </Flex.Item>
                        </Space>
                        <Space xxsm={["10px 0"]}>
                            <Flex.Item>
                                <Space
                                    margin={["0 0 30px"]}
                                    tm={["0 0 25px"]}
                                    xsm={["0 0 10px"]}
                                >
                                    <Flex.Container justify="space-between">
                                        <Space
                                            xxsm={["10px 0 10px"]}
                                            stm={["10px 0 5px"]}
                                        >
                                            <Flex.Item
                                                xl="40%"
                                                st="60%"
                                                s="100%"
                                                xxs="100%"
                                            >
                                                <P>Upload Document</P>
                                            </Flex.Item>
                                        </Space>
                                        <Flex.Item s="100%" xxs="100%" xl="55%">
                                            <IconWrapper>
                                                <Icon
                                                    component={FaPlus}
                                                    lg="m"
                                                />
                                            </IconWrapper>
                                        </Flex.Item>
                                    </Flex.Container>
                                </Space>
                            </Flex.Item>
                        </Space>
                        <Space xxsm={["10px 0"]}>
                            <Flex.Item>
                                <Space
                                    margin={["0 0 30px"]}
                                    tm={["0 0 25px"]}
                                    xsm={["0 0 10px"]}
                                >
                                    <Flex.Container justify="space-between">
                                        <Space
                                            xxsm={["10px 0 10px"]}
                                            stm={["10px 0 5px"]}
                                        >
                                            <Flex.Item
                                                xl="40%"
                                                st="60%"
                                                s="100%"
                                                xxs="100%"
                                            >
                                                <P>Feedback</P>
                                            </Flex.Item>
                                        </Space>
                                        <Flex.Item s="100%" xxs="100%" xl="55%">
                                            <Input
                                                backgroundColor="grey500"
                                                shadow={false}
                                                style={{
                                                    paddingBottom: "7px",
                                                    paddingTop: "7px",
                                                }}
                                                rows="3"
                                                disabled="true"
                                                value={
                                                    "Got better proposal from waree and tata power, proposal attached for your refrence"
                                                }
                                                componentType="textarea"
                                            />
                                        </Flex.Item>
                                    </Flex.Container>
                                </Space>
                            </Flex.Item>
                        </Space>
                        <Space xxsm={["10px 0"]}>
                            <Flex.Item>
                                <Space
                                    margin={["0 0 30px"]}
                                    tm={["0 0 25px"]}
                                    xsm={["0 0 10px"]}
                                >
                                    <Flex.Container justify="space-between">
                                        <Space
                                            xxsm={["10px 0 10px"]}
                                            stm={["10px 0 5px"]}
                                        >
                                            <Flex.Item
                                                xl="40%"
                                                st="60%"
                                                s="100%"
                                                xxs="100%"
                                            >
                                                <P>Admin Feedback</P>
                                            </Flex.Item>
                                        </Space>
                                        <Flex.Item s="100%" xxs="100%" xl="55%">
                                            <Input
                                                backgroundColor="grey500"
                                                shadow={false}
                                                style={{
                                                    paddingBottom: "7px",
                                                    paddingTop: "7px",
                                                }}
                                                rows="3"
                                                disabled="true"
                                                value={
                                                    "Updated the pannel pricing please check"
                                                }
                                                componentType="textarea"
                                            />
                                        </Flex.Item>
                                    </Flex.Container>
                                </Space>
                            </Flex.Item>
                        </Space>
                        <ProRadioInput
                            type={"approval"}
                            title={"Admin Approval"}
                            selected={"Approved"}
                            data={["Approved", "Rejected"]}
                            direction={"row"}
                            align={"center"}
                            disable={true}
                        />
                    </Container>
                </ModalAlt>
            )}
        </Section>
    );
}

export default ViewProposal;
