import React, { useCallback, useRef, useState } from "react";
import { IoArrowBackOutline } from "react-icons/io5";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import { SET_ERROR } from "../../../constants/contexConstant";
import {
    GET_MESSAGES_BY_TICKET_ID_ENDPOINT,
    SEND_MESSAGE_USER_ENDPOINT,
} from "../../../constants/routes";
import { useContextState } from "../../../context/ContextProvider";
import useFileUploadAndRemove from "../../../hooks/useFileUploadAndRemove";
import cn from "../../../lib/cn";
import request from "../../../services/request";
import P from "../../../shared/typography/P";
import ChatBoxItem from "./ChatBoxItem";
import ChatInputField from "./ChatInputField";
import ChatListItem from "./ChatListItem";
import FeedbackStars from "./FeedbackStars";
import SupportFeedback from "./SupportFeedback";

const MESSAGE_LENGTH = 30;

const WrapperMessageView = ({
    ticketList,
    meta,
    feedbackLoading,
    feedbackError,
}) => {
    const { dispatch } = useContextState();
    const chatBoxRef = useRef(null);
    const [showRating, setShowRating] = useState(false);
    const {
        file,
        setFile,
        uploadedDoc,
        uploading,
        setUploadedDoc,
        handleFileChange,
        removeFile,
    } = useFileUploadAndRemove();

    const [messageList, setMessageList] = useState({});
    const [currentMessage, setCurrentMessage] = useState("");

    const [sendingMessages, setSendingMessages] = useState(false);
    const [activeChat, setActiveChat] = useState(null);

    /* @TODO => Load Chat messages by ID @habib610 Fri August 23,2024 */
    const handleChangeChatMessage = async (id, status, feedback) => {
        try {
            let res = await request.authGet({
                endpoint: `${GET_MESSAGES_BY_TICKET_ID_ENDPOINT}/${id}/messages`,
            });

            setMessageList(res?.data);
            setActiveChat({ id, status, feedback });
            setTimeout(() => {
                chatBoxRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "end",
                    offset: 10,
                });
            }, [100]);
        } catch (error) {
            dispatch({ type: SET_ERROR, payload: "Not able to load messages" });
        }
    };
    const handleAddNewMessage = useCallback(
        async (e) => {
            e.preventDefault();
            let fileIds = uploadedDoc.map((item) => item.file);

            const body = {
                message: currentMessage,
                ticketId: activeChat?.id,
                fileIds,
            };
            try {
                setSendingMessages(true);
                let res = await request.authPost({
                    endpoint: SEND_MESSAGE_USER_ENDPOINT,
                    body,
                });
                setSendingMessages(false);

                setMessageList({
                    ...messageList,
                    messages: [res?.data?.message, ...messageList.messages],
                });
                setCurrentMessage("");
                setUploadedDoc([]);
                setFile([]);

                setTimeout(() => {
                    chatBoxRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "end",
                        inline: "end",
                    });
                }, [10]);
            } catch (error) {
                toast.error(error);
                setSendingMessages(false);
            }
        },
        [
            activeChat,
            currentMessage,
            messageList,
            setFile,
            setUploadedDoc,
            uploadedDoc,
        ]
    );

    const handleActiveChat = () => {
        setFile([]);
        setActiveChat(null);
        setUploadedDoc([]);
        setCurrentMessage("");
        setShowRating(false);
    };

    const handleAddMoreMessage = async () => {
        try {
            let res = await request.authGet({
                endpoint: `${GET_MESSAGES_BY_TICKET_ID_ENDPOINT}/${
                    activeChat?.id
                }/messages?page=${messageList.currentPage + 1}`,
            });
            let data = res?.data;

            setMessageList({
                ...messageList,

                totalPages: data?.totalPages,
                isFirst: data?.isFirst,
                isLast: data.isLast,
                currentPage: data?.currentPage,
                totalItems: data?.totalItems,

                messages: [...messageList.messages, ...data?.messages],
            });
        } catch (error) {
            dispatch({ type: SET_ERROR, payload: "Not able to load messages" });
        }
    };

    return (
        <div className="bg-white shadow-md rounded ">
            <button
                onClick={handleActiveChat}
                className={cn(
                    "text-base items-center gap-0.5 mb-1 mx-1.2 pt-0.2",
                    {
                        "flex opacity ": activeChat,
                        hidden: !activeChat,
                    }
                )}
            >
                <IoArrowBackOutline /> Go Back
            </button>

            <div className="flex h-[500px] xl:h-[525px] 2xl:h-[600px] box-border p-1.6 bg-white ">
                <div
                    className={cn(
                        "rounded-lg h-full border-blue-200 duration-300 transition-none ",
                        {
                            "w-0 overflow-hidden": activeChat,
                            "w-full overflow-y-auto": !activeChat,
                        }
                    )}
                >
                    <div className="overflow-auto  h-full no-scrollbar">
                        {/* @DESC:: Chat list item @habib610 Wed August 07,2024 */}
                        {ticketList &&
                            ticketList.map((item) => (
                                <ChatListItem
                                    {...item}
                                    active={activeChat?.id}
                                    onClick={handleChangeChatMessage}
                                    ke={item.id}
                                />
                            ))}
                    </div>
                </div>

                <div
                    className={cn("h-full duration-300 transition-none", {
                        "w-0 overflow-hidden": !activeChat,
                        "w-full overflow-visible": activeChat,
                    })}
                >
                    {activeChat && (
                        <div className="h-full bg-gray-110 rounded-lg flex flex-col">
                            {showRating ? (
                                <SupportFeedback
                                    meta={meta}
                                    ticketId={activeChat?.id}
                                    // feedback={activeChat?.feedback}
                                    onClose={(res) => {
                                        setShowRating(false);
                                        setActiveChat({
                                            ...activeChat,
                                            feedback: { ...res },
                                        });
                                    }}
                                />
                            ) : (
                                <>
                                    <div
                                        className=" flex-1 flex flex-col-reverse  px-1  overflow-auto no-scrollbar"
                                        id="scrollableDiv"
                                    >
                                        <InfiniteScroll
                                            inverse={true}
                                            scrollableTarget="scrollableDiv"
                                            loader={<h4>Loading...</h4>}
                                            next={handleAddMoreMessage}
                                            className="flex flex-col-reverse"
                                            hasMore={!messageList?.isLast}
                                            dataLength={
                                                messageList.messages?.length
                                            }
                                        >
                                            {/* @DESC:: Chat box @habib610 Wed August 07,2024 */}

                                            {messageList?.messages.map(
                                                (message) => (
                                                    <ChatBoxItem
                                                        key={message.id}
                                                        {...message}
                                                        ticketId={
                                                            activeChat?.id
                                                        }
                                                    />
                                                )
                                            )}
                                        </InfiniteScroll>
                                    </div>
                                    {activeChat?.status === "CLOSED" ? (
                                        <>
                                            {activeChat.feedback && (
                                                <div>
                                                    <FeedbackStars
                                                        className="w-2 h-2 xl:w-2 xl:h-2 mt-0.5"
                                                        filledStars={
                                                            activeChat.feedback
                                                                ?.rating
                                                        }
                                                    />
                                                    <P className="xl:text-sm 2xl:text-sm text-center mt-0.5">
                                                        {
                                                            activeChat.feedback
                                                                ?.comment
                                                        }
                                                    </P>
                                                </div>
                                            )}
                                            <button
                                                onClick={() =>
                                                    setShowRating(true)
                                                }
                                                className=" text-sm xl:text-base 2xl:text-lg self-end  text-blue-100 py-2 px-2 hover:underline"
                                            >
                                                {activeChat?.feedback
                                                    ? "Edit feedback"
                                                    : "Submit your feedback"}
                                            </button>
                                        </>
                                    ) : (
                                        <ChatInputField
                                            value={currentMessage}
                                            // disabled={MESSAGE_LENGTH < currentMessage}
                                            disabled={
                                                currentMessage.length <
                                                MESSAGE_LENGTH
                                            }
                                            onChange={(e) =>
                                                setCurrentMessage(
                                                    e.target.value
                                                )
                                            }
                                            ref={chatBoxRef}
                                            onSubmit={handleAddNewMessage}
                                            file={file}
                                            handleFileChange={handleFileChange}
                                            removeFile={removeFile}
                                            uploading={uploading}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    )}
                    <div className="hidden" ref={chatBoxRef}></div>
                </div>
            </div>
        </div>
    );
};

export default WrapperMessageView;
