import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AlertMessage from "../../../components/alert/AlertMessage";
import Loading from "../../../components/loading/Loading";
import {
    LANDING,
    PROPOSAL_DOCUMENT_META_ENDPOINT,
} from "../../../constants/routes";
import useNetwork from "../../../hooks/useNetwork";
import {
    IconMaintenance,
    IconSubsidy,
    IconTransfer,
} from "../../../resources/js/icons";
import Container from "../../../shared/Container";
import H1 from "../../../shared/typography/H1";
import TableComponent from "../../../shared/ui/Table";
import BillSummary from "../proposalDetails/BillSummary";
import BorderedContainer from "../proposalDetails/BorderedContainer";
import DetailsTable from "../proposalDetails/DetailsTable";
import DetailsWrapper from "../proposalDetails/DetailsWrapper";
import Features from "../proposalDetails/Features";
import Heading from "../proposalDetails/Heading";
import TermsAndConditions from "../proposalDetails/TermsAndConditions";
import Acceptance from "./Acceptance";

const tableProps = {
    headerColor: "bg-gray-170 rounded-xl",
    oddRowColor: "",
    additionalRowClassName: "bg-white",
    borderStyle: "border border-gray-200",
    fontSize: "text-xs md:text-sm",
};

let getOfferingFeatures = (returnOfInvestment, availSubsidy) => {
    return [
        {
            icon: IconSubsidy,
            title: "Avail Subsidy",
            value: availSubsidy,
        },
        {
            icon: IconTransfer,
            title: "Maintenance",
            value: "Free",
        },

        {
            icon: IconMaintenance,
            title: "Return on Investment",
            value: `${returnOfInvestment} Years`,
        },
    ];
};

const columns = ["Sr. No.", "Equipment", "Specification / Make"];

let getSolarPurchaseTableData = (data) => {
    return [
        {
            Description: `Solar Plant Size: ${data?.solarPlantRequired}KWp`,
            "Plan Details": (
                <span className="font-bold">{data?.solarPlantSizeCost}</span>
            ),
        },
        { Description: `GST @ 13.8%`, "Plan Details": data?.gst },
        { Description: "Total Amount", "Plan Details": data?.totalAmount },
        {
            Description: "Government Subsidy",
            "Plan Details": data?.governmentSubsidy,
        },
        {
            Description: "Return on Investments",
            "Plan Details": `${data?.returnOfInvestment} Years`,
        },
    ];
};

const purchaseColumns = ["Description", "Plan Details"];

let getMaterialSpecification = (data) => {
    return [
        {
            "Sr. No.": "1",
            Equipment: "Solar PV Modules",
            "Specification / Make": data?.solarPvModules,
        },
        {
            "Sr. No.": "2",
            Equipment: "Module Mounting Structures",
            "Specification / Make": data?.moduleMountingStructures,
        },
        {
            "Sr. No.": "3",
            Equipment: "Inverter",
            "Specification / Make": data?.inverter,
        },

        {
            "Sr. No.": "4",
            Equipment: "String Monitoring Boxes",
            "Specification / Make": data?.stringMonitoringBoxes,
        },
        {
            "Sr. No.": "5",
            Equipment: "DC Power Cables",
            "Specification / Make": data?.dcPowerCables,
        },
        {
            "Sr. No.": "6",
            Equipment: "AC Power Cables",
            "Specification / Make": data?.acPowerCables,
        },
        {
            "Sr. No.": "7",
            Equipment: "Earthing Electrode",
            "Specification / Make": data?.earthingElectrode,
        },
        {
            "Sr. No.": "8",
            Equipment: "Lighting Arrestor",
            "Specification / Make": data?.lightingArrestor,
        },
        {
            "Sr. No.": "9",
            Equipment: "Connector",
            "Specification / Make": data?.connector,
        },
        {
            "Sr. No.": "10",
            Equipment: "NET METER",
            "Specification / Make": data?.netMeter,
        },
    ];
};

let warrantyColumns = ["Type", "Coverage", "Duration"];

let getWarrantyData = (data) => {
    return [
        {
            Type: "Warranty",
            Coverage: "PV Modules",
            Duration: data?.pvtModulesWarranty,
        },
        {
            Type: "Warranty",
            Coverage: "Module Power out-put",
            Duration: data?.powerOutputWarranty,
        },
        {
            Type: "Warranty",
            Coverage: "Grid Connect String Inverter",
            Duration: data?.gridConnectWarranty,
        },
        {
            Type: "Warranty",
            Coverage: "Rest of the systems",
            Duration: data?.restSystemWarranty,
        },
    ];
};

let buildBillingSummary = (
    averageMonthlyBill,
    unitConsumption,
    solarPlantRequired
) => {
    return [
        {
            title: "Average monthly Bill",
            value: `${averageMonthlyBill}`,
            unit: "",
        },
        {
            title: "Unit Consumption",
            value: `${unitConsumption}`,
            unit: "kWh",
        },
        {
            title: "Solar Plant Required",
            value: solarPlantRequired,
            unit: "KWp",
        },
    ];
};
const PurchaseWrapper = () => {
    const { plantId } = useParams();
    const { proposalId } = useParams();
    const [plant] = useState(plantId);
    const history = useHistory();
    const [proposal] = useState(proposalId);

    const [purchase, setPurchase] = useState(null);

    const { data, error, loading } = useNetwork(
        `${PROPOSAL_DOCUMENT_META_ENDPOINT}/purchase/${plant}/${proposal}`
    );
    useEffect(() => {
        if (data) setPurchase(data?.data?.data);
    }, [data]);
    return (
        <>
            {loading ? (
                <Container className="flex justify-center">
                    <H1 className="w-[80%] md:w-[500px] flex justify-center">
                        <Loading />
                    </H1>
                </Container>
            ) : error ? (
                <Container>
                    <div className="flex justify-center ">
                        <div className="w-full  sm:w-[80%] lg:w-[500px]">
                            <AlertMessage
                                onClick={() => history.push(LANDING)}
                                message={error}
                                error
                            >
                                {error}
                            </AlertMessage>
                        </div>
                    </div>
                </Container>
            ) : purchase ? (
                <DetailsWrapper>
                    <Heading
                        date={purchase?.date}
                        propertyName={purchase?.propertyName}
                        street={purchase?.streetAddress}
                    />
                    <BillSummary
                        summary={buildBillingSummary(
                            purchase?.averageMonthlyBill,
                            purchase?.unitConsumption,
                            purchase?.solarPlantRequired
                        )}
                    />
                    <div className="flex flex-col xl:flex-row gap-2 items-stretch">
                        <DetailsTable heading="Solar Purchase Plan Details">
                            <TableComponent
                                columns={purchaseColumns}
                                data={getSolarPurchaseTableData(purchase)}
                                {...tableProps}
                                rowStyles={{
                                    boxShadow:
                                        "inset 0.5px -4px rgba(133, 133, 133, 0.07)",
                                }}
                            />
                        </DetailsTable>

                        <Features
                            details={getOfferingFeatures(
                                purchase?.returnOfInvestment,
                                purchase?.availSubsidy
                            )}
                            paymentData={{
                                terms: purchase?.paymentTerms,
                                heading: "Payment Terms",
                            }}
                        />
                    </div>

                    <BorderedContainer>
                        {purchase?.termsAndConditions && (
                            <TermsAndConditions
                                heading="Terms & Conditions"
                                terms={purchase?.termsAndConditions}
                            />
                        )}
                    </BorderedContainer>

                    <DetailsTable
                        wrapperClass="xl:w-full"
                        heading="Material Specification"
                    >
                        <TableComponent
                            columns={columns}
                            data={getMaterialSpecification(purchase)}
                            {...tableProps}
                            rowStyles={{
                                boxShadow:
                                    "inset 0.5px -4px rgba(133, 133, 133, 0.07)",
                            }}
                        />
                    </DetailsTable>

                    <DetailsTable
                        heading="Warranties and Guarantees"
                        wrapperClass="xl:w-full"
                    >
                        <TableComponent
                            columns={warrantyColumns}
                            data={getWarrantyData(purchase)}
                            {...tableProps}
                            rowStyles={{
                                boxShadow:
                                    "inset 0.5px -4px rgba(133, 133, 133, 0.07)",
                            }}
                        />
                    </DetailsTable>
                    <Acceptance />
                </DetailsWrapper>
            ) : null}
        </>
    );
};

export default PurchaseWrapper;
