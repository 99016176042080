import moment from "moment";
import React from "react";
import cn from "../../../lib/cn";
import P from "../../../shared/typography/P";
import Tag from "../../../shared/ui/tag/Tag";

const STATUS_COLORS = {
    OPEN: "green",
    IN_PROGRESS: "orange",
    CLOSED: "red",
    RESOLVED: "green",
};
const ChatListItem = (props) => {
    const statusColor = STATUS_COLORS[props?.status];
    const statusStyles = `bg-${statusColor} text-${statusColor} border-${statusColor}`;
    return (
        <div
            role="button"
            onClick={() =>
                props.onClick(props.id, props.status, props.feedback)
            }
            key={props?.id}
            className={cn(
                `py-0.8 px-1.2 xl:px-2 xl:py-1.5  mb-1.2 border border-blue-400 rounded hover:bg-gray-100 hover:cursor-pointer`,
                {
                    "bg-gray-100": props.id === props.active,
                }
            )}
        >
            <div className="flex  flex-wrap-reverse items-center justify-between gap-0.5 gap-y-0.8">
                <div className="flex flex-wrap items-center justify-between gap-0.5">
                    <P className="xl:text-base 2xl:text-base text-primary">
                        {props?.tag}
                    </P>

                    <P className="xl:text-base 2xl:text-base text-primary flex items-center flex-wrap">
                        <div className="h-0.5 w-0.5 rounded-full bg-primary mr-0.5"></div>{" "}
                        {props?.subTag}
                    </P>
                </div>
                <div className="flex gap-1 justify-between items-center mb-0.2">
                    <p className="text-secondary bg-gray-100 text-sm px-0.8 xl:px-1 xl:py-0.2 rounded border border-blue-400">
                        {props.subTagPriority}
                    </p>
                    <Tag
                        className={cn(
                            "  text-center border-solid border bg-opacity-10 px-0.8 xl:px-1 xl:py-0.2",
                            statusStyles
                        )}
                        textClass="text-sm xl:text-sm 2xl:text-sm"
                        text={props?.status.toLowerCase().replaceAll("_", " ")}
                    />
                </div>
            </div>
            <div className="flex flex-wrap items-center  gap-1">
                <p className="xl:text-xs 2xl:text-xs  text-gray-140">
                    #{props?.id}
                </p>
                <div className="h-0.4 w-0.4 rounded-full bg-gray-140"></div>
                <p className="xl:text-xs 2xl:text-xs text-gray-140">
                    {moment(props?.createdAt * 1000).format("M  MMM YY")}
                </p>
            </div>
            <P className="truncate text-secondary xl:text-sm 2xl:text-sm line-clamp-2">
                {props?.query}
            </P>
        </div>
    );
};

export default ChatListItem;
