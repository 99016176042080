import React from "react";
import P from "../../../shared/typography/P";
import BorderedContainer from "../proposalDetails/BorderedContainer";

const data = [
    {
        name: "For HyperSrot Technologies Pvt Ltd",
        list: ["Name", "Location", "Date"],
    },
    {
        name: "For Consumer:",
        list: ["Name", "Mobile"],
    },
];

const Acceptance = () => {
    return (
        <BorderedContainer>
            <P className="mb-5">Accepted By:</P>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-10.4 ">
                <SignatureFields data={data[0]} />
                <SignatureFields data={data[1]} />
            </div>
        </BorderedContainer>
    );
};

export default Acceptance;

const SignatureFields = ({ data }) => {
    return (
        <div>
            <P className="text-sm md:text-base xl:text-lg 2xl:text-lg mb-5">
                {data?.name}
            </P>
            <div className="bg-secondary h-px w-full border-b border-dashed mb-5" />
            {data?.list.map((name, i) => (
                <div key={i} className="flex items-center mb-1.5 ">
                    <P className="w-[120px] mb-1 text-secondary font-semibold md:text-base xl:text-lg 2xl:text-lg">
                        {name}
                    </P>
                    <div className="bg-secondary h-px w-full border-b border-dashed" />
                </div>
            ))}
        </div>
    );
};
