import { motion } from "framer-motion";
import React, { useState } from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { Carousel as CarouselComponent } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useHistory } from "react-router-dom";
import { listOfArticles } from "../../../constants/blogData";
import { BLOGS } from "../../../constants/routes";
import cn from "../../../lib/cn";
import Container from "../../../shared/Container";
import Section from "../../../shared/Section";
import {
    slideOpacityRight,
    translateContainer,
    wavyHeadings,
} from "../../../shared/animation/constants/landingAnimation";
import SplitText from "../../../shared/animation/typography/SplitText";
import P from "../../../shared/typography/P";
import InsightCard from "../../../shared/ui/Cards/InsightCard";

/* @TODO => Unused component. Use once blogs are ready @habib610 Sun July 28,2024 */

const InsightCarouselSection = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [currentCategory, setCurrentCategory] = useState("");
    const history = useHistory();
    const tags = [
        "Trending",
        "Sustainability",
        "Technology",
        "Government Subsidy",
        "Solar Energy",
        "Co2 Reduced",
    ];

    const previousSlide = () => {
        setCurrentSlide((prev) =>
            prev === 0 ? listOfArticles.length - 1 : prev - 1
        );
    };

    const nextSlide = () => {
        setCurrentSlide((prev) =>
            prev === listOfArticles.length - 1 ? 0 : prev + 1
        );
    };

    const handleTabClick = (category) => {
        setCurrentCategory((prev) => (prev === category ? "" : category));
    };

    const filteredCards = currentCategory
        ? listOfArticles.filter((card) => card.category === currentCategory)
        : listOfArticles;

    return (
        <Section>
            <Container>
                <motion.div
                    initial="initial"
                    whileInView="animate"
                    variants={translateContainer}
                    className="flex flex-col lg:flex-row justify-between items-center gap-0.8 lg:gap-9 mb-2.4 overflow-x-hidden"
                >
                    <div className="w-full flex flex-col gap-0.8 lg:w-[30%] xl:w-[50%] 2xl:w-[50%]">
                        <SplitText heading="h2" variants={wavyHeadings}>
                            Curious to Explore More, Checkout Our Blog Section
                        </SplitText>
                    </div>
                    <div className="flex-1">
                        <motion.div variants={slideOpacityRight}>
                            <P className="text-secondary">
                                Want to learn more about solar energy? Head to
                                Hypersot Blogs, where you can dive into an ocean
                                of knowledge and expertise about all things
                                solar.
                            </P>
                        </motion.div>
                    </div>
                </motion.div>

                <div className="flex justify-end mb-1 items-start sm:flex-col xl:flex-row lg:gap-1 flex-wrap">
                    {/* <div className="flex flex-nowrap overflow-x-auto w-[80%] xl:w-[75%] gap-2.4 mb-2.4 no-scrollbar">
                        {tags.map((tag, i) => (
                            <Tab
                                key={i}
                                text={tag}
                                active={tag === currentCategory}
                                onClick={() => handleTabClick(tag)}
                            />
                        ))}
                    </div> */}

                    {/* <a
                        href="#"
                        className={cn(
                            "text-gray-500 text-sm opacity-0 underline",
                            "xl:opacity-100 lg:text-base ml-1 xl:mt-1"
                        )}
                    >
                        See all articles
                    </a> */}

                    <div
                        className={cn(
                            "flex items-center gap-1 opacity-0 ml-1",
                            (filteredCards.length > 3 ||
                                document.body.clientWidth < 1509) &&
                                "xl:opacity-100"
                        )}
                    >
                        <button
                            onClick={previousSlide}
                            className="text-xl xl:text-3xl 2xl:text-5xl"
                        >
                            <IoIosArrowBack />
                        </button>

                        <p>
                            <span className="text-lg xl:text-3xl 2xl:text-5xl">
                                {currentSlide + 1 < 10
                                    ? `0${currentSlide + 1}`
                                    : currentSlide + 1}
                            </span>{" "}
                            <span className="text-sm xl:text-base 2xl:text-lg">
                                /{" "}
                                {listOfArticles.length < 10
                                    ? `0${listOfArticles.length}`
                                    : listOfArticles.length}
                            </span>
                        </p>

                        <button
                            onClick={nextSlide}
                            className="text-xl xl:text-3xl 2xl:text-5xl"
                        >
                            <IoIosArrowForward />
                        </button>
                    </div>
                </div>

                {filteredCards.length > 3 ||
                document.body.clientWidth < 1509 ? (
                    <div>
                        <CarouselComponent
                            selectedItem={currentSlide}
                            onChange={setCurrentSlide}
                            showArrows={false}
                            showThumbs={false}
                            showStatus={false}
                            showIndicators={false}
                            autoPlay={true}
                            infiniteLoop={true}
                            interval={3000}
                            stopOnHover={true}
                            transitionTime={500}
                            swipeable={true}
                            preventMovementUntilSwipeScrollTolerance={true}
                            swipeScrollTolerance={50}
                            emulateTouch={true}
                            centerMode={true}
                            centerSlidePercentage={
                                document.body.clientWidth < 1279
                                    ? document.body.clientWidth < 1000
                                        ? document.body.clientWidth < 699
                                            ? 100
                                            : document.body.clientWidth < 767
                                            ? 80
                                            : 70
                                        : 33
                                    : document.body.clientWidth > 1535
                                    ? 32
                                    : 35
                            }
                        >
                            {filteredCards.map((card, index) => (
                                <InsightCard
                                    key={index}
                                    category={card.category}
                                    title={card?.meta?.metaTitle}
                                    date={card?.published}
                                    imageUrl={card.meta.thumbnail}
                                    onActionClick={() =>
                                        history.push(
                                            `${BLOGS}/${card?.meta?.blogUrl}`
                                        )
                                    }
                                />
                            ))}
                        </CarouselComponent>

                        <div className="flex items-center gap-1 opacity-100 xl:opacity-0 ml-1">
                            <button
                                onClick={previousSlide}
                                className="text-xl xl:text-3xl 2xl:text-5xl"
                            >
                                <IoIosArrowBack />
                            </button>

                            <p>
                                <span className="text-lg xl:text-3xl 2xl:text-5xl">
                                    {currentSlide + 1 < 10
                                        ? `0${currentSlide + 1}`
                                        : currentSlide + 1}
                                </span>{" "}
                                <span className="text-sm xl:text-base 2xl:text-lg">
                                    /{" "}
                                    {listOfArticles.length < 10
                                        ? `0${listOfArticles.length}`
                                        : listOfArticles.length}
                                </span>
                            </p>

                            <button
                                onClick={nextSlide}
                                className="text-xl xl:text-3xl 2xl:text-5xl"
                            >
                                <IoIosArrowForward />
                            </button>
                        </div>
                    </div>
                ) : (
                    <div className="flex  gap-2 flex-wrap items-stretch mt-16">
                        {filteredCards.map((card, index) => (
                            <InsightCard
                                key={index}
                                category={card.category}
                                title={card?.meta?.metaTitle}
                                date={card?.published}
                                imageUrl={card.meta.thumbnail}
                                onActionClick={() =>
                                    history.push(
                                        `${BLOGS}/${card?.meta?.blogUrl}`
                                    )
                                }
                            />
                        ))}
                    </div>
                )}
            </Container>
        </Section>
    );
};

export default InsightCarouselSection;
