import React from "react";
import cn from "../../../lib/cn";
const DetailsTable = ({ heading, children, wrapperClass }) => {
    return (
        <div
            className={cn(
                "bg-white border-2 w-full xl:w-[50%] border-dashed rounded-xl px-1 md:px-2 py-1.5  border-gray-220",
                wrapperClass
            )}
        >
            <p className=" text-xs md:text-base lg:text-lg mb-2 text-primary font-medium">
                {heading}
            </p>
            {children}
        </div>
    );
};

export default DetailsTable;
