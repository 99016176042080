import React, { useCallback, useState } from "react";
import { FaEyeSlash } from "react-icons/fa";
import { IoEyeSharp } from "react-icons/io5";
import { toast } from "react-toastify";
import { ERROR_MSG } from "../../../constants/common";
import { SET_ERROR } from "../../../constants/contexConstant";
import { CHANGE_PASSWORD_ENDPOINT } from "../../../constants/routes";
import { useContextState } from "../../../context/ContextProvider";
import { validatePasswordUpdate } from "../../../helpers/fieldValidation";
import { updatePasswordConfirmation } from "../../../resources/js/images";
import request from "../../../services/request";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import TextField from "../../../shared/ui/Form/TextField";
import Confirmation from "./Confirmation";

const UpdatePassword = () => {
    const { dispatch } = useContextState();
    const [step, setStep] = useState(1);
    const [password, setPassword] = useState({
        current_password: "",
        new_password: "",
        confirmPassword: "",
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setPassword({ ...password, [name]: value });
    };

    const [showCurrPass, setShowCurrPass] = useState(false);
    const [showNewPass, setShowNewPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);

    const [loading, setLoading] = useState(false);

    const endpoint = CHANGE_PASSWORD_ENDPOINT;

    const handleUpdatePassWord = useCallback(
        async (e) => {
            e.preventDefault();

            setLoading(true);
            const body = {
                current_password: password.current_password,
                new_password: password.new_password,
            };
            try {
                setLoading(true);
                let res = await request.authPut({ endpoint, body });
                setLoading(false);
                setLoading(false);
                toast.success(res?.message || "Password updated successfully");
                let temp = {
                    current_password: "",
                    new_password: "",
                    confirmPassword: "",
                };
                setPassword(temp);
            } catch (error) {
                setLoading(false);
                dispatch({
                    type: SET_ERROR,
                    payload: error || ERROR_MSG,
                });
            }
        },
        [dispatch, endpoint, password]
    );

    const handleUpdatePasswordStep = (e) => {
        const getValidFormData = validatePasswordUpdate(password);
        // setErrors(getValidFormData);
        setStep(2);

        // if (Object.keys(getValidFormData).length === 0) {
        //     setStep(2);
        // }
    };

    return (
        <div>
            {step === 1 ? (
                <div>
                    <form action="">
                        <P>Set your new password</P>
                        <P className="text-secondary text-xs sm:text-sm xl:text-sm 2xl:text-sm mt-0.5 xl:mt-0.8 mb-1.2 lg:mb-1.6 2xl:mb-2.4">
                            Please enter your new password.
                        </P>

                        <div className="flex flex-col gap-2">
                            <TextField
                                label="Current Password"
                                value={password.current_password}
                                required
                                name="current_password"
                                inputWrapper="rounded-l-md"
                                errorMsg={errors.current_password}
                                onChange={handleChange}
                                type={showCurrPass ? "text" : "password"}
                                icon={
                                    showCurrPass ? (
                                        <FaEyeSlash />
                                    ) : (
                                        <IoEyeSharp />
                                    )
                                }
                                iconWrapperClass={
                                    " p-[1.5rem] rounded-r-md bg-gray-100 "
                                }
                                iconChange={() =>
                                    setShowCurrPass((prev) => !prev)
                                }
                            />
                            <TextField
                                label="New Password"
                                value={password.new_password}
                                required
                                inputWrapper="rounded-l-md"
                                name="new_password"
                                errorMsg={errors.new_password}
                                onChange={handleChange}
                                type={showNewPass ? "text" : "password"}
                                icon={
                                    showCurrPass ? (
                                        <FaEyeSlash />
                                    ) : (
                                        <IoEyeSharp />
                                    )
                                }
                                iconWrapperClass={
                                    " p-[1.5rem] rounded-r-md bg-gray-100 "
                                }
                                iconChange={() =>
                                    setShowNewPass((prev) => !prev)
                                }
                            />
                            <TextField
                                label="Confirm Password"
                                value={password.confirmPassword}
                                required
                                name="confirmPassword"
                                errorMsg={errors.confirmPassword}
                                onChange={handleChange}
                                inputWrapper="rounded-l-md"
                                type={showConfirmPass ? "text" : "password"}
                                icon={
                                    showCurrPass ? (
                                        <FaEyeSlash />
                                    ) : (
                                        <IoEyeSharp />
                                    )
                                }
                                iconWrapperClass={
                                    " p-[1.5rem] rounded-r-md bg-gray-100 "
                                }
                                iconChange={() =>
                                    setShowConfirmPass((prev) => !prev)
                                }
                            />
                            <Button
                                onClick={handleUpdatePasswordStep}
                                className="w-full mt-1 xl:mt-.5 2xl:mt-1"
                            >
                                Update Password
                            </Button>
                        </div>
                    </form>
                </div>
            ) : (
                <Confirmation
                    img={updatePasswordConfirmation}
                    heading="Update Password"
                    message=" Are you sure you want to update new password"
                >
                    <Button
                        variant="secondary"
                        className="border-solid border-2 border-primary"
                        onClick={() => setStep(1)}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleUpdatePassWord}
                        isLoading={loading}
                        disabled={loading}
                    >
                        Update
                    </Button>
                </Confirmation>
            )}
        </div>
    );
};

export default UpdatePassword;
