import React from "react";
import Counter from "../../animation/typography/Counter";
import Container from "../../Container";
import H4 from "../../typography/H4";
import P from "../../typography/P";

const SalesSummaryCard = ({ name, salesData, isActive }) => {
    return (
        <Container
            className={`${
                isActive && "border border-blue-400 rounded-2xl "
            } py-2 px-1`}
        >
            <div className="flex flex-col md:flex-row items-center md:gap-1 lg:gap-5 xl:gap-1">
                <div
                    className={`${
                        isActive && "bg-blue-300 border rounded-2xl"
                    } flex items-center justify-center p-0.5 mb-2 md:mb-0`}
                >
                    <H4 className="text-wrap">{name}</H4>
                </div>
                <div className="flex flex-wrap xl:flex-nowrap justify-between w-full gap-2 md:gap-6 lg:gap-3 xl:gap-1">
                    {salesData.map((data, index) => (
                        <>
                            <div key={index} className="text-center px-1">
                                <H4>
                                    <Counter value={data.value} />
                                </H4>
                                <P className="text-sm text-gray-500 text-nowrap">
                                    {data.label}
                                </P>
                            </div>
                            {index !== salesData.length - 1 && (
                                <div
                                    className={` min-h-[1em]  w-0.2 self-stretch bg-gray-100 ${
                                        index === salesData.length / 2 - 1 &&
                                        "flex lg:hidden xl:flex"
                                    }`}
                                ></div>
                            )}
                        </>
                    ))}
                </div>
            </div>
        </Container>
    );
};

export default SalesSummaryCard;
