import React from "react";
import { BsChevronDown, BsChevronRight, BsChevronUp } from "react-icons/bs";
import P from "../../shared/typography/P";
import Accordion from "../../shared/ui/Accordian/Accordian";
import StaticLabelDropdown from "../../shared/ui/Dropdown/StaticLabelDropdown";

const SideBar = ({ currentProposal, proposalData, handleSelectedProposal }) => {
    const previousProposals = proposalData?.filter(
        (proposal) => proposal.id !== currentProposal?.id
    );

    const handleSelected = (item) => {
        handleSelectedProposal(item);
    };
    const mapItemsToDropdownFormat = (items) => {
        if (items?.length > 1) {
            return items.map((item) => (
                <div
                    key={item.id}
                    className="flex justify-between items-center"
                    onClick={() => handleSelectedProposal(item)}
                >
                    <P className="text-blue-100">{`Proposal ID # ${item?.id}`}</P>
                    <BsChevronRight />
                </div>
            ));
        } else {
            return (
                <div
                    className="flex justify-between items-center"
                    onClick={() => handleSelectedProposal(items)}
                >
                    <P className="text-blue-100">Proposal ID #{items?.id}</P>
                    <BsChevronRight />
                </div>
            );
        }
    };
    return (
        <>
            <div className="bg-white hidden md:block border md:w-[35%] lg:w-[25%] xl:w-[20%] rounded-lg px-1 py-1 border-gray-100">
                <Accordion
                    title="Current Proposal"
                    Open={true}
                    className={"border-b border-b-blue-400"}
                    content={
                        <div
                            className="flex justify-between items-center mt-1 cursor-pointer"
                            onClick={() =>
                                handleSelectedProposal(currentProposal)
                            }
                        >
                            <P className="text-blue-100">
                                Proposal ID #{currentProposal?.id}
                            </P>
                            <BsChevronRight />
                        </div>
                    }
                    openIcon={<BsChevronUp />}
                    closeIcon={<BsChevronRight />}
                />
                <hr className="h-0.1 w-full bg-blue-300 my-2" />
                <Accordion
                    title={
                        <>
                            Recent Proposal
                            <span className="text-xs ml-1 bg-blue-300 text-blue border border-blue p-0.5 rounded-3xl">
                                {previousProposals &&
                                previousProposals?.length > 10
                                    ? previousProposals?.length
                                    : `0${previousProposals?.length}`}
                            </span>
                        </>
                    }
                    titleClass="text-nowrap"
                    className={"border-b border-b-blue-400"}
                    Open={true}
                    content={
                        previousProposals && previousProposals.length > 0 ? (
                            <div className="flex flex-col gap-1 mt-1 h-[27rem] overflow-y-auto">
                                {previousProposals.map((proposal) => (
                                    <div
                                        key={proposal.id}
                                        className="flex justify-between items-center cursor-pointer"
                                        onClick={() =>
                                            handleSelectedProposal(proposal)
                                        }
                                    >
                                        <P className="text-blue-100">
                                            Proposal ID #{proposal.id}
                                        </P>
                                        <BsChevronRight />
                                    </div>
                                ))}
                            </div>
                        ) : (
                            ""
                        )
                    }
                    openIcon={<BsChevronUp />}
                    closeIcon={<BsChevronRight />}
                />
            </div>

            <div className="flex items-center md:hidden border-b border-b-blue-300">
                <StaticLabelDropdown
                    label={<P>Current </P>}
                    items={mapItemsToDropdownFormat(currentProposal)}
                    Icon={<BsChevronDown />}
                    menuClass=" left-10"
                    handleSelection={handleSelected}
                />
                <StaticLabelDropdown
                    label={
                        <P className="text-nowrap">
                            Recent Proposal
                            <span className="text-xs ml-1 bg-blue-300 text-blue border border-blue p-1 rounded-3xl">
                                {previousProposals &&
                                previousProposals?.length > 10
                                    ? previousProposals?.length
                                    : `0${previousProposals?.length}`}
                            </span>
                        </P>
                    }
                    menuClass={"h-[30rem] overflow-y-auto"}
                    items={
                        previousProposals && previousProposals.length > 0
                            ? mapItemsToDropdownFormat(previousProposals)
                            : []
                    }
                    handleSelection={handleSelected}
                    Icon={<BsChevronDown />}
                />
            </div>
        </>
    );
};

export default SideBar;
