import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { SALES_SUMMARY_DATA_ENDPOINT } from "../../constants/routes";
import request from "../../services/request";
import Container from "../../shared/Container";
import SalesSummaryCard from "../../shared/ui/Cards/SalesSummaryCard";
import { Carousel } from "../../shared/ui/Carousel";

const HeroDetails = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const [salesData, setSalesData] = useState({});
    useEffect(() => {
        const fetchData = async () => {
            const endpoint = SALES_SUMMARY_DATA_ENDPOINT;
            const data = await request.authGet({ endpoint });

            setSalesData(data?.data);
        };
        fetchData().catch(console.error);
    }, []);

    const renderSales = () => {
        const salesCards = Object.entries(salesData.newOnboarding).map(
            ([name, data], index) => (
                <SalesSummaryCard
                    key={index}
                    name={name}
                    salesData={[
                        {
                            value: data.oneDaySales,
                            label: "1 D Sales",
                        },
                        {
                            value: data.oneWeekSales,
                            label: "7 D Sales",
                        },
                        {
                            value: data.oneMonthSales,
                            label: "30 D Sales",
                        },
                        {
                            value: data.totalSales,
                            label: "Total Sales",
                        },
                    ]}
                    isActive={currentSlide === index}
                />
            )
        );

        return salesCards;
    };
    const totalSalesData =
        salesData && salesData?.newOnboarding
            ? Object.values(salesData.newOnboarding).reduce(
                  (acc, sales) => {
                      acc.oneDaySales += sales.oneDaySales ?? 0;
                      acc.oneWeekSales += sales.oneWeekSales ?? 0;
                      acc.oneMonthSales += sales.oneMonthSales ?? 0;
                      acc.totalSales += sales.totalSales ?? 0;
                      return acc;
                  },
                  {
                      oneDaySales: 0,
                      oneWeekSales: 0,
                      oneMonthSales: 0,
                      totalSales: 0,
                  }
              )
            : {
                  oneDaySales: 0,
                  oneWeekSales: 0,
                  oneMonthSales: 0,
                  totalSales: 0,
              };
    return (
        <Container className="relative bottom-0 top-10 py-1 px-1.5 bg-white rounded-lg border border-blue-300 min-h-56 flex flex-col lg:flex-row gap-2">
            {salesData && salesData.newOnboarding && (
                <>
                    <div className="w-full lg:w-[49%]">
                        <Carousel
                            slides={renderSales()}
                            headclass="h-full"
                            autoSlide={true}
                            flexPos="justify-start lg:justify-center"
                            autoSlideInterval={3000}
                            nav={false}
                            currentSlide={currentSlide}
                            handleSlideChange={(index) =>
                                setCurrentSlide(index)
                            }
                        />
                    </div>
                    <div>
                        <SalesSummaryCard
                            name={"Total Sales Summary"}
                            salesData={[
                                {
                                    value: totalSalesData.oneDaySales,
                                    label: "1 D Sales",
                                },
                                {
                                    value: totalSalesData.oneWeekSales,
                                    label: "7 D Sales",
                                },
                                {
                                    value: totalSalesData.oneMonthSales,
                                    label: "30 D Sales",
                                },
                                {
                                    value: totalSalesData.totalSales,
                                    label: "Total Sales",
                                },
                            ]}
                        />
                    </div>
                </>
            )}
        </Container>
    );
};

export default HeroDetails;
