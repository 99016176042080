import React from "react";
import P from "../../typography/P";

const RadioButton = ({ name, value, checked, onChange, label }) => {
    return (
        <label className="inline-flex items-center space-x-1">
            <input
                type="radio"
                name={name}
                value={value}
                checked={checked}
                onChange={onChange}
                className="appearance-none h-1.2 w-1.2 checked:h-0.8 checked:w-0.8 border border-black rounded-full checked:!bg-green checked:border-transparent focus:border-transparent checked:ring-1 checked:ring-green checked:ring-offset-2 focus:outline-none focus:ring-1 focus:ring-green focus:ring-offset-2"
            />
            <P>{label}</P>
        </label>
    );
};

export default RadioButton;
