import React from "react";
import DetailsHolder from "../proposalDetails/DetailsHolder";
import LeaseWrapper from "./LeaseWrapper";

const LeaseProposalDetails = () => {
    return (
        <DetailsHolder>
            <LeaseWrapper />
        </DetailsHolder>
    );
};

export default LeaseProposalDetails;
