import React from "react";
import { IoEyeOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import Pagination from "../../components/pagination";
import { DASHBOARD, PROPOSAL } from "../../constants/routes";
import { formatDate } from "../../helpers/helpers";
import Container from "../../shared/Container";
import P from "../../shared/typography/P";
import TableComponent from "../../shared/ui/Table";

const columns = [
    "ID",
    "Project Name",
    "Date",
    "Assignee",
    "Proposal Count",
    "Action",
];

const DashBoardTable = ({ data, paginationData, onPageChange }) => {
    const history = useHistory();
    return (
        <Container className="px-1 pb-3">
            <div className="bg-gray-170 w-full rounded-2xl p-2 my-3 mb-3">
                <div className="bg-white border-2 border-dashed rounded-xl p-1.5 border-gray-220">
                    <TableComponent
                        columns={columns}
                        data={data.map((item) => ({
                            ID: item.plantId,
                            "Project Name": item.name,
                            Date: formatDate(item.creationDate),
                            Assignee: item.assignee,
                            "Proposal Count": item.count,
                            Action: (
                                <div
                                    role="button"
                                    className="flex justify-center"
                                    onClick={() =>
                                        item.count > 0
                                            ? history.push(
                                                  `${PROPOSAL}/${item.plantId}`
                                              )
                                            : history.push(
                                                  `${DASHBOARD}/${item.plantId}`
                                              )
                                    }
                                >
                                    <P>
                                        <IoEyeOutline />
                                    </P>
                                </div>
                            ),
                        }))}
                        headerColor="bg-blue-300 text-center"
                        oddRowColor="bg-gray-170"
                        additionalRowClassName="text-center"
                        borderStyle="bg-white border-collapse rounded-xl"
                    />
                </div>
            </div>
            {paginationData && (
                <Pagination
                    isFirst={paginationData.isFirst}
                    isLast={paginationData.isLast}
                    pageCount={paginationData.pageCount}
                    onChange={onPageChange}
                    forcePage={paginationData.pageNumber}
                />
            )}
        </Container>
    );
};

export default DashBoardTable;
