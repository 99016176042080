import React, { useCallback, useEffect, useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";

import { ERROR_MSG, ROLE } from "../../../constants/common";
import { SET_ERROR } from "../../../constants/contexConstant";
import {
    GET_FEEDBACK_META_ENDPOINT,
    GET_SUPPORT_TICKET_QUERY_META_ENDPOINT,
    GET_TICKET_BY_PLANT_ENDPOINT,
} from "../../../constants/routes";
import { useContextState } from "../../../context/ContextProvider";
import { checkIsAllowed } from "../../../helpers/helpers";
import useNetwork from "../../../hooks/useNetwork";
import request from "../../../services/request";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import PopupMeta from "./PopupMeta";
import SupportMessage from "./SupportMessage";
import WrapperMessageView from "./WrapperMessageView";

const ChatContainer = () => {
    const { state, dispatch } = useContextState();

    const {
        data: meta,
        loading: metaLoading,
        error: metaError,
    } = useNetwork(GET_SUPPORT_TICKET_QUERY_META_ENDPOINT);

    const {
        data: feedbackMeta,
        loading: feedbackLoading,
        error: feedbackError,
    } = useNetwork(GET_FEEDBACK_META_ENDPOINT);

    const [showModal, setShowModal] = useState(false);

    const [loading, setLoading] = useState(false);

    const [ticketList, setTicketList] = useState([]);

    useEffect(() => {
        const loadAllTicketList = async () => {
            try {
                setLoading(true);
                let res = await request.authGet({
                    endpoint: `${GET_TICKET_BY_PLANT_ENDPOINT}/${state?.user?.plant?.plantId}`,
                });
                setTicketList(res.data?.tickets);
                setLoading(false);
            } catch (error) {
                setLoading(false);

                dispatch({
                    type: SET_ERROR,
                    payload: typeof error === "string" ? error : ERROR_MSG,
                });
            }
        };
        loadAllTicketList();
    }, [dispatch, state?.user?.plant?.plantId]);

    const pushLatestQueryHandler = useCallback(
        (newQuery) => {
            setTicketList([newQuery, ...ticketList]);
        },
        [ticketList]
    );

    return (
        <>
            <div className="rounded-sm">
                <div className="  box-border rounded">
                    <div className="flex justify-between items-center bg-white px-1.6 shadow-md  mb-1 xl:mb-4 py-0.4 rounded">
                        <P>Support tickets</P>
                        {checkIsAllowed(state?.user?.roles, [
                            ROLE.CONSUMER_OWNER,
                            ROLE.INVESTOR,
                        ]) && (
                            <div className="my-1.6 xl:my-3.6 min-w-[150px]">
                                <Button
                                    onClick={() => setShowModal(true)}
                                    className=" flex items-center text-sm md:text-sm lg:text-sm xl:text-sm 2xl:text-sm font-normal rounded-none lg:rounded-0.7 xl:rounded-0.7 py-0.2 lg:py-0.8 xl:py-0.8 "
                                >
                                    <AiOutlinePlus /> Raise New Query
                                </Button>
                            </div>
                        )}
                    </div>
                    {!ticketList.length ? (
                        <SupportMessage />
                    ) : (
                        <WrapperMessageView
                            meta={feedbackMeta?.data?.tickets || []}
                            feedbackLoading={feedbackLoading}
                            feedbackError={feedbackError}
                            ticketList={ticketList}
                        />
                    )}
                </div>
            </div>

            <PopupMeta
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                meta={meta?.data?.tickets || null}
                metaLoading={metaLoading}
                metaError={metaError}
                onCreate={pushLatestQueryHandler}
            />
        </>
    );
};

export default ChatContainer;
