import React from "react";
import Container from "../../shared/Container";
import TextField from "../../shared/ui/Form/TextField";

const Filters = (onSearchValueChange, formData) => {
    return (
        <Container className="mb-2">
            <form className="flex flex-wrap xl:flex-nowrap justify-end  items-center gap-2">
                <div className="w-[35rem]">
                    <TextField
                        label=""
                        value={formData.contact}
                        onChange={onSearchValueChange}
                        name="propertyName"
                        placeholder="Search User by Email Id / Phone No."
                        type="text"
                    />
                </div>
                <div className="w-[30rem]">
                    <TextField
                        label=""
                        value={formData.id}
                        placeholder="Search by Property Id"
                        onChange={onSearchValueChange}
                        name="searchName"
                        type="text"
                    />
                </div>
            </form>
        </Container>
    );
};

export default Filters;
