import React from "react";
import P from "../../../shared/typography/P";
const cardData = [
    {
        title: "Average monthly Bill",
        value: "₹ 3,69,088",
        unit: "",
    },
    {
        title: "Unit Consumption",
        value: "17,400",
        unit: "kWh",
    },
    {
        title: "Solar Plant Required",
        value: "145",
        unit: "KWp",
    },
];

const Card = ({ title, value, unit }) => (
    <div className="border flex-col gap-2 rounded-lg bg-gray-170 py-1 px-2 min-w-full md:min-w-[30%]">
        <p className="text-xs md:text-sm text-blue">{title}</p>
        <P className="font-semibold">
            {value}{" "}
            <span className=" text-sm xl:text-base font-normal text-primary">
                {unit}
            </span>
        </P>
    </div>
);

const BillSummary = ({ summary }) => {
    return (
        <div className="bg-white  gap-3.2 p-2 border-2 border-gray-220 rounded-xl border-dashed">
            <p className="text-primary text-xs  md:text-base lg:text-lg mb-2 font-medium">
                Existing Bill Summary
            </p>
            <div className="flex flex-wrap gap-3.2 justify-between">
                {summary?.map((card, index) => (
                    <Card
                        key={index}
                        title={card.title}
                        value={card.value}
                        unit={card.unit}
                    />
                ))}
            </div>
        </div>
    );
};

export default BillSummary;
