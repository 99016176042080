import React from "react";
import { supportNew } from "../../../resources/js/icons";
import P from "../../../shared/typography/P";

const SupportMessage = () => {
    return (
        <div className="flex justify-center items-center min-h-[300px] xl:min-h-[460px] 2xl:min-h-[463px] shadow-md rounded-sm">
            <div className="flex flex-col items-center text-center h-full">
                <img
                    className="w-[60px]"
                    src={supportNew}
                    alt="support_tickets"
                />
                <P className="font-semibold">Your Support Tickets</P>
                <P className="xl:text-sm 2xl:text-sm">
                    Create your first support ticket
                </P>
            </div>
        </div>
    );
};

export default SupportMessage;
