import React, { useCallback, useRef, useState } from "react";

import { BsUpload } from "react-icons/bs";
import { LiaTimesSolid } from "react-icons/lia";

import { IoIosArrowForward, IoIosArrowRoundBack } from "react-icons/io";
import { IoDocumentOutline } from "react-icons/io5";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { TbChecks } from "react-icons/tb";
import { toast } from "react-toastify";
import AlertMessage from "../../../components/alert/AlertMessage";
import Loading from "../../../components/loading/Loading";
import { COMMON_SUCCESS_MSG, ERROR_MSG } from "../../../constants/common";
import { SET_ERROR } from "../../../constants/contexConstant";
import { CREATE_TICKET_ENDPOINT } from "../../../constants/routes";
import { useContextState } from "../../../context/ContextProvider";
import useFileUploadAndRemove from "../../../hooks/useFileUploadAndRemove";
import cn from "../../../lib/cn";
import request from "../../../services/request";
import H4 from "../../../shared/typography/H4";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";

const queryIcons = [
    <MdOutlineCurrencyRupee />,
    <TbChecks />,
    <IoDocumentOutline />,
];

let TICKET_LENGTH = 30;

const PopupMeta = ({ isOpen, onClose, meta, onCreate }) => {
    const {
        file,
        setFile,
        uploadedDoc,
        uploading,
        setUploadedDoc,
        handleFileChange,
        removeFile,
    } = useFileUploadAndRemove();
    const emptyDivRef = useRef(null);

    const { state, dispatch } = useContextState();
    const [selectedQuery, setSelectedQuery] = useState("");
    const [selectedSubtype, setSelectedSubtype] = useState("");

    const [description, setDescription] = useState("");

    const [descriptionError, setDescriptionError] = useState("");
    const [loading, setLoading] = useState(false);

    const handleQuerySelect = (query) => {
        setSelectedQuery(query);
        setSelectedSubtype("");
    };
    const handleSubtypeSelect = (subtype) => {
        setSelectedSubtype(subtype);
    };

    const cleanUpForm = useCallback(() => {
        setDescription("");
        setSelectedQuery("");
        setSelectedSubtype("");
        setUploadedDoc([]);
        setFile([]);
        onClose();
    }, [onClose, setFile, setUploadedDoc]);

    const handleFormSubmit = useCallback(
        async (e) => {
            e.preventDefault();

            setDescriptionError("");
            let fileIds = uploadedDoc.map((item) => item.file);
            try {
                setLoading(true);
                const body = {
                    message: description,
                    plantId: state?.user?.plant?.plantId,
                    tag: selectedQuery,
                    subTag: selectedSubtype,
                    fileIds,
                };

                let res = await request.authPost({
                    endpoint: CREATE_TICKET_ENDPOINT,
                    body,
                });

                toast.success(res.message || COMMON_SUCCESS_MSG);
                setLoading(false);
                onCreate(res?.data?.ticket);

                cleanUpForm();
            } catch (error) {
                setLoading(false);

                dispatch({
                    type: SET_ERROR,
                    payload: typeof error === "string" ? error : ERROR_MSG,
                });
            }
        },
        [
            cleanUpForm,
            description,
            dispatch,
            onCreate,
            selectedQuery,
            selectedSubtype,
            state?.user?.plant?.plantId,
            uploadedDoc,
        ]
    );

    const handleChangeFileInput = async (e) => {
        await handleFileChange(e);
        setTimeout(() => {
            emptyDivRef.current.scrollIntoView({
                behavior: "smooth",
                block: "end",
                inline: "end",
                offset: 10,
            });
        }, [100]);
    };

    return (
        <div
            className={cn(
                "fixed right-2 bottom-2  bg-gray-200  flex flex-col rounded-2xl  rounded-b-none duration-300 transition-all ease-in-out",
                {
                    "w-[300px] h-[400px] xl:w-[400px] xl:h-[450px]": isOpen,
                    "w-0 h-0 overflow-hidden": !isOpen,
                }
            )}
        >
            {!meta ? (
                <div className="bg-white mx-2 md:mx-4 ">
                    <AlertMessage
                        onClick={onClose}
                        message="Not available at the moment. please comeback later"
                        error
                    ></AlertMessage>
                </div>
            ) : (
                <div className="bg-primary h-full flex flex-col rounded-2xl ">
                    <div className="flex gap-2 items-center m-1 ">
                        {/* @DESC:: Meta header @habib610 Thu August 22,2024 */}
                        {selectedQuery && (
                            <div
                                role="button"
                                onClick={() => {
                                    selectedSubtype
                                        ? setSelectedSubtype("")
                                        : setSelectedQuery("");
                                }}
                            >
                                <H4 className="text-white">
                                    <IoIosArrowRoundBack />
                                </H4>
                            </div>
                        )}
                        <div>
                            <H4 className="text-white xl:text-base 2xl:text-base">
                                {!selectedQuery
                                    ? " Create Support Ticket"
                                    : !selectedSubtype
                                    ? selectedQuery
                                    : " Create Support Ticket"}
                            </H4>
                            <P className="text-white xl:text-sm 2xl:text-sm">
                                {!selectedQuery
                                    ? "Choose a topic to continue"
                                    : !selectedSubtype
                                    ? "Choose a  sub-topic to continue"
                                    : ""}
                            </P>
                        </div>
                        <H4
                            className="text-white absolute top-1 right-1 hover:cursor-pointer"
                            type="button"
                            onClick={onClose}
                        >
                            <LiaTimesSolid />
                        </H4>
                    </div>
                    <div className="bg-white rounded-t-2xl  border px-1 flex-1 overflow-auto rounded-b-none pb-2">
                        {!selectedQuery &&
                            Object.keys(meta)?.map((query, index) => (
                                <div
                                    key={index}
                                    role="button"
                                    className="flex justify-between items-center py-1 border-b"
                                    onClick={() => handleQuerySelect(query)}
                                >
                                    <div className="flex items-center gap-2">
                                        <div className="p-0.5 bg-blue-300 rounded-full">
                                            <P>{queryIcons[index]}</P>
                                        </div>
                                        <P className="text-secondary xl:text-sm 2xl:text-base">
                                            {query}
                                        </P>
                                    </div>
                                    <P className="text-secondary">
                                        <IoIosArrowForward />
                                    </P>
                                </div>
                            ))}
                        {selectedQuery &&
                            !selectedSubtype &&
                            Object.keys(meta[selectedQuery]).map(
                                (subtype, subIndex) => (
                                    <div
                                        role="button"
                                        key={subIndex}
                                        onClick={() =>
                                            handleSubtypeSelect(subtype)
                                        }
                                        className="flex justify-between items-center py-1 border-b"
                                    >
                                        <div className="flex items-center gap-2">
                                            <P className="text-secondary xl:text-sm 2xl:text-base">
                                                {subtype}
                                            </P>
                                        </div>
                                        <P className="text-secondary">
                                            <IoIosArrowForward />
                                        </P>
                                    </div>
                                )
                            )}
                        {selectedQuery && selectedSubtype && (
                            <div className="pt-1.2">
                                <div className="flex flex-col gap-1">
                                    <P className="bg-blue-300 w-max text-secondary rounded-3xl px-1 py-0.1 xl:text-sm 2xl:text-sm">
                                        {selectedQuery}
                                    </P>

                                    <P className="bg-blue-300  w-max text-secondary rounded-3xl px-1 py-0.1 xl:text-sm 2xl:text-sm">
                                        {selectedSubtype}
                                    </P>
                                </div>
                                <div className="mt-1">
                                    <label htmlFor="description">
                                        <P className="mb-0.5 xl:text-sm 2xl:text-sm">
                                            Description<span>*</span>
                                        </P>
                                    </label>
                                    <textarea
                                        id="description"
                                        value={description}
                                        onChange={(e) => {
                                            setDescription(e.target.value);
                                            if (
                                                e.target.value.length >=
                                                TICKET_LENGTH
                                            )
                                                setDescriptionError("");
                                            else
                                                setDescriptionError(
                                                    `Minimum ${TICKET_LENGTH} characters is required!`
                                                );
                                        }}
                                        placeholder="Write a brief description of your query"
                                        className={cn(
                                            "w-full text-sm border border-blue-400 rounded-lg p-1 resize-none",
                                            {
                                                "border-red": descriptionError,
                                            }
                                        )}
                                        rows="2"
                                    />

                                    {descriptionError && (
                                        <p className="text-red text-sm xl:text-sm ">
                                            {descriptionError}
                                        </p>
                                    )}
                                </div>

                                <div className="mt-1 ">
                                    {uploading ? (
                                        <P className="text-center flex justify-center">
                                            <Loading />
                                        </P>
                                    ) : (
                                        <>
                                            <P className="mb-0.5 xl:text-sm 2xl:text-sm">
                                                Upload file (optional)
                                            </P>
                                            <label
                                                role="button"
                                                className="border border-blue-400 rounded-lg p-0.5 px-0-0.2 w-full text-center flex items-center justify-center cursor-pointer mb-0.4"
                                                htmlFor="fileUploadInput"
                                            >
                                                <P className="flex items-center gap-0.4 xl:text-sm 2xl:text-base text-secondary">
                                                    <BsUpload />
                                                    Click here to upload file
                                                </P>
                                            </label>
                                        </>
                                    )}
                                    {/* Hidden File Input */}
                                    <input
                                        type="file"
                                        id="fileUploadInput"
                                        name="fileUploadInput"
                                        onClick={(e) => (e.target.value = null)}
                                        onChange={(e) =>
                                            handleChangeFileInput(e)
                                        }
                                        className="hidden"
                                    />
                                    <div className="flex flex-col-reverse">
                                        {file.length > 0 &&
                                            file.map((doc, index) => (
                                                <div
                                                    key={index}
                                                    className="flex justify-between items-center border border-blue-400 rounded-lg p-1 mb-0.5"
                                                >
                                                    <P className="text-secondary xl:text-sm 2xl:text-sm">
                                                        {doc.name}
                                                    </P>
                                                    <button
                                                        onClick={() =>
                                                            removeFile(index)
                                                        }
                                                        className="text-red-500 hover:text-red-700"
                                                    >
                                                        Remove
                                                    </button>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                                <Button
                                    onClick={handleFormSubmit}
                                    className="mt-2 w-full xl:text-sm 2xl:text-sm "
                                    variant="primary"
                                    isLoading={loading}
                                    disabled={
                                        description.length < TICKET_LENGTH ||
                                        loading
                                    }
                                >
                                    Create Support Ticket
                                </Button>
                                <div ref={emptyDivRef} className="mt-1"></div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default PopupMeta;
