import React, { useCallback, useEffect, useRef, useState } from "react";
import { RxCross1 } from "react-icons/rx";
import InfiniteScroll from "react-infinite-scroll-component";
import { toast } from "react-toastify";
import { ERROR_MSG } from "../../constants/common";
import {
    RESET_LOADER,
    SET_ERROR,
    SET_LOADER,
} from "../../constants/contexConstant";
import {
    GET_ALL_TICKETS_FOR_AGENT_ENDPOINT,
    GET_MESSAGES_BY_TICKET_ID_ENDPOINT,
    SEND_MESSAGE_USER_ENDPOINT,
} from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import useFileUploadAndRemove from "../../hooks/useFileUploadAndRemove";
import { aboutUsBg } from "../../resources/js/images";
import request from "../../services/request";
import H4 from "../../shared/typography/H4";
import Filters from "../dashboard/Filters";
import HeroDetails from "../dashboard/HeroDetails";
import Hero from "../home/Hero";
import ChatBoxItem from "../profile/chat/ChatBoxItem";
import ChatInputField from "../profile/chat/ChatInputField";
import TicketTable from "./TicketsTable";

/* @TODO => Load all messages @habib610 Thu September 05,2024 */
/* @TODO => show chat and active messages @habib610 Thu September 05,2024 */
/* @TODO => try uploading files and messages @habib610 Thu September 05,2024 */
/* @TODO => work on pagination @habib610 Thu September 05,2024 */
/* @TODO => work on filter @habib610 Thu September 05,2024 */

const TicketsManager = () => {
    const [selectedFilter, setSelectedFilter] = useState("All");
    const [ProposalGen, setProposalGen] = useState(true);
    const [messageList, setMessageList] = useState({});
    const [activeChat, setActiveChat] = useState({});

    const [currentMessage, setCurrentMessage] = useState("");
    const chatBoxRef = useRef(null);
    const { state, dispatch } = useContextState();
    const [isSidebarOpen, setIsSidebarOpen] = useState(null);
    const [sendingMessages, setSendingMessages] = useState(false);

    /* @DESC::  @habib610 Wed September 04,2024 */
    const [ticketList, setTicketList] = useState(null);

    /* @DESC:: Load ticket list @habib610 Thu September 05,2024 */

    const loadTicketList = useCallback(async () => {
        try {
            dispatch({ type: SET_LOADER });
            let res = await request.authGet({
                endpoint: GET_ALL_TICKETS_FOR_AGENT_ENDPOINT,
            });
            setTicketList(res?.data);
            dispatch({ type: RESET_LOADER });
        } catch (error) {
            dispatch({ type: RESET_LOADER });
            dispatch({
                type: SET_ERROR,
                payload: typeof error === "string" ? error : ERROR_MSG,
            });
        }
    }, [dispatch]);

    useEffect(() => {
        loadTicketList();
    }, [loadTicketList]);

    /* @DESC::  @habib610 Thu September 05,2024 */

    const [formData, setFormData] = useState({
        propertyName: "",
        searchName: "",
    });

    const toggleSidebar = (val) => {
        if (val === null) setActiveChat(null);
        else {
            console.log("Values", val);
            handleChangeChatMessage(val);
        }
    };
    const {
        file,
        setFile,
        uploadedDoc,
        uploading,
        setUploadedDoc,
        handleFileChange,
        removeFile,
    } = useFileUploadAndRemove();

    const handleFilterChange = useCallback((e) => {
        setSelectedFilter(e.target.value);
    }, []);

    const handleSearchValueChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, []);

    const handleProposalGenChange = useCallback(() => {
        setProposalGen((prev) => !prev);
    }, []);
    // const handleChangeChatMessage = useCallback(
    //     async (id) => {
    //         console.log(id, "id");
    //         try {
    //             console.log("insideLogs");
    //             let res = await request.authGet({
    //                 endpoint: `${GET_MESSAGES_BY_TICKET_ID_ENDPOINT}/${id}/messages`,
    //             });
    //             console.log(res, "Outside logs");

    //             setMessageList(res?.data);
    //             setActiveChat({ id });

    //             // setTimeout(() => {
    //             //     chatBoxRef.current.scrollIntoView({
    //             //         behavior: "smooth",
    //             //         block: "end",
    //             //         inline: "end",
    //             //         offset: 10,
    //             //     });
    //             // }, 5);
    //         } catch (error) {
    //             dispatch({
    //                 type: SET_ERROR,
    //                 payload: "Not able to load messages",
    //             });
    //         }
    //     },
    //     [dispatch]
    // );
    /* @TODO => Load Chat messages by ID @habib610 Fri August 23,2024 */
    const handleChangeChatMessage = async (id, status, feedback) => {
        console.log(id, "handleChat message");
        try {
            let res = await request.authGet({
                endpoint: `${GET_MESSAGES_BY_TICKET_ID_ENDPOINT}/${id}/messages`,
            });

            setMessageList(res?.data);
            setActiveChat({ id, status, feedback });
            // setTimeout(() => {
            //     chatBoxRef.current.scrollIntoView({
            //         behavior: "smooth",
            //         block: "end",
            //         inline: "end",
            //         offset: 10,
            //     });
            // }, [100]);
        } catch (error) {
            dispatch({
                type: SET_ERROR,
                payload:
                    typeof error === "string"
                        ? error
                        : "Not able to load messages",
            });
        }
    };

    const handleAddMoreMessage = async () => {
        try {
            let res = await request.authGet({
                endpoint: `${GET_MESSAGES_BY_TICKET_ID_ENDPOINT}/${
                    activeChat?.id
                }/messages?page=${messageList.currentPage + 1}`,
            });
            let data = res?.data;

            setMessageList({
                ...messageList,

                totalPages: data?.totalPages,
                isFirst: data?.isFirst,
                isLast: data.isLast,
                currentPage: data?.currentPage,
                totalItems: data?.totalItems,

                messages: [...messageList.messages, ...data?.messages],
            });
        } catch (error) {
            dispatch({ type: SET_ERROR, payload: "Not able to load messages" });
        }
    };

    const handleAddNewMessage = useCallback(
        async (e) => {
            e.preventDefault();
            let fileIds = uploadedDoc.map((item) => item.file);

            const body = {
                message: currentMessage,
                ticketId: activeChat?.id, // todo changes id
                fileIds,
            };
            try {
                setSendingMessages(true);
                let res = await request.authPost({
                    endpoint: SEND_MESSAGE_USER_ENDPOINT,
                    body,
                });
                setSendingMessages(false);

                setMessageList({
                    ...messageList,
                    messages: [res?.data?.message, ...messageList.messages],
                });
                setCurrentMessage("");
                setUploadedDoc([]);
                setFile([]);

                // setTimeout(() => {
                //     chatBoxRef.current.scrollIntoView({
                //         behavior: "smooth",
                //         block: "end",
                //         inline: "end",
                //     });
                // }, [10]);
            } catch (error) {
                toast.error(error);
                setSendingMessages(false);
            }
        },
        [
            activeChat?.id,
            currentMessage,
            messageList,
            setFile,
            setUploadedDoc,
            uploadedDoc,
        ]
    );

    return (
        <div>
            <Hero
                containerClass={"mb-5 xl:mb-5 2xl:mb-5"}
                wrapperClass="h-[550px] sm:h-[500px] md:h-[350px] xl:h-[300px]"
                align={"items-end px-2 relative bottom-0"}
                content={<HeroDetails />}
                url={aboutUsBg}
            />

            <Filters
                selectedFilter={selectedFilter}
                onFilterChange={handleFilterChange}
                onSearchValueChange={handleSearchValueChange}
                formData={formData}
                ProposalGen={ProposalGen}
                handleChange={handleProposalGenChange}
            />

            <TicketTable data={ticketList} toggleSidebar={toggleSidebar} />

            {activeChat && messageList?.messages && (
                <div className="fixed right-0 top-0 h-full w-full bg-primary/25 shadow-lg z-[100]">
                    <div className="fixed right-0 top-0 h-full w-full md:w-2/3 lg:w-1/2 rounded-2xl bg-primary shadow-lg z-[100] flex flex-col transition-transform transform translate-x-0">
                        <div className="bg-primary flex justify-between rounded-t-2xl p-2 ">
                            <H4 className="text-white">Ticket Summary</H4>
                            <H4 className="text-white">
                                <RxCross1 onClick={() => toggleSidebar(null)} />
                            </H4>
                        </div>

                        <div
                            className=" flex-1 flex flex-col-reverse  px-1  overflow-auto no-scrollbar"
                            id="scrollableDiv"
                        >
                            <InfiniteScroll
                                inverse={true}
                                scrollableTarget="scrollableDiv"
                                loader={<h4>Loading...</h4>}
                                next={handleAddMoreMessage}
                                className="flex flex-col-reverse"
                                hasMore={!messageList?.isLast}
                                dataLength={messageList?.messages?.length}
                            >
                                {/* @DESC:: Chat box @habib610 Wed August 07,2024 */}

                                {messageList?.messages.map((message) => (
                                    <ChatBoxItem
                                        key={message.id}
                                        {...message}
                                        ticketId={activeChat?.id}
                                    />
                                ))}
                            </InfiniteScroll>
                        </div>
                        <div className="bg-white p-2">
                            <ChatInputField
                                value={currentMessage}
                                onChange={(e) =>
                                    setCurrentMessage(e.target.value)
                                }
                                ref={chatBoxRef}
                                onSubmit={handleAddNewMessage}
                                file={file}
                                handleFileChange={handleFileChange}
                                removeFile={removeFile}
                                uploading={uploading}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TicketsManager;
