import React, { useEffect, useState } from "react";
import { GoArrowLeft } from "react-icons/go";
import { IoChevronDownOutline } from "react-icons/io5";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { H3 } from "../../../components/styles/Typography.styles";
import Textarea from "../../../components/text-area/Textarea";
import { ERROR_MSG, ROLE } from "../../../constants/common";
import { propertyList } from "../../../constants/InvestmentConstant";
import {
    PROPOSAL_UPDATE,
    SALES_PROPOSAL_BASE_ENDPOINT,
    SALES_PROPOSAL_UPDATE,
    SAVE_MASTER_DATA_ENDPOINT,
} from "../../../constants/routes";
import { useContextState } from "../../../context/ContextProvider";
import { checkIsAllowed } from "../../../helpers/helpers";
import WrapperMaster from "../../../pages/portal/proposal/WrapperMaster";
import { background } from "../../../resources/js/images";
import request from "../../../services/request";
import Container from "../../../shared/Container";
import Section from "../../../shared/Section";
import H4 from "../../../shared/typography/H4";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";
import Dropdown from "../../../shared/ui/Dropdown/Dropdown";
import RadioInput from "../../../shared/ui/Form/RadioInput";
import TextField from "../../../shared/ui/Form/TextField";
import Hero from "../../contactUs/Hero";
const HeroDetails = () => {
    return (
        <div>
            <Container>
                <div className="flex justify-center items-end min-h-[140px] lg:min-h-[180px] xl:min-h-[250px]">
                    <H4 className="text-white text-lg sm:text-xl xl:text-2xl 2xl:text-5xl mb-4 xl:mb-7">
                        Edit Proposal Data
                    </H4>
                </div>
            </Container>
        </div>
    );
};
const plantDuration = {
    5: "5 Years",
    6: "6 Years",
    7: "7 Years",
    8: "8 Years",
    9: "9 Years",
    10: "10 Years",
};
const connectionType = {
    RESIDENTIAL_SOCIETY: "RESIDENTIAL SOCIETY",
    COMMERCIAL: "COMMERCIAL",
    INDUSTRIAL: "INDUSTRIAL",
    INDIVIDUAL_HOMES: "INDIVIDUAL HOMES",
};
const RevisionProposal = () => {
    const { state } = useContextState();
    const { plantId, proposalId } = useParams();

    const fetchData = `${SALES_PROPOSAL_BASE_ENDPOINT}/${plantId}/${
        proposalId ? proposalId : "current"
    }`;
    const [initialData, setInitialData] = useState(null);
    const [errors, setErrors] = useState({
        consumerTotalUnits: "",
        consumerBill: "",
    });
    const [formData, setFormData] = useState({
        category: "",
        consumerBill: "",
        consumerTotalUnits: "",
        plantDuration: "",
        purchase: "",
        lease: "",
        loan: "",
        subsidy: false,
        tenant: false,
        comment: "",
    });
    const fetchBillAmount = async () => {
        try {
            if (!isNaN(Number(formData.consumerTotalUnits))) {
                let res = await request.authGet({
                    endpoint: `${SALES_PROPOSAL_BASE_ENDPOINT}/getBill/residential/${Number(
                        formData.consumerTotalUnits
                    )}`,
                });
                let bill = Number(res?.data?.billAmount).toFixed(2);
                if (bill) {
                    handleChange("consumerBill", Number(bill));
                } else {
                    toast.error(
                        "Can't fetch bill amount. Please try again later"
                    );
                }
            }
        } catch (error) {
            toast.error(typeof error === "string" ? error : ERROR_MSG);
        }
    };
    useEffect(() => {
        const handleCurrentProposal = async () => {
            try {
                const response = await request.authGet({
                    endpoint: `${SALES_PROPOSAL_BASE_ENDPOINT}/${plantId}/${
                        proposalId ? proposalId : "current"
                    }`,
                });
                const proposal = response.data.proposal;

                setFormData({
                    category: (proposal.category || "").replace(/_/g, " "),
                    consumerBill: proposal.consumerBill || "",
                    consumerTotalUnits: proposal.consumerTotalUnits || "",
                    plantDuration: `${proposal.plantDuration / 12} Years` || "",
                    purchase: proposal.purchase || "",
                    lease: proposal.lease || "",
                    loan: proposal.loan || "",
                    subsidy: proposal.subsidy || false,
                    tenant: proposal.tenant || false,
                    comment: proposal.comment || "",
                });
                setInitialData({
                    category: (proposal.category || "").replace(/_/g, " "),
                    consumerBill: proposal.consumerBill || "",
                    consumerTotalUnits: proposal.consumerTotalUnits || "",

                    plantDuration: `${proposal.plantDuration / 12} Years` || "",
                    purchase: proposal.purchase || "",
                    lease: proposal.lease || "",
                    loan: proposal.loan || "",
                    subsidy: proposal.subsidy || false,
                    tenant: proposal.tenant || false,
                    comment: proposal.comment || "",
                });
            } catch (error) {
                toast.error("Failed to fetch initial data");
            }
        };

        if (plantId) {
            handleCurrentProposal();
        }
    }, [plantId]);

    const handleChange = (field, value) => {
        let errorMessage = "";

        if (
            (field === "consumerTotalUnits" || field === "consumerBill") &&
            value !== "" &&
            !/^\d+$/.test(value)
        ) {
            errorMessage = "Please enter a valid integer.";
        }

        setErrors((prevErrors) => ({
            ...prevErrors,
            [field]: errorMessage,
        }));

        setFormData((prevFormData) => ({
            ...prevFormData,
            [field]: value,
        }));
    };

    const handleUpdateProposal = async (masterData) => {
        if (formData.comment.trim() === "") {
            toast.error("Admin comment is required");
            return;
        }
        if (!initialData) {
            toast.error("Failed to fetch initial data");
            return;
        }

        const updatedData = {};
        for (const key in formData) {
            if (formData[key] !== initialData[key] || key === "comment") {
                if (key === "category") {
                    updatedData[key] = formData[key];
                } else if (key === "plantDuration") {
                    updatedData[key] = parseInt(formData[key]) * 12;
                } else {
                    updatedData[key] = formData[key];
                }
            }
        }

        if (masterData) {
            updatedData.data = masterData;
        }
        try {
            if (checkIsAllowed(state?.user?.roles, [ROLE.SUPER_ADMIN])) {
                await request.authPut({
                    endpoint: `${PROPOSAL_UPDATE}/${plantId}`,
                    body: updatedData,
                });
            } else {
                await request.authPut({
                    endpoint: `${SALES_PROPOSAL_UPDATE}/${plantId}`,
                    body: updatedData,
                });
            }

            toast.success("Proposal updated successfully");
        } catch (error) {
            toast.error(typeof error === "string" ? error : ERROR_MSG);
        }
    };
    const history = useHistory();

    const editData = SAVE_MASTER_DATA_ENDPOINT;
    return (
        <div className="bg-white">
            <Hero
                content={<HeroDetails />}
                wrapperClass={"bg-blue-500"}
                url={background}
                containerClass={" mb-0 sm:mb-0 xl:mb-0 2xl:mb-0 lg:mb-0"}
            />
            <div className="-mt-2 lg:-mt-3 xl:-mt-5 bg-white p-2 m-2 rounded-t-xl">
                <Section>
                    <Container>
                        <div
                            className="flex items-center gap-2"
                            role="button"
                            onClick={() => history.goBack()}
                        >
                            <P>
                                <GoArrowLeft />
                            </P>
                            <P>Go Back</P>
                        </div>
                        <H3 className="py-1.5">Edit Porposal data</H3>
                        <div className="bg-gray-100  rounded-sm border-2 border-gray-200 p-1.6 xl:p-2.4">
                            <div className="bg-white p-1.6 xl:p-2.4 rounded-sm border-2 border-gray-140 border-dashed ">
                                <div className=" flex-wrap grid md:grid-cols-3  gap-3 items-center w-full mb-2">
                                    <Dropdown
                                        selected={formData.category}
                                        onSelect={(value) =>
                                            handleChange("category", value)
                                        }
                                        label={"Connection Type"}
                                        className={"flex-1"}
                                        data={propertyList}
                                        icon={<IoChevronDownOutline />}
                                    />
                                    <TextField
                                        size={"flex-1"}
                                        label={"Consumer's Total Unit"}
                                        placeholder="Consumers Total Unit"
                                        value={formData.consumerTotalUnits}
                                        onChange={(e) => {
                                            handleChange(
                                                "consumerTotalUnits",
                                                e.target.value
                                            );
                                            {
                                                (formData.category ===
                                                    connectionType.INDIVIDUAL_HOMES ||
                                                    formData.category ===
                                                        connectionType.RESIDENTIAL_SOCIETY) &&
                                                    fetchBillAmount();
                                            }
                                        }}
                                        errorMsg={errors.consumerTotalUnits}
                                        name="totalUnits"
                                        type="text"
                                    />
                                    <TextField
                                        size={"flex-1"}
                                        label={"Consumer's Bill"}
                                        placeholder="Consumers Bill"
                                        value={formData.consumerBill}
                                        onChange={(e) =>
                                            handleChange(
                                                "consumerBill",
                                                e.target.value
                                            )
                                        }
                                        disabled={
                                            formData.category ===
                                                connectionType.INDIVIDUAL_HOMES ||
                                            formData.category ===
                                                connectionType.RESIDENTIAL_SOCIETY
                                        }
                                        errorMsg={errors.consumerBill}
                                        name="consumerBill"
                                        type="text"
                                    />

                                    <Dropdown
                                        selected={formData.purchase}
                                        onSelect={(value) =>
                                            handleChange("purchase", value)
                                        }
                                        label={"Purchase"}
                                        className={"flex-1"}
                                        data={[
                                            "INTERESTED",
                                            "REJECTED",
                                            "APPROVED",
                                            "SHARED",
                                        ]}
                                        icon={<IoChevronDownOutline />}
                                    />
                                    <Dropdown
                                        selected={formData.lease}
                                        onSelect={(value) =>
                                            handleChange("lease", value)
                                        }
                                        label={"Lease"}
                                        className={"flex-1"}
                                        data={[
                                            "INTERESTED",
                                            "REJECTED",
                                            "APPROVED",
                                            "SHARED",
                                        ]}
                                        icon={<IoChevronDownOutline />}
                                    />
                                    <Dropdown
                                        selected={formData.loan}
                                        onSelect={(value) =>
                                            handleChange("loan", value)
                                        }
                                        label={"Loan"}
                                        className={"flex-1"}
                                        data={[
                                            "INTERESTED",
                                            "REJECTED",
                                            "APPROVED",
                                            "SHARED",
                                        ]}
                                        icon={<IoChevronDownOutline />}
                                    />
                                    <Dropdown
                                        selected={formData.plantDuration}
                                        onSelect={(value) =>
                                            handleChange("plantDuration", value)
                                        }
                                        label={"Plan Duration"}
                                        className={"flex-1"}
                                        data={Object.values(plantDuration)}
                                        icon={<IoChevronDownOutline />}
                                    />
                                    {formData.category ===
                                        connectionType.COMMERCIAL && (
                                        <div>
                                            <P>Tenant</P>

                                            <div className="flex items-center gap-2">
                                                <RadioInput
                                                    id="tenantYes"
                                                    label="Yes"
                                                    value="Yes"
                                                    name="tenant"
                                                    checked={
                                                        formData.tenant === true
                                                    }
                                                    onChange={() =>
                                                        handleChange(
                                                            "tenant",
                                                            true
                                                        )
                                                    }
                                                />

                                                <RadioInput
                                                    id="tenantNo"
                                                    label="No"
                                                    value="No"
                                                    name="tenant"
                                                    checked={
                                                        formData.tenant ===
                                                        false
                                                    }
                                                    onChange={() =>
                                                        handleChange(
                                                            "tenant",
                                                            false
                                                        )
                                                    }
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {formData.category ===
                                        connectionType.INDUSTRIAL && (
                                        <div>
                                            <P>Government Subsidy / Tenant</P>
                                            <br />
                                            <div className="flex items-center gap-2">
                                                <RadioInput
                                                    id="governmentSubsidNo"
                                                    label="No"
                                                    value="No"
                                                    name="governmentSubsidy"
                                                    checked={true}
                                                    onChange={() =>
                                                        handleChange(
                                                            "subsidy",
                                                            false
                                                        )
                                                    }
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {formData.category !==
                                        connectionType.COMMERCIAL &&
                                        formData.category !==
                                            connectionType.INDUSTRIAL && (
                                            <div>
                                                <P>Government Subsidy</P>
                                                <br />
                                                <div className="flex items-center gap-2">
                                                    <RadioInput
                                                        id="governmentSubsidYes"
                                                        label="Yes"
                                                        value="Yes"
                                                        name="governmentSubsidy"
                                                        checked={
                                                            formData.subsidy ===
                                                            true
                                                        }
                                                        onChange={() =>
                                                            handleChange(
                                                                "subsidy",
                                                                true
                                                            )
                                                        }
                                                        color="primary"
                                                    />

                                                    <RadioInput
                                                        id="governmentSubsidNo"
                                                        label="No"
                                                        value="No"
                                                        name="governmentSubsidy"
                                                        defaultChecked={
                                                            formData.subsidy ===
                                                            false
                                                        }
                                                        color="blue"
                                                        onChange={() =>
                                                            handleChange(
                                                                "subsidy",
                                                                false
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        )}
                                </div>
                                <div className="md:w-[80%]">
                                    <P>Admin's Comment</P> <br />
                                    <Textarea
                                        value={formData.comment}
                                        onChange={(value) => {
                                            handleChange("comment", value);
                                        }}
                                        placeholder={
                                            "Please Provide Update Summary"
                                        }
                                        type="text"
                                        name="comment"
                                        style={{
                                            paddingBottom: "7px",
                                            paddingTop: "7px",
                                            backgroundColor: "white",
                                        }}
                                        rows="6"
                                    />
                                </div>
                            </div>
                        </div>
                    </Container>
                </Section>
                {checkIsAllowed(state?.user?.roles, [ROLE.SUPER_ADMIN]) ? (
                    <WrapperMaster
                        fetchData={fetchData}
                        heading={"Proposal Super Admin"}
                        onSubmit={handleUpdateProposal}
                    />
                ) : (
                    <Button
                        onClick={() => {
                            handleUpdateProposal(null);
                        }}
                    >
                        Submit
                    </Button>
                )}
            </div>
        </div>
    );
};

export default RevisionProposal;
