import React, { useCallback, useState } from "react";
import { LiaTimesSolid } from "react-icons/lia";
import { toast } from "react-toastify";
import AlertMessage from "../../../components/alert/AlertMessage";
import ModalAlt from "../../../components/modal/ModalAlt";
import { H4 } from "../../../components/styles/Typography.styles";
import { ERROR_MSG } from "../../../constants/common";
import {
    RESET_LOADER,
    SET_ERROR,
    SET_LOADER,
} from "../../../constants/contexConstant";
import { DELETE_USER_FROM_PLANT_ENDPOINT } from "../../../constants/routes";
import { useContextState } from "../../../context/ContextProvider";
import request from "../../../services/request";
import Button from "../../../shared/ui/Button";
import TableComponent from "../../../shared/ui/Table";

const columns = ["Name", "Phone", "Role", "Action"];

const MembersList = ({ members, onRemove }) => {
    const [userToDelete, setUserToDelete] = useState(null);
    const { dispatch } = useContextState();

    const buildTableObjects = useCallback((members) => {
        if (members?.length === 0) return [];
        else {
            return members.map((item) => ({
                id: item?.id,
                Name: item?.name,
                Phone: item.phoneNumber.slice(2),
                Role: (
                    <>
                        {item?.roles.map((role) => {
                            let splitRoles = role.split("_");
                            return (
                                <div className="lowercase first-letter:uppercase">
                                    {splitRoles.length === 2
                                        ? splitRoles[1]
                                        : splitRoles[0]}
                                </div>
                            );
                        })}
                    </>
                ),
                Action: (
                    <div className="flex items-center justify-center gap-0.5">
                        {/* <button
                            className="text-xl hover:cursor-pointer"
                            onClick={() => handleEdit(item.id, item?.name)}
                        >
                            <CiEdit />
                        </button> */}
                        <button
                            className="text-xl text-red hover:cursor-pointer"
                            onClick={() => handleDelete(item.id, item?.name)}
                        >
                            <LiaTimesSolid />
                        </button>
                    </div>
                ),
            }));
        }
    }, []);

    const handleEdit = () => {};

    const handleDelete = (id, name) => {
        setUserToDelete({ id, name });
    };

    const deleteUserFromPlant = async () => {
        try {
            dispatch({ type: SET_LOADER });
            await request.authDelete({
                endpoint: `${DELETE_USER_FROM_PLANT_ENDPOINT}/${userToDelete?.id}`,
            });
            dispatch({ type: RESET_LOADER });
            toast.success("User has been removed");
            onRemove(userToDelete?.id);
            setUserToDelete(null);
        } catch (error) {
            setUserToDelete(null);
            dispatch({ type: RESET_LOADER });
            dispatch({
                type: SET_ERROR,
                payload: typeof error === "string" ? error : ERROR_MSG,
            });
        }
    };

    return (
        <div>
            {members.length ? (
                <TableComponent
                    columns={columns}
                    data={buildTableObjects(members)}
                    headerColor="bg-gray-170 rounded-xl"
                    oddRowColor=""
                    additionalRowClassName="bg-white"
                    borderStyle="border border-gray-200"
                    fontSize="text-xs md:text-sm"
                />
            ) : (
                <AlertMessage
                    message="No members available at the moment or please try adding members to your plant"
                    error
                />
            )}
            <ModalAlt
                width={["100%", "400px", "600px"]}
                isOpen={userToDelete}
                onClose={() => setUserToDelete(null)}
                innerTimes={false}
            >
                <div>
                    <H4 className="text-center text-secondary mb-2">
                        Are you sure to remove{" "}
                        <span className="text-primary">
                            {userToDelete?.name}
                        </span>{" "}
                        from plant?
                    </H4>
                    <div className="flex gap-2 justify-center">
                        <Button
                            onClick={() => setUserToDelete(null)}
                            variant="secondary border border-2"
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={deleteUserFromPlant}
                            variant="primary"
                            className="bg-red px-4"
                        >
                            Yes
                        </Button>
                    </div>
                </div>
            </ModalAlt>
        </div>
    );
};

export default MembersList;
