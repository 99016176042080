import React, { useState } from "react";
import P from "../../typography/P";

const Accordion = ({
    title,
    content,
    openIcon,
    closeIcon,
    className,
    Open = false,
    titleClass = "",
}) => {
    const [isOpen, setIsOpen] = useState(Open);

    return (
        <div className=" mb-2">
            <div className={"px-2"}>
                <div
                    onClick={() => setIsOpen(!isOpen)}
                    className={`flex justify-between items-center ${className} gap-2 py-1`}
                >
                    <P className={titleClass}>{title}</P>
                    <P>{isOpen ? openIcon : closeIcon}</P>
                </div>
            </div>
            {isOpen && <div className=" bg-white px-2 ">{content}</div>}
        </div>
    );
};

export default Accordion;
