export const propertyList = [
    "Residential Society",
    "Commercial",
    "Industrial",
    "Individual Homes",
];
export const connectionTypeToCategory = {
    RESIDENTIAL_SOCIETY: "Residential Society",
    COMMERCIAL: "Commercial",
    INDUSTRIAL: "Industrial",
    INDIVIDUAL_HOMES: "Individual Homes",
};
export const baseRate = {
    individual: 10,
    residential: 21,
    commercial: 16,
    industrial: 12,
};

export const propertyRanges = {
    residential: { min: 100000, max: 500000 },
    commercial: { min: 100000, max: 500000 },
    industrial: { min: 100000, max: 1000000 },
    individual: { min: 500, max: 20000 },
};

export const subsidyRange = {
    "0-5": 57600,
    "5-10": 55600,
    "10-25": 52600,
    "25-100": 47500,
    "100-500": 45000,
};
export let OPTIONS = {
    Purchase: "Purchase",
    Lease: "Lease",
    Loan: "Loan",
};

export const discountRate = {
    commercial: 0.4,
    industrial: 0.3,
    individual: 0.25,
};
export const ownerShipOptions = {
    residential: [OPTIONS.Purchase, OPTIONS.Lease],
    commercial: [OPTIONS.Purchase, OPTIONS.Lease, OPTIONS.Loan],
    industrial: [OPTIONS.Purchase, OPTIONS.Loan],
    individual: [OPTIONS.Purchase, OPTIONS.Loan],
};
