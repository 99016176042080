import React, { useEffect, useMemo, useState } from "react";
import { IoEyeOutline } from "react-icons/io5";
import { SlPencil } from "react-icons/sl";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { connectionTypeToCategory } from "../../constants/InvestmentConstant";
import { ERROR_MSG } from "../../constants/common";
import {
    CURRENT_MASTER_DATA_ENDPOINT,
    PROPOSAL,
    SAVE_PROPOSAL_DATA_ENDPOINT,
} from "../../constants/routes";
import { getKeyByValue } from "../../helpers/helpers";
import request from "../../services/request";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import CustomButton from "../../shared/ui/CustomButton";
import TextField from "../../shared/ui/Form/TextField";
import TableComponent from "../../shared/ui/Table";
import SwitchingTabs from "../../shared/ui/tab/SwitchingTabs";

const columns = ["Description", "Amount"];

const ProposalTable = ({
    connectionType,
    totalUnits,
    consumersBill,
    isProposalGenerated,
    selectedOptions,
    checkedState,
}) => {
    const history = useHistory();
    const options = selectedOptions;
    const { propertyId } = useParams();
    const [solarData, setSolarData] = useState(null);
    const [solarPlantSize, setSolarPlantSize] = useState(0);
    const [subsidyAmount, setSubsidyAmount] = useState(0);
    const [averageMonthlyBill, setAverageMonthlyBill] = useState(
        Number(consumersBill)
    );
    const [planDuration, setPlanDuration] = useState("8 Years");
    const [savedAmount, setSavedAmout] = useState(0);
    const [GST, setGST] = useState(0);
    const [totalAmt, setTotalAmt] = useState(0);
    const [upfront, setUpfront] = useState(0);
    const [leaseTable, setLeaseTable] = useState({});
    const [currentTab, setCurrentTab] = useState(0);
    const handleTabClick = (index) => {
        setCurrentTab(index);
    };
    useEffect(() => {
        const fetchData = async () => {
            const endpoint = CURRENT_MASTER_DATA_ENDPOINT;
            const data = await request.authGet({ endpoint });
            setSolarData(JSON.parse(data.data.masterData.data));
        };
        fetchData().catch(console.error);
    }, []);

    useEffect(() => {
        if (totalUnits > 0) {
            const size = totalUnits / 120;
            setAverageMonthlyBill(Number(consumersBill));
            setSolarPlantSize(size.toFixed(2));
            calculateSubsidy(size.toFixed(2));
            calculateTotalCost(size.toFixed(2));
            setLeaseTable(
                calculateLeasePricingTable(size, Number(consumersBill))
            );
        }
    }, [totalUnits, connectionType, consumersBill, solarData]);

    useEffect(() => {
        setCurrentTab(0);
    }, [selectedOptions]);

    const calculateSubsidy = (size) => {
        if (connectionType === "Residential Society") {
            let subAmt =
                solarData?.government_subsidy["residential-society"]["0-500"] *
                size;
            setSubsidyAmount(subAmt.toLocaleString());
        } else if (connectionType === "Individual Homes") {
            let subAmt;
            const subsidyData =
                solarData?.government_subsidy["individual-homes"];
            if (subsidyData) {
                Object.keys(subsidyData).forEach((range) => {
                    const [min, max] = range.split("-").map(Number);
                    if (size >= min && size < max) {
                        subAmt = subsidyData[range];
                    }
                });
            }
            if (subAmt) {
                setSubsidyAmount(subAmt.toLocaleString());
            }
        }
    };

    const calculateTotalCost = (size) => {
        let costPerKW;
        const pricingData =
            connectionType === "Residential Society" ||
            connectionType === "Individual Homes"
                ? solarData?.pricing.loan_and_purchase.subsidy
                : solarData?.pricing.loan_and_purchase["non-subsidy"];

        if (pricingData) {
            Object.keys(pricingData).forEach((range) => {
                const [min, max] = range.split("-").map(Number);
                if (size > min && size <= max) {
                    costPerKW = pricingData[range];
                }
            });
        }

        if (costPerKW) {
            const savemt = size * costPerKW;
            const totalCost = savemt * 1.138;
            setSavedAmout(savemt.toLocaleString());
            setGST((savemt * 0.138).toLocaleString());
            setTotalAmt(totalCost.toLocaleString());
            setUpfront((totalCost * 0.3).toFixed(2).toLocaleString());
        } else {
            console.error(
                "Could not find appropriate range for the provided size"
            );
        }
    };

    const calculateLeasePricingTable = (solarPlantSize, averageMonthlyBill) => {
        const investorRate = Number(
            solarData?.charges["residential-society"].investor_rate["5Y"]
        );
        const hypersrotRate = Number(
            solarData?.charges["residential-society"].hypersrot_rate.per_KW
        );

        const fixedMonthlyRental =
            (investorRate + hypersrotRate) * 120 * solarPlantSize;

        const bankGuarantee = fixedMonthlyRental * 4;
        const savingsOnExistingBillsPercentage =
            ((averageMonthlyBill - fixedMonthlyRental) / averageMonthlyBill) *
            100;

        const governmentSubsidy =
            solarData?.government_subsidy["residential-society"]["0-500"] *
            solarPlantSize;

        const planDuration = 5 * 12; // Assuming a plan duration of 5 years
        const effectiveSavingsPerMonth =
            ((averageMonthlyBill -
                fixedMonthlyRental +
                governmentSubsidy / planDuration) /
                averageMonthlyBill) *
            100;

        return {
            fixedMonthlyRental: fixedMonthlyRental.toLocaleString(),
            bankGuarantee: bankGuarantee.toLocaleString(),
            savingsOnExistingBillsPercentage:
                savingsOnExistingBillsPercentage.toFixed(2),
            governmentSubsidy: governmentSubsidy.toLocaleString(),
            effectiveSavingsPerMonth: effectiveSavingsPerMonth.toFixed(2),
        };
    };
    const planDurationYears = useMemo(() => {
        const yearsString = planDuration.split(" ")[0];
        return yearsString + "Y";
    }, [connectionType]);

    const billPayableToHypersrot = useMemo(() => {
        return (
            solarPlantSize *
            (solarData?.charges.commercial.investor_rate[planDurationYears] +
                solarData?.charges.commercial.hypersrot_rate.per_KW) *
            120
        );
    }, [solarPlantSize, solarData]);
    const billWithoutGST = useMemo(() => {
        return Number(consumersBill) / 1.18;
    }, [averageMonthlyBill]);

    const Gsthyper = useMemo(() => {
        return billPayableToHypersrot * 0.18;
    }, [billPayableToHypersrot, solarData]);

    const totalBillPayable = useMemo(() => {
        return billPayableToHypersrot + Gsthyper;
    }, [billPayableToHypersrot, GST]);

    const monthlySavings = useMemo(() => {
        return Number(consumersBill) - billPayableToHypersrot;
    }, [averageMonthlyBill, billPayableToHypersrot]);

    const savingsPercentage = useMemo(() => {
        return (monthlySavings.toFixed(0) / averageMonthlyBill) * 100;
    }, [monthlySavings, averageMonthlyBill]);

    const tenantGST = useMemo(() => {
        return billWithoutGST * 0.18;
    }, [billWithoutGST]);

    const savingsOnCollection = useMemo(() => {
        return billWithoutGST.toFixed(0) - billPayableToHypersrot.toFixed(0);
    }, [billWithoutGST, billPayableToHypersrot]);

    const gstPayableToHypersrot = useMemo(() => {
        return (totalBillPayable / 1.18) * 0.18;
    }, [totalBillPayable]);

    const gstPayableToGovernment = useMemo(() => {
        return tenantGST - gstPayableToHypersrot;
    }, [tenantGST, gstPayableToHypersrot]);
    let isCommercial =
        connectionType === connectionTypeToCategory.COMMERCIAL ||
        connectionType === connectionTypeToCategory.INDUSTRIAL;
    let isLease = options[currentTab] === "Lease";

    const tableData = isLease
        ? [
              {
                  Description: "Fixed Monthly Rental",
                  Amount: (
                      <P className="font-semibold">
                          {isProposalGenerated
                              ? leaseTable?.fixedMonthlyRental
                              : "XXXX"}
                      </P>
                  ),
              },
              {
                  Description: "Bank Guarantee",
                  Amount: (
                      <P className="font-semibold">
                          {isProposalGenerated
                              ? leaseTable?.bankGuarantee
                              : "XXXXX"}
                      </P>
                  ),
              },
              {
                  Description: "Savings on Existing Bills",
                  Amount: (
                      <P className="font-semibold">
                          {isProposalGenerated
                              ? `${leaseTable?.savingsOnExistingBillsPercentage} %`
                              : "XXXX"}
                      </P>
                  ),
              },
              {
                  Description: "Government Subsidy",
                  Amount: (
                      <P className="font-semibold">
                          {isProposalGenerated
                              ? leaseTable?.governmentSubsidy
                              : "XXXXX"}
                      </P>
                  ),
              },
              {
                  Description: "Effective Savings Per Month",
                  Amount: (
                      <P className="font-semibold">
                          {isProposalGenerated
                              ? ` ${leaseTable?.effectiveSavingsPerMonth} %`
                              : "XXXX"}
                      </P>
                  ),
              },
          ]
        : [
              {
                  Description: (
                      <P className="text-secondary xl:text-lsm">
                          Solar Plant Size{" "}
                          <span className="text-blue-100 text-xs ml-1 underline">
                              Specification
                          </span>
                      </P>
                  ),
                  Amount: (
                      <P className="font-semibold">
                          {isProposalGenerated
                              ? `${solarPlantSize} KWp`
                              : "XXXX"}
                      </P>
                  ),
              },
              {
                  Description: "GST @ 13.8%",
                  Amount: (
                      <P className="font-semibold">
                          {isProposalGenerated ? GST : "XXX"}
                      </P>
                  ),
              },
              {
                  Description: "Total Amount",
                  Amount: (
                      <P className="font-semibold">
                          {isProposalGenerated ? totalAmt : "XXXX"}
                      </P>
                  ),
              },
          ];
    const saveProposal = async () => {
        const body = {
            plantId: propertyId,
            category: getKeyByValue(connectionType),
            consumerBill: `${parseInt(consumersBill)}`,
            consumerTotalUnits: `${parseInt(totalUnits)}`,
            plantDuration: planDuration.split(" ")[0],
            purchase: checkedState["purchase"] ? "INTERESTED" : "REJECTED",
            lease: checkedState["lease"] ? "INTERESTED" : "REJECTED",
            loan: checkedState["loan"] ? "INTERESTED" : "REJECTED",
        };

        try {
            const response = await request.authPost({
                endpoint: SAVE_PROPOSAL_DATA_ENDPOINT,
                body: body,
            });

            // Handle response according to your needs
            if (response.status === "SUCCESS") {
                history.push(`${PROPOSAL}/${propertyId}`, {
                    connectionType,
                    consumersBill,
                    totalUnits,
                    checkedState,
                });
            } else {
                toast.error("Failed to save proposal:");
                return false;
            }
        } catch (error) {
            toast.error(typeof error === "string" ? error : ERROR_MSG);

            return false;
        }
    };
    return (
        <div className="bg-gray-170 w-full md:w-[65%] lg:w-[75%] xl:w-[80%] rounded-2xl p-1 md:p-2">
            <div className="flex justify-between items-center">
                <div>
                    <SwitchingTabs
                        options={options}
                        currentTab={currentTab}
                        onTabClick={handleTabClick}
                        border={"!rounded-[3rem] lg:rounded-[3rem]"}
                        bestlabel={false}
                    />
                </div>
                <div className="flex items-center gap-2">
                    <CustomButton
                        disabled={true}
                        icon={<IoEyeOutline />}
                        onClick={() => {}}
                    />
                    <CustomButton
                        disabled={true}
                        icon={<SlPencil />}
                        onClick={() => {}}
                    />
                </div>
            </div>
            <div className="bg-white border-2 border-dashed rounded-xl px-1 py-1 md:px-2 md:py-1.5 gap-3.2 mt-2 border-gray-220 flex justify-between flex-wrap items-stretch md:items-center">
                {isCommercial && options[currentTab] === "Lease" ? (
                    <div className="w-full md:w-1/3 xl:w-1/4">
                        <TextField
                            label="Bill (Without GST)"
                            value={
                                isProposalGenerated
                                    ? billWithoutGST.toLocaleString()
                                    : "XXX"
                            }
                            disabled={true}
                        />
                    </div>
                ) : (
                    <div className="w-full md:w-1/3 xl:w-1/4">
                        <TextField
                            label="Solar Plant Size"
                            value={
                                isProposalGenerated
                                    ? `${solarPlantSize} KWp`
                                    : "XXX"
                            }
                            disabled={true}
                        />
                    </div>
                )}

                {options[currentTab] === "Lease" &&
                    connectionType === "Commercial" && (
                        <div className="w-full md:w-1/3 xl:w-1/4">
                            <TextField
                                label="GST (18%)"
                                value={
                                    isProposalGenerated
                                        ? Gsthyper.toLocaleString()
                                        : "XXXX"
                                }
                                disabled={true}
                            />
                        </div>
                    )}

                {options[currentTab] === "Lease" &&
                connectionType === "Commercial" ? (
                    <div className="w-full md:w-1/3 xl:w-1/4">
                        <TextField
                            label="Total Bill Payable"
                            value={
                                isProposalGenerated
                                    ? totalBillPayable.toLocaleString()
                                    : "XXXX"
                            }
                            disabled={true}
                        />
                    </div>
                ) : (
                    options[currentTab] === "Lease" && (
                        <div className="w-full md:w-1/3 xl:w-1/4">
                            <TextField
                                label="Units Consumed"
                                value={
                                    isProposalGenerated
                                        ? Number(totalUnits).toLocaleString()
                                        : "XXXX"
                                }
                                disabled={true}
                            />
                        </div>
                    )
                )}

                {options[currentTab] === "Lease" &&
                connectionType === "Commercial" ? (
                    <div className="w-full md:w-1/3 xl:w-1/4">
                        <TextField
                            label="Monthly Savings"
                            value={
                                isProposalGenerated
                                    ? monthlySavings.toLocaleString()
                                    : "XXXX"
                            }
                            disabled={true}
                        />
                    </div>
                ) : (
                    options[currentTab] === "Lease" && (
                        <div className="w-full md:w-1/3 xl:w-1/4">
                            <TextField
                                label="Average Monthly Bill"
                                value={
                                    isProposalGenerated
                                        ? Number(consumersBill).toLocaleString()
                                        : "XXXX"
                                }
                                disabled={true}
                            />
                        </div>
                    )
                )}

                {(connectionType === "Residential Society" ||
                    connectionType === "Individual Homes") && (
                    <div className="w-full md:w-1/3 xl:w-1/4">
                        <TextField
                            label="Subsidy Amount"
                            value={
                                isProposalGenerated ? subsidyAmount : "XXXXX"
                            }
                            disabled={true}
                        />
                    </div>
                )}

                {(connectionType === "Commercial" ||
                    connectionType === "Industrial") &&
                    options[currentTab] !== "Lease" && (
                        <div className="w-full md:w-1/3 xl:w-1/4">
                            <TextField
                                label="GST"
                                value={
                                    isProposalGenerated
                                        ? GST.toLocaleString()
                                        : "XXXX"
                                }
                                disabled={true}
                            />
                        </div>
                    )}

                {connectionType === "Commercial" &&
                    options[currentTab] === "Lease" && (
                        <div className="w-full md:w-1/3 xl:w-1/4">
                            <TextField
                                label="Savings Percentage"
                                value={
                                    isProposalGenerated
                                        ? `${savingsPercentage.toFixed(2)}%`
                                        : "XXXX"
                                }
                                disabled={true}
                            />
                        </div>
                    )}

                {options[currentTab] === "Loan" && (
                    <div className="w-full md:w-1/3 xl:w-1/3">
                        <TextField
                            label="Upfront Payment (30%)"
                            value={isProposalGenerated ? upfront : "XXXX"}
                            disabled={true}
                        />
                    </div>
                )}
            </div>
            <div className="bg-white border-2 border-dashed rounded-xl px-1 py-1 md:px-2 md:py-1.5 my-2 border-gray-220">
                <TableComponent
                    columns={columns}
                    data={tableData}
                    headerColor="bg-blue-300"
                    oddRowColor="bg-gray-170"
                    additionalRowClassName="rounded-xl"
                    borderStyle="border-collapse border border-gray-200"
                    fontSize="text-xs md:text-sm"
                />
            </div>
            <Button onClick={() => saveProposal()}>Save Proposal</Button>
        </div>
    );
};

export default ProposalTable;
