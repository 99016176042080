import React from "react";
import Container from "../../shared/Container";
import P from "../../shared/typography/P";
import Checkbox from "../../shared/ui/Form/Checkbox";
import RadioButton from "../../shared/ui/Form/RadioButton";
import TextField from "../../shared/ui/Form/TextField";

const Filters = ({
    selectedFilter,
    onFilterChange,

    handleChange,
    ProposalGen,
    onSearchValueChange,

    formData,
}) => {
    return (
        <Container className="px-1 mb-4">
            <div className="flex flex-col gap-3 items-start xl:flex-row justify-between lg:items-center">
                <div className="flex flex-col space-y-2 items-start xl:flex-row lg:items-center xl:space-x-4 xl:space-y-0">
                    <P className="text-gray-600  font-medium text-nowrap">
                        Filter by:
                    </P>

                    <div className="flex space-x-4">
                        <RadioButton
                            name="filter"
                            value="All"
                            checked={selectedFilter === "All"}
                            onChange={onFilterChange}
                            label="All"
                        />
                        <RadioButton
                            name="filter"
                            value="Mine"
                            checked={selectedFilter === "Mine"}
                            onChange={onFilterChange}
                            label="Mine"
                        />
                        <Checkbox
                            checked={!ProposalGen}
                            onChange={handleChange}
                            label="Proposal not generated"
                        />
                    </div>
                </div>
                <form className="flex flex-wrap xl:flex-nowrap justify-between items-center gap-2">
                    <div className="w-[30rem]">
                        <TextField
                            label=""
                            value={formData.propertyName}
                            onChange={onSearchValueChange}
                            name="propertyName"
                            placeholder="Property Name"
                            type="text"
                        />
                    </div>
                    <div className="w-[30rem]">
                        <TextField
                            label=""
                            value={formData.searchName}
                            placeholder="Search by ID"
                            onChange={onSearchValueChange}
                            name="searchName"
                            type="text"
                        />
                    </div>
                </form>
            </div>
        </Container>
    );
};

export default Filters;
