import React from "react";
import { logoDark } from "../../../resources/js/images";
import P from "../../../shared/typography/P";

const Heading = ({ date, propertyName, street }) => {
    return (
        <div className="bg-white flex flex-col gap-3.2 p-1 sm:p-2 border-2 border-gray-220 rounded-xl border-dashed">
            <div className="flex justify-between items-start">
                <img
                    src={logoDark}
                    alt="HyperSrot logo"
                    className="w-[109px] h-[26px] md:w-[148px] md:h-[35px]"
                />
                <div className="flex flex-col justify-end text-right">
                    <P className="text-xs  sm:text-sm">To,</P>
                    <P className=" text-xs sm:text-sm">The Chairman</P>
                </div>
            </div>
            <div className="flex justify-between items-start">
                <div>
                    <P className="text-xs text-nowrap  sm:text-sm">
                        Commercial Proposal
                    </P>
                    <p className="text-secondary text-nowrap text-xs md:text-sm">
                        Date: {date}
                    </p>
                </div>
                <div className="flex flex-col justify-end text-right">
                    <P className="text-secondary text-xs  sm:text-sm">
                        {propertyName}
                    </P>
                    <P className="text-secondary text-xs text-nowrap sm:text-sm">
                        {street}
                    </P>
                </div>
            </div>
        </div>
    );
};

export default Heading;
