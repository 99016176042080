import React, { useEffect, useState } from "react";
import { FaEyeSlash } from "react-icons/fa";
import { IoEyeSharp } from "react-icons/io5";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import AlertMessage from "../../components/alert/AlertMessage";
import Loading from "../../components/loading/Loading";
import { ERROR_MSG } from "../../constants/common";
import { SET_ERROR } from "../../constants/contexConstant";
import {
    ERROR_SCREEN,
    FORGET_PASSWORD_ENDPOINT,
    LANDING,
} from "../../constants/routes";
import { useContextState } from "../../context/ContextProvider";
import { validatePasswordAndOtp } from "../../helpers/fieldValidation";
import { getURLQueryParams } from "../../helpers/helpers";
import auth from "../../services/auth";
import request from "../../services/request";
import H1 from "../../shared/typography/H1";
import P from "../../shared/typography/P";
import Button from "../../shared/ui/Button";
import TextField from "../../shared/ui/Form/TextField";
import OTP from "../../shared/ui/OTP";

const UpdatePasswordForm = () => {
    const { dispatch } = useContextState();
    const [formError, setFormError] = useState({});
    const location = useLocation();

    const [formData, setFormData] = useState({
        password: "",
        confirmPassword: "",
        otp: "",
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [firstAttempt, setFirstAttempt] = useState(false);
    const [isTfaEnable, setIsTfaEnable] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const history = useHistory();

    const code = getURLQueryParams(location, "code", true);
    const credentials = getURLQueryParams(location, "credentials", true);
    const tfa = getURLQueryParams(location, "tfa", true);

    /*  @TODO: redirect to error Mar 01  */
    if (
        code === undefined ||
        code === "" ||
        credentials === undefined ||
        credentials === "" ||
        tfa === undefined ||
        tfa === ""
    )
        history.push(ERROR_SCREEN);

    useEffect(() => {
        setLoading(true);
        setMessage("");
        setError("");
        const sentOTPToUser = async () => {
            try {
                await auth.loginOTP({
                    username: credentials,
                });
                setLoading(false);
                setShowAlert(false);
                setMessage("");
                setFirstAttempt(true);
            } catch (error) {
                setShowAlert(true);
                setError(error);
                setLoading(false);
                setFirstAttempt(false);
            }
        };

        if (tfa === "enabled") {
            sentOTPToUser();
            setIsTfaEnable(true);
        } else {
            setLoading(false);
            setShowAlert(false);
            setMessage("");
            setFirstAttempt(true);
            setIsTfaEnable(false);
        }
    }, [code, credentials, tfa]);

    const handleChangeFunction = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setFormError({ ...formError, [name]: "" });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const getValidFormData = validatePasswordAndOtp(formData, isTfaEnable);
        setFormError(getValidFormData);
        if (Object.keys(getValidFormData).length === 0) {
            setLoading(true);
            setError("");
            setMessage("");
            const endpoint = FORGET_PASSWORD_ENDPOINT;
            const body = {
                code,
                credentials,
                password: formData.password,
            };

            if (isTfaEnable) {
                body.otp = formData.otp;
            }

            try {
                let res = await request.put({ endpoint, body });
                setError("");
                setMessage(
                    res?.message || "Password has been updated successfully!"
                );
                setTimeout(() => {
                    history.push(LANDING);
                }, 3000);

                const temp = {
                    password: "",
                    confirmPassword: "",
                    otp: "",
                };
                setFormData(temp);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        }
    };

    const handleResentOTP = async (mode) => {
        setLoading(true);
        let val = credentials;
        try {
            await auth.loginOTP({
                username: val,
                type: "retry",
                mode,
            });
            if (mode === "text") {
                toast.success("OTP has been sent", {
                    containerId: "recentOtp",
                });
            } else {
                toast.success("Call has been sent", {
                    containerId: "recentOtp",
                });
            }

            setLoading(false);
        } catch (error) {
            setLoading(false);

            dispatch({
                type: SET_ERROR,
                payload: typeof error === "string" ? error : ERROR_MSG,
            });
        }
    };

    return (
        <div className="mt-3.6">
            {!firstAttempt && loading ? (
                <H1 className="text-center flex justify-center items-center ">
                    <Loading />
                </H1>
            ) : !firstAttempt && error ? (
                <div>
                    {showAlert && (
                        <AlertMessage
                            message={error}
                            error={error}
                            onClick={() => setShowAlert(false)}
                        />
                    )}
                </div>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className="grid grid-cols-1 gap-4">
                        <TextField
                            label={"Enter New Password"}
                            value={formData.password}
                            onChange={handleChangeFunction}
                            name={"password"}
                            inputWrapper="rounded-l-md"
                            errorMsg={formError.password}
                            type={showPassword ? "text" : "password"}
                            icon={
                                showPassword ? <FaEyeSlash /> : <IoEyeSharp />
                            }
                            iconWrapperClass={
                                " p-[1.5rem] rounded-r-md bg-gray-100 "
                            }
                            iconChange={() => setShowPassword((pass) => !pass)}
                        />
                        <TextField
                            label={"Re-enter New Password"}
                            value={formData.confirmPassword}
                            onChange={handleChangeFunction}
                            name={"confirmPassword"}
                            inputWrapper="rounded-l-md"
                            errorMsg={formError.confirmPassword}
                            type={showConfirmPassword ? "text" : "password"}
                            icon={
                                showPassword ? <FaEyeSlash /> : <IoEyeSharp />
                            }
                            iconWrapperClass={
                                " p-[1.5rem] rounded-r-md bg-gray-100 "
                            }
                            iconChange={() =>
                                setShowConfirmPassword((pass) => !pass)
                            }
                        />

                        {tfa === "enabled" && (
                            <OTP
                                otp={formData.otp}
                                onChange={handleChangeFunction}
                                error={error}
                                errorMessage={formError.otp}
                                resentLink={false}
                                showResentOtp={true}
                                handleResentOTP={handleResentOTP}
                            />
                        )}
                    </div>
                    <Button
                        disabled={loading}
                        isLoading={loading}
                        type="submit"
                        className="w-full mt-4"
                    >
                        Update Password
                    </Button>

                    {message && <P className="text-green mt-1">{message}</P>}
                    {error && <P className="text-red mt-1">{error}</P>}
                </form>
            )}
        </div>
    );
};

export default UpdatePasswordForm;
