import React from "react";
import { OnboardingBg } from "../../resources/js/images";

import Hero from "../contactUs/Hero";
import HeroDetails from "./heroDetails";
const Onboarding = () => {
    return (
        <div>
            <Hero
                content={<HeroDetails />}
                url={OnboardingBg}
                wrapperClass={"min-h-[70vh]"}
                containerClass={" mb-0 sm:mb-0 xl:mb-0 2xl:mb-0 lg:mb-0"}
            />
        </div>
    );
};

export default Onboarding;
