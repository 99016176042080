import React, { useState } from "react";
import cn from "../../lib/cn";
import { background } from "../../resources/js/images";
import Container from "../../shared/Container";
import Section from "../../shared/Section";
import SwitchingTabs from "../../shared/ui/tab/SwitchingTabs";
import Hero from "../contactUs/Hero";
import ChatContainer from "./chat/ChatContainer";
import ProfileCard from "./sections/ProfileCard";
import UserUpdate from "./sections/UserUpdate";

let data = ["Users", "Support"];
const Profile = () => {
    const [active, setActive] = useState(0);
    return (
        <>
            <Hero
                containerClass="mb-0 sm:mb-0 xl:mb-0 2xl:mb-0"
                wrapperClass="min-h-[20vh] mb-0 "
                url={background}
            ></Hero>
            <Section className="-mt-[50px]">
                <Container>
                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-2 xl:gap-2">
                        <div>
                            <ProfileCard />
                            <div className="xl:hidden mt-2 inline-flex justify-end">
                                <SwitchingTabs
                                    options={data}
                                    currentTab={active}
                                    onTabClick={(index) => setActive(index)}
                                    border="!rounded-[3rem] lg:rounded-[3rem]"
                                />
                            </div>
                            <div
                                className={cn("xl:block", {
                                    hidden: active === 1,
                                })}
                            >
                                <UserUpdate />
                            </div>
                        </div>
                        <div
                            className={cn("xl:block", {
                                hidden: active === 0,
                            })}
                        >
                            <ChatContainer />
                        </div>
                    </div>
                </Container>
            </Section>
        </>
    );
};

export default Profile;
