import React, { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ERROR_MSG } from "../../constants/common";
import {
    GET_ALL_PLANT_ENDPOINT,
    GET_PLANT_BY_QUERY_ENDPOINT,
} from "../../constants/routes";
import { aboutUsBg } from "../../resources/js/images";
import request from "../../services/request";
import Hero from "../home/Hero";
import DashBoardTable from "./DashBoardTable";
import Filters from "./Filters";
import HeroDetails from "./HeroDetails";

const DashBoard = () => {
    const [data, setData] = useState([]);
    const [paginationData, setPaginationData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState("All");
    const [ProposalGen, setProposalGen] = useState(false);
    const [formData, setFormData] = useState({
        propertyName: "",
        searchName: "",
    });

    const handleFilterChange = useCallback((e) => {
        setSelectedFilter(e.target.value);
    }, []);

    const handleSearchValueChange = useCallback((e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    }, []);
    const handleProposalGenChange = useCallback(() => {
        setProposalGen((prev) => !prev);
    }, []);
    const fetchAllPlantData = useCallback(
        async (pageNumber = 0, pageSize = 6) => {
            const number = isNaN(pageNumber)
                ? Number(pageNumber?.selected)
                : pageNumber;
            try {
                let endpoint = "";
                if (formData.propertyName) {
                    endpoint = `${GET_PLANT_BY_QUERY_ENDPOINT}?columnName=name&searchPattern=${
                        formData.propertyName
                    }&pageNumber=${number}&pageSize=${pageSize}&sortBy=true&assignee=${
                        selectedFilter === "Mine"
                    }&proposalGenerated=${ProposalGen}`;
                } else if (formData.searchName) {
                    endpoint = `${GET_PLANT_BY_QUERY_ENDPOINT}?columnName=plantId&searchPattern=${
                        formData.searchName
                    }&pageNumber=${number}&pageSize=${pageSize}&sortBy=true&assignee=${
                        selectedFilter === "Mine"
                    }&proposalGenerated=${ProposalGen}`;
                } else {
                    endpoint = `${GET_ALL_PLANT_ENDPOINT}?pageNumber=${number}&pageSize=${pageSize}&sortBy=true&assignee=${
                        selectedFilter === "Mine"
                    }&proposalGenerated=${ProposalGen}`;
                }
                setIsLoading(true);
                const data = await request.authGet({ endpoint });
                setData(data?.data?.plants);
                setPaginationData({
                    isFirst: data?.data?.isFirst,
                    isLast: data?.data?.isLast,
                    pageNumber: data?.data?.pageNumber,
                    pageCount: data?.data?.totalPages,
                });
                setIsLoading(false);
            } catch (error) {
                toast.error(typeof error === "string" ? error : ERROR_MSG);
                setIsLoading(false);
            }
        },
        [selectedFilter, formData, ProposalGen]
    );

    useEffect(() => {
        fetchAllPlantData();
    }, [selectedFilter, formData, ProposalGen]);

    const handleSearchSubmit = useCallback(
        (e) => {
            e.preventDefault();
            fetchAllPlantData();
        },
        [fetchAllPlantData]
    );
    return (
        <div className="bg-white">
            <Hero
                containerClass={"mb-5 xl:mb-5 2xl:mb-5"}
                wrapperClass="h-[550px] sm:h-[500px] md:h-[350px] xl:h-[300px]"
                align={"items-end px-2 relative bottom-0"}
                content={<HeroDetails />}
                url={aboutUsBg}
            />
            <Filters
                selectedFilter={selectedFilter}
                onFilterChange={handleFilterChange}
                onSearchValueChange={handleSearchValueChange}
                onSearchSubmit={handleSearchSubmit}
                formData={formData}
                ProposalGen={ProposalGen}
                handleChange={handleProposalGenChange}
            />
            <DashBoardTable
                data={data}
                paginationData={paginationData}
                onPageChange={fetchAllPlantData}
            />
        </div>
    );
};

export default DashBoard;
