import React, { useCallback, useEffect, useState } from "react";
import { GET_ALL_PROFILE_MEMBERS_ENDPOINT } from "../../../constants/routes";
import { useContextState } from "../../../context/ContextProvider";
import useNetwork from "../../../hooks/useNetwork";
import cn from "../../../lib/cn";
import MembersList from "./MembersList";
import UpdatePassword from "./UpdatePassword";

const tabData = ["Member's List", "Update Password"];

const UserUpdate = () => {
    const [activeTab, setActiveTab] = useState(0);
    const { state } = useContextState();
    const [userList, setUserList] = useState([]);
    const { data } = useNetwork(
        `${GET_ALL_PROFILE_MEMBERS_ENDPOINT}/${state?.user?.plant?.plantId}`
    );

    useEffect(() => {
        if (data?.data?.members) {
            setUserList(data?.data?.members);
        }
    }, [data?.data?.members]);

    const handleRemoveUserFromUserList = useCallback(
        (id) => {
            let members = userList.filter((item) => item.id !== id);
            setUserList(members);
        },
        [userList]
    );

    return (
        <div className="bg-white shadow-md rounded p-1.6 mt-2 xl:mt-4">
            <div className="flex  border-b border-blue-300 mb-1">
                {tabData.map((item, index) => (
                    <div
                        key={index}
                        role="button"
                        onClick={() => setActiveTab(index)}
                        className={cn(
                            "text-xs sm:text-sm xl:text-base px-0.5 xl:px-1  hover:cursor-pointer  relative border-blue pb-0.8"
                        )}
                    >
                        {item}

                        <div
                            className={cn(
                                "absolute inset-x-0 bottom-0  h-0.2 rounded-2 transition-all duration-200",
                                {
                                    "bg-linear-border-blue-cyan":
                                        index === activeTab,
                                }
                            )}
                        ></div>
                    </div>
                ))}
            </div>

            <div className="xl:mt-2.4">
                {activeTab === 0 ? (
                    <MembersList
                        onRemove={handleRemoveUserFromUserList}
                        members={userList}
                    />
                ) : (
                    <UpdatePassword />
                )}
            </div>
        </div>
    );
};

export default UserUpdate;
