import React from "react";
import { CgCheck } from "react-icons/cg";

const Stepper = ({ currentStep, onClick }) => {
    return (
        <div className="flex items-center w-full mb-2">
            <Step
                title="Profile information"
                stepNumber={1}
                currentStep={currentStep}
                onClick={onClick}
            />
            <hr className="w-full" />
            <Step
                title="Site visit information"
                stepNumber={2}
                currentStep={currentStep}
                onClick={onClick}
            />
            <hr className="w-full" />
            <Step
                title="Confirmation"
                stepNumber={3}
                currentStep={currentStep}
                onClick={onClick}
            />
        </div>
    );
};

const Step = ({ title, stepNumber, currentStep, onClick }) => {
    const isActive = currentStep === stepNumber;
    return (
        <div
            className="flex flex-col flex-1 items-center gap-1"
            role="button"
            onClick={() => onClick(stepNumber)}
        >
            <div
                className={`w-[20px] h-[20px] rounded-full flex items-center  justify-center border-[1.5px] ${
                    isActive
                        ? "text-primary border-primary"
                        : "text-secondary border-gray-500 border-opacity-40"
                } ${
                    currentStep > stepNumber
                        ? "bg-primary text-white border-primary"
                        : "bg-white"
                }  `}
            >
                {currentStep <= stepNumber ? stepNumber : <CgCheck size={18} />}
            </div>
            <span
                className={` text-nowrap ${
                    isActive || currentStep > stepNumber
                        ? "text-primary"
                        : "text-gray-500"
                }`}
            >
                {title}
            </span>
        </div>
    );
};

export default Stepper;
