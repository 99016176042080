import React, { forwardRef } from "react";
import withClickOutside from "../../../hoc/withClickOutside";
import cn from "../../../lib/cn";
import P from "../../typography/P";

const Dropdown = forwardRef(
    (
        {
            label,
            selected,
            onSelect,
            isSelected,
            setIsSelected,
            icon,
            className = "",
            required = false,
            data,
            wrapperClass = "",
            disabled = false,
        },
        ref
    ) => {
        const handleSelect = (value) => {
            onSelect(value);
            setIsSelected(false);
        };

        return (
            <div className={className}>
                {label && (
                    <label
                        className="text-secondary font-normal text-sm sm:text-base xl:text-lg "
                        htmlFor={label}
                    >
                        {label}{" "}
                        {required && <span className="text-red">*</span>}
                    </label>
                )}
                <div
                    ref={ref}
                    className={`relative p-1 py-1 lg:py-1.2 ${
                        disabled ? "bg-gray" : "bg-white border border-blue-400"
                    } hover:cursor-pointer  rounded-lg ${label && "mt-1"}`}
                    role="button"
                    onClick={() => {
                        setIsSelected((prev) => !prev);
                    }}
                >
                    <div className="flex justify-between gap-1 items-center">
                        <P className="flex-1">{selected}</P>
                        {!disabled && <div className="text-xl">{icon}</div>}
                    </div>

                    {!disabled && (
                        <div
                            className={cn(
                                "options absolute start-0 end-0 top-[45px] z-30 shadow-md transition-all duration-300 bg-blue-300 border-blue-400",
                                wrapperClass,
                                {
                                    "max-h-[500px] opacity-100 overflow-auto z-100":
                                        isSelected,
                                    "max-h-0 opacity-0 overflow-hidden":
                                        !isSelected,
                                }
                            )}
                        >
                            {data.map((item, i) => (
                                <P
                                    key={i}
                                    role="button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setIsSelected(!isSelected);
                                        return handleSelect(item);
                                    }}
                                    className="px-1 py-0.5 border-b text-lsm hover:bg-gray-150 transition-all duration-200 hover:cursor-pointer"
                                >
                                    {item}
                                </P>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

export default withClickOutside(Dropdown);
