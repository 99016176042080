import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { connectionTypeToCategory } from "../../constants/InvestmentConstant";
import { aboutUsBg } from "../../resources/js/images";
import request from "../../services/request";
import P from "../../shared/typography/P";
import Hero from "../home/Hero";
import HeroDetails from "./HeroDetails";
import ProposalContent from "./ProposalContent";

const Proposal = () => {
    const [currentTab, setCurrentTab] = useState(0);
    const { id } = useParams();
    const [currentProposal, setCurrentProposal] = useState();
    const [proposalData, setProposalData] = useState(null);
    const [selected, setSelected] = useState(null);
    const [checkedState, setCheckedState] = useState({
        purchase: true,
        lease: true,
        loan: true,
    });
    const options = Object.keys(checkedState)
        .filter((key) => checkedState[key])
        .map((option) => option.charAt(0).toUpperCase() + option.slice(1));
    const [averageMonthlyBill, setAverageMonthlyBill] = useState(0);
    const [solarPlantSize, setSolarPlantSize] = useState(0);
    const [subsidyAmount, setSubsidyAmount] = useState(0);
    const [savedAmount, setSavedAmout] = useState(0);
    const [GST, setGST] = useState(0);
    const [totalAmt, setTotalAmt] = useState(0);
    const [upfront, setUpfront] = useState(0);
    const [leaseTable, setLeaseTable] = useState([]);
    const handleTabClick = (index) => {
        setCurrentTab(index);
    };
    let solarData = selected ? JSON.parse(selected?.data) : "";
    let connectionType = connectionTypeToCategory[selected?.category];
    let totalUnits = selected?.totalUnits;
    let consumersBill = selected?.consumersBill;

    const handleSelectedProposal = (item) => {
        setSelected(item);
    };

    useEffect(() => {
        const fetchData = async () => {
            const endpoint = `/sales/proposal/${id}/all`;
            const data = await request.authGet({ endpoint });

            setProposalData(data.data.proposal);
            const foundProposal = data.data.proposal.find(
                (proposal) => proposal.isCurrentVersion === true
            );
            solarData = foundProposal ? JSON.parse(foundProposal?.data) : "";
            connectionType = foundProposal?.connectionType;
            totalUnits = foundProposal?.totalUnits;
            consumersBill = foundProposal?.consumersBill;
            setCheckedState({
                Purchase: foundProposal
                    ? foundProposal?.purchase !== "REJECTED"
                    : true,
                Lease: foundProposal
                    ? foundProposal?.lease !== "REJECTED"
                    : true,
                Loan: foundProposal ? foundProposal?.loan !== "REJECTED" : true,
            });
            setSelected(foundProposal);
            setCurrentProposal(foundProposal);
        };
        fetchData();
    }, []);

    useEffect(() => {
        solarData = selected ? JSON.parse(selected?.data) : "";
        connectionType = connectionTypeToCategory[selected?.category];
        totalUnits = selected?.consumerTotalUnits;
        consumersBill = selected?.consumerBill;
    }, [selected]);

    useEffect(() => {
        if (totalUnits > 0) {
            const size = totalUnits / 120;
            setAverageMonthlyBill(Number(consumersBill));
            setSolarPlantSize(size.toFixed(2));
            calculateSubsidy(size.toFixed(2));
            calculateTotalCost(size.toFixed(2));
            setLeaseTable(
                calculateLeasePricingTable(size, Number(consumersBill))
            );
        }
    }, [totalUnits, connectionType, consumersBill, solarData, selected]);

    const calculateSubsidy = (size) => {
        if (connectionType === "Residential Society") {
            let subAmt =
                solarData?.government_subsidy["residential-society"]["0-500"] *
                size;
            setSubsidyAmount(subAmt.toLocaleString());
        } else if (connectionType === "Individual Homes") {
            let subAmt;
            const subsidyData =
                solarData?.government_subsidy["individual-homes"];
            if (subsidyData) {
                Object.keys(subsidyData).forEach((range) => {
                    const [min, max] = range.split("-").map(Number);
                    if (size >= min && size <= max) {
                        subAmt = subsidyData[range];
                    }
                });
            }
            if (subAmt) {
                setSubsidyAmount(subAmt.toLocaleString());
            }
        }
    };

    const calculateTotalCost = (size) => {
        let costPerKW;
        const pricingData =
            connectionType === "Residential Society" ||
            connectionType === "Individual Homes"
                ? solarData?.pricing.loan_and_purchase.subsidy
                : solarData?.pricing.loan_and_purchase["non-subsidy"];

        if (pricingData) {
            Object.keys(pricingData).forEach((range) => {
                const [min, max] = range.split("-").map(Number);
                if (size >= min && size < max) {
                    costPerKW = pricingData[range];
                }
            });
        }

        if (costPerKW) {
            const savemt = size * costPerKW;
            const totalCost = savemt * 1.138;
            setSavedAmout(savemt.toLocaleString());
            setGST((savemt * 0.138).toLocaleString());
            setTotalAmt(totalCost.toLocaleString());
            setUpfront((totalCost * 0.3).toLocaleString());
        } else {
            console.error(
                "Could not find appropriate range for the provided size"
            );
        }
    };
    const calculateLeasePricingTable = (solarPlantSize, averageMonthlyBill) => {
        const investorRate = Number(
            solarData?.charges["residential-society"].investor_rate["5Y"]
        );
        const hypersrotRate = Number(
            solarData?.charges["residential-society"].hypersrot_rate.per_KW
        );

        const fixedMonthlyRental =
            (investorRate + hypersrotRate) * 120 * solarPlantSize;

        const bankGuarantee = fixedMonthlyRental * 4;
        const savingsOnExistingBillsPercentage =
            ((averageMonthlyBill - fixedMonthlyRental) / averageMonthlyBill) *
            100;

        const governmentSubsidy =
            solarData?.government_subsidy["residential-society"]["0-500"] *
            solarPlantSize;

        const planDuration = Number(selected?.plantDuration) * 12; // Assuming a plan duration of 5 years
        const effectiveSavingsPerMonth =
            ((consumersBill -
                averageMonthlyBill +
                governmentSubsidy / planDuration) /
                averageMonthlyBill) *
            100;

        return {
            fixedMonthlyRental: fixedMonthlyRental.toLocaleString(),
            bankGuarantee: bankGuarantee.toLocaleString(),
            savingsOnExistingBillsPercentage:
                savingsOnExistingBillsPercentage.toFixed(2),
            governmentSubsidy: governmentSubsidy.toLocaleString(),
            effectiveSavingsPerMonth: effectiveSavingsPerMonth.toFixed(2),
        };
    };
    const planDurationYears = useMemo(() => {
        const yearsString = selected?.planDuration;
        return yearsString + "Y";
    }, [connectionType]);

    const billPayableToHypersrot = useMemo(() => {
        return (
            solarPlantSize *
            (solarData?.charges?.commercial.investor_rate[planDurationYears] +
                solarData?.charges?.commercial.hypersrot_rate.per_KW) *
            120
        );
    }, [solarPlantSize, solarData]);

    const billWithoutGST = useMemo(() => {
        return isNaN(consumersBill)
            ? Number(selected?.consumerBill) / 1.18
            : Number(consumersBill) / 1.18;
    }, [averageMonthlyBill, consumersBill]);

    const Gsthyper = useMemo(() => {
        return billPayableToHypersrot * 0.18;
    }, [billPayableToHypersrot, solarData]);

    const totalBillPayable = useMemo(() => {
        return billPayableToHypersrot + Gsthyper;
    }, [billPayableToHypersrot, GST]);

    const monthlySavings = useMemo(() => {
        return Number(consumersBill) - billPayableToHypersrot;
    }, [averageMonthlyBill, billPayableToHypersrot]);

    const savingsPercentage = useMemo(() => {
        return (monthlySavings.toFixed(0) / averageMonthlyBill) * 100;
    }, [monthlySavings, averageMonthlyBill]);

    const tenantGST = useMemo(() => {
        return billWithoutGST * 0.18;
    }, [billWithoutGST]);

    const savingsOnCollection = useMemo(() => {
        return billWithoutGST.toFixed(0) - billPayableToHypersrot.toFixed(0);
    }, [billWithoutGST, billPayableToHypersrot]);

    const gstPayableToHypersrot = useMemo(() => {
        return (totalBillPayable / 1.18) * 0.18;
    }, [totalBillPayable]);

    const gstPayableToGovernment = useMemo(() => {
        return tenantGST - gstPayableToHypersrot;
    }, [tenantGST, gstPayableToHypersrot]);

    let isLease = options[currentTab] === "Lease";

    const tableData = isLease
        ? [
              {
                  Description: "Fixed Monthly Rental",
                  "Plan Details": (
                      <P className="font-semibold">
                          {leaseTable?.fixedMonthlyRental}
                      </P>
                  ),
              },
              {
                  Description: "Bank Guarantee",
                  "Plan Details": (
                      <P className="font-semibold">
                          {leaseTable?.bankGuarantee}
                      </P>
                  ),
              },
              {
                  Description: "Savings on Existing Bills",
                  "Plan Details": (
                      <P className="font-semibold">
                          {leaseTable?.savingsOnExistingBillsPercentage} %
                      </P>
                  ),
              },
              {
                  Description: "Government Subsidy",
                  "Plan Details": (
                      <P className="font-semibold">
                          {leaseTable?.governmentSubsidy}
                      </P>
                  ),
              },
              {
                  Description: "Effective Savings Per Month",
                  "Plan Details": (
                      <P className="font-semibold">
                          {leaseTable?.effectiveSavingsPerMonth} %
                      </P>
                  ),
              },
          ]
        : [
              {
                  Description: (
                      <P className="text-secondary ">
                          Solar Plant Size{" "}
                          <span className="text-blue-100 text-xs ml-1 underline">
                              Specification
                          </span>
                      </P>
                  ),
                  "Plan Details": (
                      <P className="font-semibold">{solarPlantSize} KWp</P>
                  ),
              },
              {
                  Description: "GST @ 13.8%",
                  "Plan Details": <P className="font-semibold">{GST}</P>,
              },
              {
                  Description: "Total Amount",
                  "Plan Details": <P className="font-semibold">{totalAmt}</P>,
              },
          ];

    return (
        <div>
            <Hero
                containerClass={"mb-5 xl:mb-5 2xl:mb-5"}
                wrapperClass="h-[500px] sm:h-[350px] xl:h-[350px]"
                align={"items-end px-2 relative bottom-0"}
                content={
                    <HeroDetails
                        currentTab={currentTab}
                        handleTabClick={handleTabClick}
                        selected={selected}
                        upfront={upfront}
                        subsidyAmount={subsidyAmount}
                        billWithSolar={savedAmount}
                        solarPlantSize={solarPlantSize}
                        options={options}
                        billWithoutGST={billWithoutGST}
                        connectionType={connectionType}
                    />
                }
                url={aboutUsBg}
            />
            <ProposalContent
                currentTab={currentTab}
                currentProposal={currentProposal}
                proposalData={proposalData}
                connectionType={connectionType}
                selected={selected}
                tableData={tableData}
                options={options}
                handleSelectedProposal={handleSelectedProposal}
            />
        </div>
    );
};

export default Proposal;
