import React from "react";
import { checkBox } from "../../../resources/js/images";

const Checkbox = ({ label, checked, onChange }) => {
    return (
        <label className="flex items-center space-x-1 cursor-pointer">
            <div
                className={` flex  border-2 rounded-md ${
                    checked ? "border-none" : "w-6 h-6 border-gray-300"
                }`}
                onClick={onChange}
            >
                {checked && (
                    <img
                        src={checkBox}
                        alt="checked"
                        className=" h-1.5  w-1.5"
                    />
                )}
            </div>
            <span className="text-sm">{label}</span>
        </label>
    );
};

export default Checkbox;
