import React from "react";
import P from "../../../shared/typography/P";
const cardData = [
    {
        title: "Monthly Saving",
        value: "1,38,538",
        unit: "",
    },
    {
        title: "Effective Savings Percentage",
        value: "49.33",
        unit: "",
    },
    {
        title: "Saving in 5 Years",
        value: "83,12,260 + 26,10,000",
        unit: "",
    },
    {
        title: "Lifetime Saving*",
        value: "10,50,24,120",
        unit: "",
    },
];

const Card = ({ title, value, unit }) => (
    <div className="border flex-col gap-2 rounded-lg bg-gray-170 py-1 px-2 min-w-full md:min-w-[40%] xl:min-w-[22%]">
        <p className=" text-xs md:text-sm text-blue">{title}</p>
        <P className="font-semibold">
            {value}
            <span className=" text-sm xl:text-base font-normal text-primary">
                {unit}
            </span>
        </P>
    </div>
);
const Savings = ({ data }) => {
    return (
        <div className="bg-white  gap-3.2 p-2 border-2 border-gray-220 rounded-xl border-dashed">
            <p className="text-xs  text-primary md:text-base lg:text-lg mb-2 font-medium">
                Savings at Glance
            </p>
            <div className="flex gap-3.2 flex-wrap justify-between">
                {data.map((card, index) => (
                    <Card
                        key={index}
                        title={card.title}
                        value={card.value}
                        unit={card.unit}
                    />
                ))}
            </div>
        </div>
    );
};

export default Savings;
