import React, { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import ReactGA from "react-ga";
import { BrowserRouter } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled, { ThemeProvider } from "styled-components";
import Routes from "./Routes";
import Enquiry from "./app/enquiry";
import AdminEnquiry from "./app/enquiry/AdminEnquiry";
import ErrorFallback from "./components/error/ErrorFallback";
import RequestHandler from "./components/requestHandler/RequestHandler";
import GlobalStyles from "./components/styles/GlobalStyles";
import Toast from "./components/toast/Toast";
import { ROLE } from "./constants/common";
import { useContextState } from "./context/ContextProvider";
import { checkIsAllowed } from "./helpers/helpers";
import LoginModal from "./pages/login/LoginModal";
import SignupModal from "./pages/login/SignupModal";
import Footer from "./shared/footer/Footer";
import Header from "./shared/header/Header";
import theme from "./theme";
ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);

const App = () => {
    // useEffect(() => {
    //     loadWhatsAppSdk("kiwi", WHATSAPP_SDK_END_POINT, WHATSAPP_SDK_SECRET);
    // }, []);
    const { state } = useContextState();
    const [showModal, setShowModal] = useState(false);
    const handleModal = () => {
        setShowModal((showModal) => !showModal);
    };
    return (
        <ThemeProvider theme={theme}>
            <GlobalStyles />
            <BrowserRouter>
                {/* <Router /> */}
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    <RequestHandler />
                    <Header handleModal={handleModal} />
                    {/* <header>
                        <Navbar />
                    </header> */}
                    <Wrapper>
                        <main>
                            <Routes />
                        </main>
                        {/*<footer>
                            <Footer />
                </footer>*/}
                        <Footer />
                    </Wrapper>

                    <LoginModal />
                    <SignupModal />
                    {checkIsAllowed(state?.user?.roles, [
                        ROLE.SUPER_ADMIN,
                        ROLE.SALES_MANAGER,
                    ]) ? (
                        <AdminEnquiry
                            isOpen={showModal}
                            onClose={handleModal}
                        />
                    ) : (
                        <Enquiry isOpen={showModal} onClose={handleModal} />
                    )}

                    <Toast />
                </ErrorBoundary>
                {/* <BottomTab /> */}
            </BrowserRouter>
        </ThemeProvider>
    );
};
export default App;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height: 100vh;
    main {
        flex: 1;
    }
`;
