import { AnimatePresence, motion } from "framer-motion";
import React, { useCallback, useEffect, useState } from "react";
import { FaEdit } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import styled from "styled-components";
import Icon from "../../../components/Icon/Icon";
import Accordion from "../../../components/accordion/Accordion";
import DropDown from "../../../components/dropdown/DropDown";
import Input from "../../../components/input/Input";
import ModalTimes from "../../../components/modal/ModalTimes";
import ModalTitle from "../../../components/modal/ModalTitle";
import Space from "../../../components/space/Space";
import { Flex } from "../../../components/styles/Elements.styles";
import { H3, P as Pa } from "../../../components/styles/Typography.styles";
import { ERROR_MSG } from "../../../constants/common";
import { RESET_LOADER, SET_LOADER } from "../../../constants/contexConstant";
import { useContextState } from "../../../context/ContextProvider";
import request from "../../../services/request";
import Container from "../../../shared/Container";
import Section from "../../../shared/Section";
import H2 from "../../../shared/typography/H2";
import P from "../../../shared/typography/P";
import Button from "../../../shared/ui/Button";

const WrapperMaster = ({
    fetchData,
    editData,
    heading,
    onSubmit,
    sectionClass,
}) => {
    const { dispatch } = useContextState();
    const [modalState, setModalState] = useState({
        isOpen: true,
        mode: null,
    });

    const [loanAndPurchaseValue, setLoanAndPurchaseValue] = useState("");

    const [leasingValue, setLeasingValue] = useState("");

    const [onboardingLeasingValue, setOnboardingLeasingValue] = useState("");
    const [paymentPurchaseValue, setPaymentPurchaseValue] = useState("");
    const [paymentLoanValue, setPaymentLoanValue] = useState("");
    const [dataFromSever, setDataFromSever] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [pricing, setPricing] = useState({
        loan_and_purchase: {
            subsidy: {
                "0-5": "",
                "5-10": "",
                "10-25": "",
                "25-100": "",
                "100-500": "",
            },
            "non-subsidy": {
                "0-5": "",
                "5-10": "",
                "10-25": "",
                "25-100": "",
                "100-500": "",
            },
        },
        leasing: {
            subsidy: {
                "25-100": "",
                "100-500": "",
            },
        },
    });
    const [pricingErrorState, setPricingErrorState] = useState({
        loan_and_purchase: {
            subsidy: {
                "0-5": false,
                "5-10": false,
                "10-25": false,
                "25-100": false,
                "100-500": false,
            },
            "non-subsidy": {
                "0-5": false,
                "5-10": false,
                "10-25": false,
                "25-100": false,
                "100-500": false,
            },
        },
        leasing: {
            subsidy: {
                "25-100": false,
                "100-500": false,
            },
        },
    });

    const [governmentSubsidy, setGovernmentSubsidy] = useState({
        "residential-society": {
            "0-500": "",
        },
        "individual-homes": {
            "0-2": "",
            "2-3": "",
            "3-500": "",
        },
    });
    const [governmentSubsidyErrorState, setGovernmentSubsidyErrorState] =
        useState({
            "residential-society": {
                "0-500": false,
            },
            "individual-homes": {
                "0-2": false,
                "2-3": false,
                "3-500": false,
            },
        });

    const [charges, setCharges] = useState({
        "residential-society": {
            investor_rate: {
                "5Y": "",
            },
            hypersrot_rate: {
                per_KW: "",
            },
        },
        commercial: {
            investor_rate: {
                "5Y": "",
                "6Y": "",
                "7Y": "",
                "8Y": "",
                "9Y": "",
                "10Y": "",
            },
            hypersrot_rate: {
                per_KW: "",
            },
        },
    });
    const [chargesErrorState, setChargesErrorState] = useState({
        "residential-society": {
            investor_rate: {
                "5Y": false,
            },
            hypersrot_rate: {
                per_KW: false,
            },
        },
        commercial: {
            investor_rate: {
                "5Y": false,
                "6Y": false,
                "7Y": false,
                "8Y": false,
                "9Y": false,
                "10Y": false,
            },
            hypersrot_rate: {
                per_KW: false,
            },
        },
    });
    const [calculator, setCalculator] = useState({
        residential: {
            "0-100": "",
            "100-300": "",
            "300-500": "",
            "500-60000": "",
        },
    });

    const [calculatorError, setCalculatorError] = useState({
        residential: {
            "0-100": false,
            "100-300": false,
            "300-500": false,
            "500-60000": false,
        },
    });

    const [specifications, setSpecifications] = useState({
        solar_pv_modules: "",
        module_mounting_structure: "",
        inverter: "",
        monitoring_boxes: "",
        dc_power_cable: "",
        ac_power_cable: "",
        earthing_electrode: "",
        lighting_arrestor: "",
        connector: "",
        net_meter: "",
    });
    const [specificationsErrorState, setSpecificationsErrorState] = useState({
        solar_pv_modules: false,
        module_mounting_structure: false,
        inverter: false,
        monitoring_boxes: false,
        dc_power_cable: false,
        ac_power_cable: false,
        earthing_electrode: false,
        lighting_arrestor: false,
        connector: false,
        net_meter: false,
    });

    const [warranties, setWarranties] = useState({
        solar_pv_modules: "",
        module_power_output: "",
        inverter: "",
        rest_of_system: "",
    });
    const [warrantiesErrorState, setWarrantiesErrorState] = useState({
        solar_pv_modules: false,
        module_power_output: false,
        inverter: false,
        rest_of_system: false,
    });

    useEffect(() => {
        const getCurrentActiveMasterData = async () => {
            try {
                dispatch({ type: SET_LOADER });
                let response = await request.authGet({
                    endpoint: fetchData,
                });

                let data = "";
                if (onSubmit) {
                    const datas = response.data.proposal.data;

                    setDataFromSever(datas);
                    data = JSON.parse(datas);
                } else {
                    setDataFromSever(response.data.masterData.data);
                    data = JSON.parse(response.data.masterData.data);
                }

                setLoanAndPurchaseValue(
                    data.terms_and_conditions.loan_and_purchase
                );
                setCalculator(data?.calculator);
                setLeasingValue(data.terms_and_conditions.leasing);
                setOnboardingLeasingValue(data["onboarding-steps"].leasing);
                setPaymentPurchaseValue(data["payment-terms"].purchase);
                setPaymentLoanValue(data["payment-terms"].loan);

                setPricing(data.pricing);
                setGovernmentSubsidy(data.government_subsidy);
                setCharges(data.charges);
                setSpecifications(data.specification);
                setWarranties(data.warranties);
                dispatch({ type: RESET_LOADER });
            } catch (error) {
                toast.error(typeof error === "string" ? error : ERROR_MSG);
                dispatch({ type: RESET_LOADER });
            }
        };

        getCurrentActiveMasterData();
    }, [dispatch]);

    const handleWarrantiesInputChange = (path, value) => {
        const pathParts = path.split(".");
        let updatedData = { ...warranties };
        let updatedErrorState = { ...warrantiesErrorState };

        let currentPart = updatedData;
        let currentErrorPart = updatedErrorState;
        for (let i = 0; i < pathParts.length - 1; i++) {
            currentPart = currentPart[pathParts[i]];
            currentErrorPart = currentErrorPart[pathParts[i]];
        }

        const lastPart = pathParts[pathParts.length - 1].replace(
            /[\[\]"]+/g,
            ""
        );

        if (!value) {
            currentErrorPart[lastPart] = false;
        } else {
            currentErrorPart[lastPart] = false;
            currentPart[lastPart] = value;
        }

        setWarranties(updatedData);
        setWarrantiesErrorState(updatedErrorState);
    };

    const handleSpecificationsInputChange = (path, value) => {
        const pathParts = path.split(".");
        let updatedData = { ...specifications };
        let updatedErrorState = { ...specificationsErrorState };

        let currentPart = updatedData;
        let currentErrorPart = updatedErrorState;
        for (let i = 0; i < pathParts.length - 1; i++) {
            currentPart = currentPart[pathParts[i]];
            currentErrorPart = currentErrorPart[pathParts[i]];
        }

        const lastPart = pathParts[pathParts.length - 1].replace(
            /[\[\]"]+/g,
            ""
        );

        if (!value) {
            currentErrorPart[lastPart] = false;
        } else {
            currentErrorPart[lastPart] = false;
            currentPart[lastPart] = value;
        }

        setSpecifications(updatedData);
        setSpecificationsErrorState(updatedErrorState);
    };

    const handleSubmit = useCallback(
        async (e) => {
            e.preventDefault();

            try {
                const submitData = {
                    calculator: calculator,
                    pricing: pricing,
                    government_subsidy: governmentSubsidy,
                    charges: charges,
                    "payment-terms": {
                        purchase: paymentPurchaseValue,
                        loan: paymentLoanValue,
                    },
                    "onboarding-steps": { leasing: onboardingLeasingValue },
                    specification: specifications,
                    warranties: warranties,
                    terms_and_conditions: {
                        loan_and_purchase: loanAndPurchaseValue,
                        leasing: leasingValue,
                    },
                };

                const submitDataString = JSON.stringify(submitData);

                const dataObject = {
                    data: submitDataString,
                };
                if (onSubmit) {
                    if (submitDataString === dataFromSever) {
                        onSubmit();
                    } else {
                        onSubmit(submitDataString);
                    }
                } else {
                    if (submitDataString === dataFromSever) {
                        toast.error("Data is same and cannot be submitted");
                    } else {
                        setIsLoading(true);
                        await request.authPost({
                            endpoint: editData,
                            body: dataObject,
                        });
                        toast.success("Data submitted successfully");
                        setIsLoading(false);
                    }
                }
            } catch (error) {
                toast.error(typeof error === "string" ? error : ERROR_MSG);
                setIsLoading(false);
            }
        },
        [
            calculator,
            pricing,
            governmentSubsidy,
            charges,
            paymentPurchaseValue,
            paymentLoanValue,
            onboardingLeasingValue,
            specifications,
            warranties,
            onSubmit,
            loanAndPurchaseValue,
            leasingValue,
            dataFromSever,
            editData,
        ]
    );

    /* @DESC:: Will handle twice step nested input @habib610 Mon August 12,2024 */
    const handleTwiceStepInputChange = (path, value, setData, setError) => {
        const pathParts = path.split(".");
        setData((prev) => ({
            ...prev,
            [pathParts[0]]: {
                ...prev[[pathParts[0]]],
                [pathParts[1]]: value,
            },
        }));

        if (isNaN(value) || !value) {
            setError((prev) => ({
                ...prev,
                [pathParts[0]]: {
                    ...prev[[pathParts[0]]],
                    [pathParts[1]]: true,
                },
            }));
        } else {
            setError((prev) => ({
                ...prev,
                [pathParts[0]]: {
                    ...prev[[pathParts[0]]],
                    [pathParts[1]]: false,
                },
            }));
        }
    };

    const handleThriceStepNestedInput = (path, value, setData, setError) => {
        const pathParts = path.split(".");

        setData((prev) => ({
            ...prev,
            [pathParts[0]]: {
                ...prev[pathParts[0]],
                [pathParts[1]]: {
                    ...prev[pathParts[0]][[pathParts[1]]],
                    [pathParts[2]]: value,
                },
            },
        }));

        if (isNaN(value) || !value) {
            setError((prev) => ({
                ...prev,
                [pathParts[0]]: {
                    ...prev[pathParts[0]],
                    [pathParts[1]]: {
                        ...prev[pathParts[0]][[pathParts[1]]],
                        [pathParts[2]]: true,
                    },
                },
            }));
        } else {
            setError((prev) => ({
                ...prev,
                [pathParts[0]]: {
                    ...prev[pathParts[0]],
                    [pathParts[1]]: {
                        ...prev[pathParts[0]][[pathParts[1]]],
                        [pathParts[2]]: false,
                    },
                },
            }));
        }
    };

    return (
        <Section className={sectionClass}>
            <Container>
                {heading && (
                    <div xxs="100%" className="mb-2 ">
                        <H3>{heading}</H3>
                    </div>
                )}
                <div className="bg-gray-100 rounded-sm border-2 border-gray-200 p-1.6 xl:p-2.4">
                    <div className="bg-white p-1.6 xl:p-2.4 rounded-sm border-2 border-gray-140 border-dashed ">
                        <div className="flex justify-between flex-wrap  xl:gap-4">
                            <div className="w-full xl:flex-1">
                                <Accordion
                                    wrapperClass="border border-blue-400 shadow rounded"
                                    size={["s", "m", "m"]}
                                    weight="rg"
                                    isOpen={false}
                                    label={`Calculator`}
                                    isLine
                                    contentMargin="mt-0 pb-0"
                                >
                                    <Accordion
                                        size={["s", "m", "m"]}
                                        weight="rg"
                                        isOpen={false}
                                        label={`Residential`}
                                        isLine
                                        wrapperClass="pb-0"
                                        contentMargin="mt-0.5"
                                    >
                                        <DetailsField
                                            label={"0-100"}
                                            placeholder="Enter Residential 0-100"
                                            value={
                                                calculator?.residential["0-100"]
                                            }
                                            onChange={(event) =>
                                                handleTwiceStepInputChange(
                                                    "residential.0-100",
                                                    event.target.value,
                                                    setCalculator,
                                                    setCalculatorError
                                                )
                                            }
                                            errorMessage={
                                                calculatorError.residential[
                                                    "0-100"
                                                ]
                                                    ? "Enter a positive number for Calculator 0-100"
                                                    : null
                                            }
                                        />
                                        <DetailsField
                                            label={"100-300"}
                                            placeholder="Enter Residential 100-300"
                                            value={
                                                calculator?.residential[
                                                    "100-300"
                                                ]
                                            }
                                            onChange={(event) =>
                                                handleTwiceStepInputChange(
                                                    "residential.100-300",
                                                    event.target.value,
                                                    setCalculator,
                                                    setCalculatorError
                                                )
                                            }
                                            errorMessage={
                                                calculatorError.residential[
                                                    "100-300"
                                                ]
                                                    ? "Enter a positive number for Calculator 100-300"
                                                    : null
                                            }
                                        />
                                        <DetailsField
                                            label={"300-500"}
                                            placeholder="Enter Residential 300-500"
                                            value={
                                                calculator?.residential[
                                                    "300-500"
                                                ]
                                            }
                                            onChange={(event) =>
                                                handleTwiceStepInputChange(
                                                    "residential.300-500",
                                                    event.target.value,
                                                    setCalculator,
                                                    setCalculatorError
                                                )
                                            }
                                            errorMessage={
                                                calculatorError.residential[
                                                    "300-500"
                                                ]
                                                    ? "Enter a positive number for Calculator 300-500"
                                                    : null
                                            }
                                        />
                                        <DetailsField
                                            label={"500-60000"}
                                            placeholder="Enter Residential 500-60000"
                                            value={
                                                calculator?.residential[
                                                    "500-60000"
                                                ]
                                            }
                                            onChange={(event) =>
                                                handleTwiceStepInputChange(
                                                    "residential.500-60000",
                                                    event.target.value,
                                                    setCalculator,
                                                    setCalculatorError
                                                )
                                            }
                                            errorMessage={
                                                calculatorError.residential[
                                                    "500-60000"
                                                ]
                                                    ? "Enter a positive number for calculator 500-60000"
                                                    : null
                                            }
                                        />
                                    </Accordion>
                                </Accordion>
                                <Accordion
                                    wrapperClass="border border-blue-400 shadow rounded"
                                    size={["s", "m", "m"]}
                                    weight="rg"
                                    isOpen={false}
                                    label={`Onboarding steps`}
                                    isLine
                                    contentMargin="mt-0.5 pb-0"
                                >
                                    <TextEditor
                                        onClick={() =>
                                            setModalState({
                                                isOpen: true,
                                                mode: "onboardingLeasing",
                                            })
                                        }
                                        label="Leasing"
                                        html={onboardingLeasingValue}
                                    />
                                </Accordion>
                                <Accordion
                                    wrapperClass="border border-blue-400 shadow rounded "
                                    size={["s", "m", "m"]}
                                    weight="rg"
                                    isOpen={false}
                                    label={`Pricing`}
                                    isLine
                                    contentMargin="mt-0 pb-0 "
                                >
                                    <Accordion
                                        size={["s", "m", "m"]}
                                        weight="rg"
                                        isOpen={false}
                                        label={`Loan and Purchase`}
                                        isLine
                                        contentMargin="mt-0 pb-0"
                                        wrapperClass="mb-0 pb-0"
                                    >
                                        <Accordion
                                            size={["s", "m", "m"]}
                                            weight="rg"
                                            isOpen={false}
                                            label={`Subsidy`}
                                            isLine
                                            wrapperClass="mb-0 py-1"
                                            contentMargin="mt-0 pb-0 mb-0"
                                        >
                                            <DetailsField
                                                label={"0-5"}
                                                placeholder="Enter Subsidy 0-5"
                                                value={
                                                    pricing?.loan_and_purchase
                                                        ?.subsidy?.["0-5"]
                                                }
                                                onChange={(event) =>
                                                    handleThriceStepNestedInput(
                                                        "loan_and_purchase.subsidy.0-5",
                                                        event.target.value,
                                                        setPricing,
                                                        setPricingErrorState
                                                    )
                                                }
                                                errorMessage={
                                                    pricingErrorState
                                                        .loan_and_purchase
                                                        .subsidy["0-5"]
                                                        ? "Enter a positive integer for Subsidy 0-5"
                                                        : null
                                                }
                                            />
                                            <DetailsField
                                                label={"5-10"}
                                                placeholder="Enter Subsidy 5-10"
                                                errorMessage={
                                                    pricingErrorState
                                                        .loan_and_purchase
                                                        .subsidy["5-10"]
                                                        ? "Enter a positive integer for Subsidy 5-10"
                                                        : null
                                                }
                                                value={
                                                    pricing?.loan_and_purchase
                                                        ?.subsidy["5-10"]
                                                }
                                                onChange={(event) =>
                                                    handleThriceStepNestedInput(
                                                        "loan_and_purchase.subsidy.5-10",
                                                        event.target.value,
                                                        setPricing,
                                                        setPricingErrorState
                                                    )
                                                }
                                            />
                                            <DetailsField
                                                label={"10-25"}
                                                placeholder="Enter Subsidy 10-25"
                                                errorMessage={
                                                    pricingErrorState
                                                        .loan_and_purchase
                                                        .subsidy["10-25"]
                                                        ? "Enter a positive integer for Subsidy 10-25"
                                                        : null
                                                }
                                                value={
                                                    pricing?.loan_and_purchase
                                                        ?.subsidy["10-25"]
                                                }
                                                onChange={(event) =>
                                                    handleThriceStepNestedInput(
                                                        "loan_and_purchase.subsidy.10-25",
                                                        event.target.value,
                                                        setPricing,
                                                        setPricingErrorState
                                                    )
                                                }
                                            />
                                            <DetailsField
                                                label={"25-100"}
                                                placeholder="Enter Subsidy 25-100"
                                                errorMessage={
                                                    pricingErrorState
                                                        .loan_and_purchase
                                                        .subsidy["25-100"]
                                                        ? "Enter a positive integer for Subsidy 25-100"
                                                        : null
                                                }
                                                value={
                                                    pricing?.loan_and_purchase
                                                        ?.subsidy["25-100"]
                                                }
                                                onChange={(event) =>
                                                    handleThriceStepNestedInput(
                                                        "loan_and_purchase.subsidy.25-100",
                                                        event.target.value,
                                                        setPricing,
                                                        setPricingErrorState
                                                    )
                                                }
                                            />
                                            <DetailsField
                                                label={"100-500"}
                                                placeholder="Enter Subsidy 100-500"
                                                errorMessage={
                                                    pricingErrorState
                                                        .loan_and_purchase
                                                        .subsidy["100-500"]
                                                        ? "Enter a positive integer for Subsidy 100-500"
                                                        : null
                                                }
                                                value={
                                                    pricing?.loan_and_purchase
                                                        ?.subsidy["100-500"]
                                                }
                                                onChange={(event) =>
                                                    handleThriceStepNestedInput(
                                                        "loan_and_purchase.subsidy.100-500",
                                                        event.target.value,
                                                        setPricing,
                                                        setPricingErrorState
                                                    )
                                                }
                                            />
                                        </Accordion>
                                        <Accordion
                                            size={["s", "m", "m"]}
                                            weight="rg"
                                            isOpen={false}
                                            label={`Non Subsidy`}
                                            isLine
                                            contentMargin="mt-0.5 pb-0 mb-0"
                                            wrapperClass="my-0 py-0"
                                        >
                                            <DetailsField
                                                label={"0-5"}
                                                placeholder="Enter Non-Subsidy 0-5"
                                                errorMessage={
                                                    pricingErrorState
                                                        .loan_and_purchase[
                                                        "non-subsidy"
                                                    ]["0-5"]
                                                        ? "Enter a positive integer for Non-Subsidy 0-5"
                                                        : null
                                                }
                                                value={
                                                    pricing
                                                        ?.loan_and_purchase?.[
                                                        "non-subsidy"
                                                    ]?.["0-5"]
                                                }
                                                onChange={(event) =>
                                                    handleThriceStepNestedInput(
                                                        "loan_and_purchase.non-subsidy.0-5",
                                                        event.target.value,
                                                        setPricing,
                                                        setPricingErrorState
                                                    )
                                                }
                                            />
                                            <DetailsField
                                                label={"5-10"}
                                                placeholder="Enter Non-Subsidy 5-10"
                                                errorMessage={
                                                    pricingErrorState
                                                        .loan_and_purchase[
                                                        "non-subsidy"
                                                    ]["5-10"]
                                                        ? "Enter a positive integer for Non-Subsidy 5-10"
                                                        : null
                                                }
                                                value={
                                                    pricing
                                                        ?.loan_and_purchase?.[
                                                        "non-subsidy"
                                                    ]?.["5-10"]
                                                }
                                                onChange={(event) =>
                                                    handleThriceStepNestedInput(
                                                        "loan_and_purchase.non-subsidy.5-10",
                                                        event.target.value,
                                                        setPricing,
                                                        setPricingErrorState
                                                    )
                                                }
                                            />
                                            <DetailsField
                                                label={"10-25"}
                                                placeholder="Enter Non-Subsidy 10-25"
                                                errorMessage={
                                                    pricingErrorState
                                                        .loan_and_purchase[
                                                        "non-subsidy"
                                                    ]["10-25"]
                                                        ? "Enter a positive integer for Non-Subsidy 10-25"
                                                        : null
                                                }
                                                value={
                                                    pricing
                                                        ?.loan_and_purchase?.[
                                                        "non-subsidy"
                                                    ]?.["10-25"]
                                                }
                                                onChange={(event) =>
                                                    handleThriceStepNestedInput(
                                                        "loan_and_purchase.non-subsidy.10-25",
                                                        event.target.value,
                                                        setPricing,
                                                        setPricingErrorState
                                                    )
                                                }
                                            />
                                            <DetailsField
                                                label={"25-100"}
                                                placeholder="Enter Non-Subsidy 25-100"
                                                errorMessage={
                                                    pricingErrorState
                                                        .loan_and_purchase[
                                                        "non-subsidy"
                                                    ]["25-100"]
                                                        ? "Enter a positive integer for Non-Subsidy 25-100"
                                                        : null
                                                }
                                                value={
                                                    pricing
                                                        ?.loan_and_purchase?.[
                                                        "non-subsidy"
                                                    ]?.["25-100"]
                                                }
                                                onChange={(event) =>
                                                    handleThriceStepNestedInput(
                                                        "loan_and_purchase.non-subsidy.25-100",
                                                        event.target.value,
                                                        setPricing,
                                                        setPricingErrorState
                                                    )
                                                }
                                            />
                                            <DetailsField
                                                label={"100-500"}
                                                placeholder="Enter Non-Subsidy 100-500"
                                                errorMessage={
                                                    pricingErrorState
                                                        .loan_and_purchase[
                                                        "non-subsidy"
                                                    ]["100-500"]
                                                        ? "Enter a positive integer for Non-Subsidy 100-500"
                                                        : null
                                                }
                                                value={
                                                    pricing
                                                        ?.loan_and_purchase?.[
                                                        "non-subsidy"
                                                    ]?.["100-500"]
                                                }
                                                onChange={(event) =>
                                                    handleThriceStepNestedInput(
                                                        "loan_and_purchase.non-subsidy.100-500",
                                                        event.target.value,
                                                        setPricing,
                                                        setPricingErrorState
                                                    )
                                                }
                                            />
                                        </Accordion>
                                    </Accordion>
                                    <Accordion
                                        size={["s", "m", "m"]}
                                        weight="rg"
                                        isOpen={false}
                                        label={`Leasing`}
                                        isLine
                                        wrapperClass="mb-0 pb-0"
                                        contentMargin="mt-0 mb-0"
                                    >
                                        <Accordion
                                            size={["s", "m", "m"]}
                                            weight="rg"
                                            isOpen={false}
                                            label={`Subsidy`}
                                            isLine
                                        >
                                            <DetailsField
                                                label={"25-100"}
                                                placeholder="Enter Subsidy 25-100"
                                                errorMessage={
                                                    pricingErrorState.leasing
                                                        .subsidy["25-100"]
                                                        ? "Enter a positive integer for Subsidy 25-100"
                                                        : null
                                                }
                                                value={
                                                    pricing?.leasing?.subsidy[
                                                        "25-100"
                                                    ]
                                                }
                                                onChange={(event) =>
                                                    handleThriceStepNestedInput(
                                                        "leasing.subsidy.25-100",
                                                        event.target.value,
                                                        setPricing,
                                                        setPricingErrorState
                                                    )
                                                }
                                            />
                                            <DetailsField
                                                label={"100-500"}
                                                placeholder="Enter Subsidy 100-500"
                                                errorMessage={
                                                    pricingErrorState.leasing
                                                        .subsidy["100-500"]
                                                        ? "Enter a positive integer for Subsidy 100-500"
                                                        : null
                                                }
                                                value={
                                                    pricing?.leasing?.subsidy[
                                                        "100-500"
                                                    ]
                                                }
                                                onChange={(event) =>
                                                    handleThriceStepNestedInput(
                                                        "leasing.subsidy.100-500",
                                                        event.target.value,
                                                        setPricing,
                                                        setPricingErrorState
                                                    )
                                                }
                                            />
                                        </Accordion>
                                    </Accordion>
                                </Accordion>
                                <Accordion
                                    wrapperClass="border border-blue-400 shadow rounded"
                                    size={["s", "m", "m"]}
                                    weight="rg"
                                    isOpen={false}
                                    label={`Specification`}
                                    isLine
                                    contentMargin="mt-0.5"
                                >
                                    <DetailsField
                                        label={"solar_pv_modules"}
                                        placeholder="Enter Specification Solar PV"
                                        errorMessage={
                                            specificationsErrorState.solar_pv_modules
                                                ? "Enter Specification Solar PV"
                                                : null
                                        }
                                        value={specifications.solar_pv_modules}
                                        onChange={(event) =>
                                            handleSpecificationsInputChange(
                                                "solar_pv_modules",
                                                event.target.value
                                            )
                                        }
                                    />
                                    <DetailsField
                                        label={"module_mounting_structure"}
                                        placeholder="Enter Specification Module"
                                        errorMessage={
                                            specificationsErrorState.module_mounting_structure
                                                ? "Enter Specification Module"
                                                : null
                                        }
                                        value={
                                            specifications.module_mounting_structure
                                        }
                                        onChange={(event) =>
                                            handleSpecificationsInputChange(
                                                "module_mounting_structure",
                                                event.target.value
                                            )
                                        }
                                    />
                                    <DetailsField
                                        label={"inverter"}
                                        placeholder="Enter Specification Inverter"
                                        errorMessage={
                                            specificationsErrorState.inverter
                                                ? "Enter Specification Inverter"
                                                : null
                                        }
                                        value={specifications.inverter}
                                        onChange={(event) =>
                                            handleSpecificationsInputChange(
                                                "inverter",
                                                event.target.value
                                            )
                                        }
                                    />
                                    <DetailsField
                                        label={"monitoring_boxes"}
                                        placeholder="Enter Specification Monitoring boxes"
                                        errorMessage={
                                            specificationsErrorState.monitoring_boxes
                                                ? "Enter Specification Monitoring boxes"
                                                : null
                                        }
                                        value={specifications.monitoring_boxes}
                                        onChange={(event) =>
                                            handleSpecificationsInputChange(
                                                "monitoring_boxes",
                                                event.target.value
                                            )
                                        }
                                    />
                                    <DetailsField
                                        label={"dc_power_cable"}
                                        placeholder="Enter Specification DC Power Cable"
                                        errorMessage={
                                            specificationsErrorState.dc_power_cable
                                                ? "Enter Specification DC Power Cable"
                                                : null
                                        }
                                        value={specifications.dc_power_cable}
                                        onChange={(event) =>
                                            handleSpecificationsInputChange(
                                                "dc_power_cable",
                                                event.target.value
                                            )
                                        }
                                    />
                                    <DetailsField
                                        label={"ac_power_cable"}
                                        placeholder="Enter Specification AC Power Cable"
                                        errorMessage={
                                            specificationsErrorState.ac_power_cable
                                                ? "Enter Specification AC Power Cable"
                                                : null
                                        }
                                        value={specifications.ac_power_cable}
                                        onChange={(event) =>
                                            handleSpecificationsInputChange(
                                                "ac_power_cable",
                                                event.target.value
                                            )
                                        }
                                    />
                                    <DetailsField
                                        label={"earthing_electrode"}
                                        placeholder="Enter Specification Earthing Electrode"
                                        errorMessage={
                                            specificationsErrorState.earthing_electrode
                                                ? "Enter Specification Earthing Electrode"
                                                : null
                                        }
                                        value={
                                            specifications.earthing_electrode
                                        }
                                        onChange={(event) =>
                                            handleSpecificationsInputChange(
                                                "earthing_electrode",
                                                event.target.value
                                            )
                                        }
                                    />
                                    <DetailsField
                                        label={"lighting_arrestor"}
                                        placeholder="Enter Specification Lighting Arrestor"
                                        errorMessage={
                                            specificationsErrorState.lighting_arrestor
                                                ? "Enter Specification Lighting Arrestor"
                                                : null
                                        }
                                        value={specifications.lighting_arrestor}
                                        onChange={(event) =>
                                            handleSpecificationsInputChange(
                                                "lighting_arrestor",
                                                event.target.value
                                            )
                                        }
                                    />
                                    <DetailsField
                                        label={"connector"}
                                        placeholder="Enter Specification Connector"
                                        errorMessage={
                                            specificationsErrorState.connector
                                                ? "Enter Specification Connector"
                                                : null
                                        }
                                        value={specifications.connector}
                                        onChange={(event) =>
                                            handleSpecificationsInputChange(
                                                "connector",
                                                event.target.value
                                            )
                                        }
                                    />
                                    <DetailsField
                                        label={"net_meter"}
                                        placeholder="Enter Specification Net Meter"
                                        errorMessage={
                                            specificationsErrorState.net_meter
                                                ? "Enter Specification Net Meter"
                                                : null
                                        }
                                        value={specifications.net_meter}
                                        onChange={(event) =>
                                            handleSpecificationsInputChange(
                                                "net_meter",
                                                event.target.value
                                            )
                                        }
                                    />
                                </Accordion>
                                <Accordion
                                    wrapperClass="border border-blue-400 shadow rounded "
                                    size={["s", "m", "m"]}
                                    weight="rg"
                                    isOpen={false}
                                    label={`Government Subsidy`}
                                    isLine
                                    contentMargin="mt-0 pb-0"
                                >
                                    <Accordion
                                        size={["s", "m", "m"]}
                                        weight="rg"
                                        isOpen={false}
                                        label={`Residential Society`}
                                        isLine
                                        wrapperClass="pb-0 mb-0"
                                        contentMargin="mt-0.5 pb-0"
                                    >
                                        <DetailsField
                                            label={"0-500"}
                                            placeholder="Enter Residential Society 0-500"
                                            errorMessage={
                                                governmentSubsidyErrorState[
                                                    "residential-society"
                                                ]["0-500"]
                                                    ? "Enter a positive integer for Residential Society 0-500"
                                                    : null
                                            }
                                            value={
                                                governmentSubsidy[
                                                    "residential-society"
                                                ]["0-500"]
                                            }
                                            onChange={(event) =>
                                                handleTwiceStepInputChange(
                                                    "residential-society.0-500",
                                                    event.target.value,
                                                    setGovernmentSubsidy,
                                                    setGovernmentSubsidyErrorState
                                                )
                                            }
                                        />
                                    </Accordion>
                                    <Accordion
                                        size={["s", "m", "m"]}
                                        weight="rg"
                                        isOpen={false}
                                        label={`Individual Homes`}
                                        isLine
                                        wrapperClass="pb-0"
                                        contentMargin="mt-0.5 pb-0"
                                    >
                                        <DetailsField
                                            label={"0-2"}
                                            placeholder="Enter Individual Homes 0-2"
                                            errorMessage={
                                                governmentSubsidyErrorState[
                                                    "individual-homes"
                                                ]["0-2"]
                                                    ? "Enter a positive integer for Individual Homes 0-2"
                                                    : null
                                            }
                                            value={
                                                governmentSubsidy[
                                                    "individual-homes"
                                                ]["0-2"]
                                            }
                                            onChange={(event) =>
                                                handleTwiceStepInputChange(
                                                    "individual-homes.0-2",
                                                    event.target.value,
                                                    setGovernmentSubsidy,
                                                    setGovernmentSubsidyErrorState
                                                )
                                            }
                                        />
                                        <DetailsField
                                            label={"2-3"}
                                            placeholder="Enter Individual Homes 2-3"
                                            errorMessage={
                                                governmentSubsidyErrorState[
                                                    "individual-homes"
                                                ]["2-3"]
                                                    ? "Enter a positive integer for Individual Homes 2-3"
                                                    : null
                                            }
                                            value={
                                                governmentSubsidy[
                                                    "individual-homes"
                                                ]["2-3"]
                                            }
                                            onChange={(event) =>
                                                handleTwiceStepInputChange(
                                                    "individual-homes.2-3",
                                                    event.target.value,
                                                    setGovernmentSubsidy,
                                                    setGovernmentSubsidyErrorState
                                                )
                                            }
                                        />
                                        <DetailsField
                                            label={"3-500"}
                                            placeholder="Enter Individual Homes 3-500"
                                            errorMessage={
                                                governmentSubsidyErrorState[
                                                    "individual-homes"
                                                ]["3-500"]
                                                    ? "Enter a positive integer for Individual Homes 3-500"
                                                    : null
                                            }
                                            value={
                                                governmentSubsidy[
                                                    "individual-homes"
                                                ]["3-500"]
                                            }
                                            onChange={(event) =>
                                                handleTwiceStepInputChange(
                                                    "individual-homes.3-500",
                                                    event.target.value,
                                                    setGovernmentSubsidy,
                                                    setGovernmentSubsidyErrorState
                                                )
                                            }
                                        />
                                    </Accordion>
                                </Accordion>
                            </div>
                            <div className="w-full xl:flex-1">
                                <Accordion
                                    wrapperClass="border border-blue-400 shadow rounded"
                                    size={["s", "m", "m"]}
                                    weight="rg"
                                    isOpen={false}
                                    label={`Warranties`}
                                    isLine
                                    contentMargin="mt-0.5 pb-0"
                                >
                                    <DetailsField
                                        style={{
                                            width: "100%",
                                        }}
                                        label={"solar_pv_modules"}
                                        placeholder="Enter Warranties Solar PV"
                                        errorMessage={
                                            warrantiesErrorState.solar_pv_modules
                                                ? "Enter Warranties Solar PV"
                                                : null
                                        }
                                        value={warranties.solar_pv_modules}
                                        onChange={(event) =>
                                            handleWarrantiesInputChange(
                                                "solar_pv_modules",
                                                event.target.value
                                            )
                                        }
                                    />
                                    <DetailsField
                                        style={{
                                            width: "100%",
                                        }}
                                        label={"module_power_output"}
                                        placeholder="Enter Warranties Module Power Output"
                                        errorMessage={
                                            warrantiesErrorState.module_power_output
                                                ? "Enter Warranties Module Power Output"
                                                : null
                                        }
                                        value={warranties.module_power_output}
                                        onChange={(event) =>
                                            handleWarrantiesInputChange(
                                                "module_power_output",
                                                event.target.value
                                            )
                                        }
                                    />
                                    <DetailsField
                                        style={{
                                            width: "100%",
                                        }}
                                        label={"inverter"}
                                        placeholder="Enter Warranties Inverter"
                                        errorMessage={
                                            warrantiesErrorState.inverter
                                                ? "Enter Warranties Inverter"
                                                : null
                                        }
                                        value={warranties.inverter}
                                        onChange={(event) =>
                                            handleWarrantiesInputChange(
                                                "inverter",
                                                event.target.value
                                            )
                                        }
                                    />
                                    <DetailsField
                                        style={{
                                            width: "100%",
                                        }}
                                        label={"rest_of_system"}
                                        placeholder="Enter Warranties Rest Of System"
                                        errorMessage={
                                            warrantiesErrorState.rest_of_system
                                                ? "Enter Warranties Rest Of System"
                                                : null
                                        }
                                        value={warranties.rest_of_system}
                                        onChange={(event) =>
                                            handleWarrantiesInputChange(
                                                "rest_of_system",
                                                event.target.value
                                            )
                                        }
                                    />
                                </Accordion>
                                <Accordion
                                    wrapperClass="border border-blue-400 shadow rounded"
                                    size={["s", "m", "m"]}
                                    weight="rg"
                                    isOpen={false}
                                    label={`Charges`}
                                    isLine
                                    contentMargin="mt-0 pb-0"
                                >
                                    <Accordion
                                        size={["s", "m", "m"]}
                                        weight="rg"
                                        isOpen={false}
                                        label={`Residential Society`}
                                        isLine
                                        contentMargin="pb-0 mt-0 "
                                        wrapperClass="pb-0 mb-0"
                                    >
                                        <Accordion
                                            size={["s", "m", "m"]}
                                            weight="rg"
                                            isOpen={false}
                                            label={`Investor Rate`}
                                            isLine
                                            wrapperClass="pb-0 mb-0"
                                            contentMargin="mt-0.5 pb-0"
                                        >
                                            <DetailsField
                                                label={"5Y"}
                                                placeholder="Enter Investor Rate 5Y"
                                                errorMessage={
                                                    chargesErrorState[
                                                        "residential-society"
                                                    ].investor_rate["5Y"]
                                                        ? "Enter a positive number for Investor Rate 5Y"
                                                        : null
                                                }
                                                value={
                                                    charges[
                                                        "residential-society"
                                                    ].investor_rate["5Y"]
                                                }
                                                onChange={(event) =>
                                                    handleThriceStepNestedInput(
                                                        "residential-society.investor_rate.5Y",
                                                        event.target.value,
                                                        setCharges,
                                                        setChargesErrorState
                                                    )
                                                }
                                            />
                                        </Accordion>
                                        <Accordion
                                            size={["s", "m", "m"]}
                                            weight="rg"
                                            isOpen={false}
                                            label={`Hypersrot Rate`}
                                            isLine
                                            contentMargin="mt-0.5 pb-0.5"
                                            wrapperClass="mb-0 pb-0"
                                        >
                                            <DetailsField
                                                label={"Per KW"}
                                                placeholder="Enter Hypersrot Rate Per KW"
                                                errorMessage={
                                                    chargesErrorState[
                                                        "residential-society"
                                                    ].hypersrot_rate.per_KW
                                                        ? "Enter a positive number for Hypersrot Rate Per KW"
                                                        : null
                                                }
                                                value={
                                                    charges[
                                                        "residential-society"
                                                    ].hypersrot_rate.per_KW
                                                }
                                                onChange={(event) =>
                                                    handleThriceStepNestedInput(
                                                        "residential-society.hypersrot_rate.per_KW",
                                                        event.target.value,
                                                        setCharges,
                                                        setChargesErrorState
                                                    )
                                                }
                                            />
                                        </Accordion>
                                    </Accordion>
                                    <Accordion
                                        size={["s", "m", "m"]}
                                        weight="rg"
                                        isOpen={false}
                                        label={`Commercial`}
                                        isLine
                                        wrapperClass="pb-0 mb-0"
                                        contentMargin="pb-0 mt-0"
                                    >
                                        <Accordion
                                            size={["s", "m", "m"]}
                                            weight="rg"
                                            isOpen={false}
                                            label={`Investor Rate`}
                                            isLine
                                            contentMargin="mt-0.5 pb-0"
                                            wrapperClass="mb-0 pb-0"
                                        >
                                            <DetailsField
                                                label={"5Y"}
                                                placeholder="Enter Investor Rate 5Y"
                                                errorMessage={
                                                    chargesErrorState.commercial
                                                        .investor_rate["5Y"]
                                                        ? "Enter a positive number for Investor Rate 5Y"
                                                        : null
                                                }
                                                value={
                                                    charges.commercial
                                                        .investor_rate["5Y"]
                                                }
                                                onChange={(event) =>
                                                    handleThriceStepNestedInput(
                                                        "commercial.investor_rate.5Y",
                                                        event.target.value,
                                                        setCharges,
                                                        setChargesErrorState
                                                    )
                                                }
                                            />
                                            <DetailsField
                                                label={"6Y"}
                                                placeholder="Enter Investor Rate 6Y"
                                                errorMessage={
                                                    chargesErrorState.commercial
                                                        .investor_rate["6Y"]
                                                        ? "Enter a positive number for Investor Rate 6Y"
                                                        : null
                                                }
                                                value={
                                                    charges.commercial
                                                        .investor_rate["6Y"]
                                                }
                                                onChange={(event) =>
                                                    handleThriceStepNestedInput(
                                                        "commercial.investor_rate.6Y",
                                                        event.target.value,
                                                        setCharges,
                                                        setChargesErrorState
                                                    )
                                                }
                                            />
                                            <DetailsField
                                                label={"7Y"}
                                                placeholder="Enter Investor Rate 7Y"
                                                errorMessage={
                                                    chargesErrorState.commercial
                                                        .investor_rate["7Y"]
                                                        ? "Enter a positive number for Investor Rate 7Y"
                                                        : null
                                                }
                                                value={
                                                    charges.commercial
                                                        .investor_rate["7Y"]
                                                }
                                                onChange={(event) =>
                                                    handleThriceStepNestedInput(
                                                        "commercial.investor_rate.7Y",
                                                        event.target.value,
                                                        setCharges,
                                                        setChargesErrorState
                                                    )
                                                }
                                            />
                                            <DetailsField
                                                label={"8Y"}
                                                placeholder="Enter Investor Rate 8Y"
                                                errorMessage={
                                                    chargesErrorState.commercial
                                                        .investor_rate["8Y"]
                                                        ? "Enter a positive number for Investor Rate 8Y"
                                                        : null
                                                }
                                                value={
                                                    charges.commercial
                                                        .investor_rate["8Y"]
                                                }
                                                onChange={(event) =>
                                                    handleThriceStepNestedInput(
                                                        "commercial.investor_rate.8Y",
                                                        event.target.value,
                                                        setCharges,
                                                        setChargesErrorState
                                                    )
                                                }
                                            />
                                            <DetailsField
                                                label={"9Y"}
                                                placeholder="Enter Investor Rate 9Y"
                                                errorMessage={
                                                    chargesErrorState.commercial
                                                        .investor_rate["9Y"]
                                                        ? "Enter a positive number for Investor Rate 9Y"
                                                        : null
                                                }
                                                value={
                                                    charges.commercial
                                                        .investor_rate["9Y"]
                                                }
                                                onChange={(event) =>
                                                    handleThriceStepNestedInput(
                                                        "commercial.investor_rate.9Y",
                                                        event.target.value,
                                                        setCharges,
                                                        setChargesErrorState
                                                    )
                                                }
                                            />
                                            <DetailsField
                                                label={"10Y"}
                                                placeholder="Enter Investor Rate 10Y"
                                                errorMessage={
                                                    chargesErrorState.commercial
                                                        .investor_rate["10Y"]
                                                        ? "Enter a positive number for Investor Rate 10Y"
                                                        : null
                                                }
                                                value={
                                                    charges.commercial
                                                        .investor_rate["10Y"]
                                                }
                                                onChange={(event) =>
                                                    handleThriceStepNestedInput(
                                                        "commercial.investor_rate.10Y",
                                                        event.target.value,
                                                        setCharges,
                                                        setChargesErrorState
                                                    )
                                                }
                                            />
                                        </Accordion>
                                        <Accordion
                                            size={["s", "m", "m"]}
                                            weight="rg"
                                            isOpen={false}
                                            label={`Hypersrot Rate`}
                                            isLine
                                            contentMargin="mt-0.5 pb-0"
                                            wrapperClass="mb-0 pb-0"
                                        >
                                            <DetailsField
                                                label={"Per KW"}
                                                placeholder="Enter Hypersrot Rate Per KW"
                                                errorMessage={
                                                    chargesErrorState.commercial
                                                        .hypersrot_rate.per_KW
                                                        ? "Enter a positive number for Hypersrot Rate Per KW"
                                                        : null
                                                }
                                                value={
                                                    charges.commercial
                                                        .hypersrot_rate.per_KW
                                                }
                                                onChange={(event) =>
                                                    handleThriceStepNestedInput(
                                                        "commercial.hypersrot_rate.per_KW",
                                                        event.target.value,
                                                        setCharges,
                                                        setChargesErrorState
                                                    )
                                                }
                                            />
                                        </Accordion>
                                    </Accordion>
                                </Accordion>
                                <Accordion
                                    wrapperClass="border border-blue-400 shadow rounded"
                                    size={["s", "m", "m"]}
                                    weight="rg"
                                    isOpen={false}
                                    label={`Terms and Conditions`}
                                    isLine
                                    contentMargin="mt-0.5"
                                >
                                    <TextEditor
                                        onClick={() =>
                                            setModalState({
                                                isOpen: true,
                                                mode: "loanAndPurchase",
                                            })
                                        }
                                        label="Loan and Purchases"
                                        html={loanAndPurchaseValue}
                                    />
                                    <TextEditor
                                        onClick={() =>
                                            setModalState({
                                                isOpen: true,
                                                mode: "leasing",
                                            })
                                        }
                                        label="leasing"
                                        html={leasingValue}
                                    />
                                </Accordion>
                                <Accordion
                                    wrapperClass="border border-blue-400 shadow rounded"
                                    size={["s", "m", "m"]}
                                    weight="rg"
                                    isOpen={false}
                                    label={`Payment Terms`}
                                    isLine
                                    contentMargin="mt-0.5"
                                >
                                    <TextEditor
                                        label="Purchase"
                                        onClick={() =>
                                            setModalState({
                                                isOpen: true,
                                                mode: "paymentPurchase",
                                            })
                                        }
                                        html={paymentPurchaseValue}
                                    />

                                    <TextEditor
                                        onClick={() =>
                                            setModalState({
                                                isOpen: true,
                                                mode: "paymentLoan",
                                            })
                                        }
                                        label="Loan"
                                        html={paymentLoanValue}
                                    />
                                </Accordion>
                            </div>
                        </div>

                        <div className=" w-full flex justify-center">
                            <Button
                                className="w-[48%] sm:w-[40%] xl:w-[30%]"
                                onClick={handleSubmit}
                                isLoading={isLoading}
                                disabled={isLoading}
                            >
                                Submit
                            </Button>
                        </div>
                    </div>
                </div>
            </Container>

            <ModalAlt
                times={false}
                innerTimes={false}
                isOpen={
                    modalState.isOpen && modalState.mode === "loanAndPurchase"
                }
                onClose={() => setModalState({ ...modalState, isOpen: false })}
                width={["100%", "100%", "50%"]}
                topPadding="0px"
                backgroundColor="white"
            >
                <H2>Loan and Purchase</H2>
                <Space xxsm={["20px 0  40px"]}>
                    <Flex.Item>
                        <ReactQuill
                            theme="snow"
                            value={loanAndPurchaseValue}
                            onChange={setLoanAndPurchaseValue}
                            style={{ height: "300px" }}
                        />
                    </Flex.Item>
                </Space>
                <Space xxsm={["50px 0 0"]}>
                    <Flex.Item>
                        <Button
                            color="white"
                            type="solid"
                            backgroundColor="primary"
                            onClick={() =>
                                setModalState({ ...modalState, isOpen: false })
                            }
                        >
                            Submit
                        </Button>
                    </Flex.Item>
                </Space>
            </ModalAlt>
            <ModalAlt
                times={false}
                innerTimes={false}
                isOpen={modalState.isOpen && modalState.mode === "leasing"}
                onClose={() => setModalState({ ...modalState, isOpen: false })}
                width={["100%", "100%", "50%"]}
                topPadding="0px"
                backgroundColor="white"
            >
                <H2>Leasing</H2>
                <Space xxsm={["20px 0  40px"]}>
                    <Flex.Item>
                        <ReactQuill
                            theme="snow"
                            value={leasingValue}
                            onChange={setLeasingValue}
                            style={{ height: "300px" }}
                        />
                    </Flex.Item>
                </Space>
                <Space xxsm={["50px 0 0"]}>
                    <Flex.Item>
                        <Button
                            color="white"
                            type="solid"
                            backgroundColor="primary"
                            onClick={() =>
                                setModalState({ ...modalState, isOpen: false })
                            }
                        >
                            Submit
                        </Button>
                    </Flex.Item>
                </Space>
            </ModalAlt>
            <ModalAlt
                times={false}
                innerTimes={false}
                isOpen={
                    modalState.isOpen && modalState.mode === "onboardingLeasing"
                }
                onClose={() => setModalState({ ...modalState, isOpen: false })}
                width={["100%", "100%", "50%"]}
                topPadding="0px"
                backgroundColor="white"
            >
                <H2>Leasing</H2>
                <Space xxsm={["20px 0  40px"]}>
                    <Flex.Item>
                        <ReactQuill
                            theme="snow"
                            value={onboardingLeasingValue}
                            onChange={setOnboardingLeasingValue}
                            style={{ height: "300px" }}
                        />
                    </Flex.Item>
                </Space>
                <Space xxsm={["50px 0 0"]}>
                    <Flex.Item>
                        <Button
                            color="white"
                            type="solid"
                            backgroundColor="primary"
                            onClick={() =>
                                setModalState({ ...modalState, isOpen: false })
                            }
                        >
                            Submit
                        </Button>
                    </Flex.Item>
                </Space>
            </ModalAlt>
            <ModalAlt
                times={false}
                innerTimes={false}
                isOpen={
                    modalState.isOpen && modalState.mode === "paymentPurchase"
                }
                onClose={() => setModalState({ ...modalState, isOpen: false })}
                width={["100%", "100%", "50%"]}
                topPadding="0px"
                backgroundColor="white"
            >
                <H2>Payment Purchase</H2>
                <Space xxsm={["20px 0  40px"]}>
                    <Flex.Item>
                        <ReactQuill
                            theme="snow"
                            value={paymentPurchaseValue}
                            onChange={setPaymentPurchaseValue}
                            style={{ height: "300px" }}
                        />
                    </Flex.Item>
                </Space>
                <Space xxsm={["50px 0 0"]}>
                    <Flex.Item>
                        <Button
                            color="white"
                            type="solid"
                            backgroundColor="primary"
                            onClick={() =>
                                setModalState({ ...modalState, isOpen: false })
                            }
                        >
                            Submit
                        </Button>
                    </Flex.Item>
                </Space>
            </ModalAlt>
            <ModalAlt
                times={false}
                innerTimes={false}
                isOpen={modalState.isOpen && modalState.mode === "paymentLoan"}
                onClose={() => setModalState({ ...modalState, isOpen: false })}
                width={["100%", "100%", "50%"]}
                topPadding="0px"
                backgroundColor="white"
            >
                <H2>Payment Loan</H2>
                <Space xxsm={["20px 0  40px"]}>
                    <Flex.Item>
                        <ReactQuill
                            theme="snow"
                            value={paymentLoanValue}
                            onChange={setPaymentLoanValue}
                            style={{ height: "300px" }}
                        />
                    </Flex.Item>
                </Space>
                <Space xxsm={["50px 0 0"]}>
                    <Flex.Item>
                        <Button
                            color="white"
                            type="solid"
                            backgroundColor="primary"
                            onClick={() =>
                                setModalState({ ...modalState, isOpen: false })
                            }
                        >
                            Submit
                        </Button>
                    </Flex.Item>
                </Space>
            </ModalAlt>
        </Section>
    );
};

export default WrapperMaster;
const EditorWrapper = styled.div`
    position: relative;
    .edit {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0.5rem;
        font-size: 14px;
        line-height: 1;
        min-height: 20px;
        ${Pa} {
            margin-left: 0.5rem;
        }
    }
    .text-editor {
        min-height: 50px;
        overflow-y: auto;
        margin-bottom: 1rem;
        max-height: 100px;
        padding: 1rem;
        border: solid 2px
            ${(props) =>
                props.disabled
                    ? props.theme.color.grey300
                    : props.isError
                    ? props.theme.color.error
                    : props.theme.color.lightGrey};
        border-radius: 0.5rem;
        li {
            font-size: ${(props) => props.theme.fontSize.default.s};
            margin-left: 20px;
            font-size: ${(props) => props.theme.fontSize.default.s};
        }
        ul li {
            list-style: disc;
        }
        ol li {
            list-style: decimal;
        }
    }
`;
const TextEditor = ({ label, html, onClick }) => {
    return (
        <Flex.Container justify="space-between" align="center">
            <Flex.Item flex="1">
                <P>{label}</P>
            </Flex.Item>
            <Flex.Item xxs="100%" st="100%" t="50%" lg="60%">
                <EditorWrapper>
                    <div
                        className="text-editor"
                        onClick={onClick}
                        dangerouslySetInnerHTML={{
                            __html: html,
                        }}
                    ></div>

                    <Button onClick={onClick} className="edit">
                        Edit <Icon color="white" component={FaEdit} />
                    </Button>
                </EditorWrapper>
            </Flex.Item>
        </Flex.Container>
    );
};
const DetailsField = ({
    label,
    onChange,
    isSelect,
    selected,
    data,
    componentType = "input",
    maxLength,
    value,
    ...rest
}) => {
    return (
        <Space lgm={[" 0 0 25px "]} tm={["0 0 20px"]} xxsm={["0 0 10px"]}>
            <Flex.Container justify="space-between" align="center">
                <Flex.Item xxs="100%" st="100%" t="40%" lg="35%">
                    <P className="text-wrap break-words  xl:text-sm 2xl:text-base">
                        {label}
                    </P>
                </Flex.Item>
                <Space tm={[".1rem 0 0 "]}>
                    <Flex.Item xxs="100%" st="100%" t="50%" lg="60%">
                        {isSelect ? (
                            <DropDown
                                data={data}
                                onChange={(value) => onChange(value)}
                                selected={selected}
                                {...rest}
                            />
                        ) : (
                            <Input
                                onChange={onChange}
                                backgroundColor="white"
                                shadow={false}
                                componentType={componentType}
                                maxLength={maxLength}
                                value={value}
                                style={{
                                    paddingBottom: "7px",
                                    paddingTop: "7px",
                                }}
                                {...rest}
                            />
                        )}
                    </Flex.Item>
                </Space>
            </Flex.Container>
        </Space>
    );
};
const Overlay = styled.div`
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 115;
    padding: 75px 0;
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        padding: ${(props) => props.topPadding || "100px"} 0;
    }
`;
const ContainerModal = styled.div`
    width: 95%;
    @media (min-width: ${(props) => props.theme.breakPoints.smallMobile}) {
        width: 84%;
    }
    margin-inline: auto;
    box-sizing: border-box;
`;
export const CloseIcon = styled.div`
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: ${(props) => props.theme.fontSize.mobile.m};
    cursor: pointer;
    color: ${(props) =>
        props.color ? props.theme.color[props.color] : props.theme.color.white};
    @media (min-width: ${(props) => props.theme.breakPoints.tablet}) {
        font-size: ${(props) => props.theme.fontSize.tablet.m};
    }
`;
const FlexItem = styled(Flex.Item)`
    width: ${(props) => props.width[0]};
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        width: ${(props) => props.width[1]};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.laptop}) {
        width: ${(props) => props.width[2]};
    }
`;

const Content = styled.div`
    background-color: ${(props) => props.theme.color[props.backgroundColor]};
    padding: ${(props) => props.xxsp};
    border-radius: 10px;
    position: relative;
    overflow-x: ${(props) => props.overflowx};
    overflow-y: ${(props) => props.overflowy};
    max-height: 76vh;
    .innerTimes {
        position: absolute;
        top: 10px;
        right: 10px;
    }
    @media (min-width: ${(props) => props.theme.breakPoints.mobile}) {
        padding: ${(props) => props.xsp};
    }
    @media (min-width: ${(props) => props.theme.breakPoints.smallTablet}) {
        padding: ${(props) => props.stp};
        .innerTimes {
            top: 15px;
            right: 15px;
        }
    }
    @media (min-width: ${(props) => props.theme.breakPoints.large}) {
        padding: ${(props) => props.xlp};
    }
`;
const ModalAlt = ({
    isOpen,
    title,
    onClose,
    times,
    innerTimes = false,
    children,
    backgroundColor = "white",
    bgTheme = "light",
    width = ["100%", "100%", "60%"],
    topPadding,
    overflowx = "hidden",
    overflowy = "auto",
    xxsp = "2rem 1rem",
    xsp = "2rem 1.8rem",
    stp = "2.5rem 2rem",
    xlp = "2.5rem 2rem",
}) => {
    return isOpen ? (
        <AnimatePresence>
            <Overlay topPadding={topPadding}>
                <ContainerModal>
                    <Flex.Container justify="center">
                        <FlexItem width={width}>
                            <ModalTitle
                                times={times}
                                onClick={onClose}
                                title={title}
                            />
                            <Content
                                as={motion.div}
                                backgroundColor={backgroundColor}
                                layout
                                initial={{ scale: 0.8, opacity: 0, y: -1000 }}
                                animate={{ scale: 1, opacity: 1, y: 0 }}
                                exit={{ scale: 0.8, opacity: 0, y: 1000 }}
                                transition={{ type: "spring", duration: 0.5 }}
                                overflowx={overflowx}
                                overflowy={overflowy}
                                xxsp={xxsp}
                                xsp={xsp}
                                stp={stp}
                                xlp={xlp}
                            >
                                {children}
                                {innerTimes && (
                                    <div className="innerTimes">
                                        <ModalTimes
                                            bgTheme={bgTheme}
                                            onClose={onClose}
                                        />
                                    </div>
                                )}
                            </Content>
                        </FlexItem>
                    </Flex.Container>
                </ContainerModal>
            </Overlay>
        </AnimatePresence>
    ) : null;
};
