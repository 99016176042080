import React, { useState } from "react";
import P from "../../typography/P";

const StaticLabelDropdown = ({
    label,
    items,
    Icon,
    menuClass = "",
    handleSelection = () => {},
}) => {
    const [selectedItem, setSelectedItem] = useState(null);
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);
    const handleItemClick = (item) => {
        setSelectedItem(item);

        setIsOpen(false);
    };

    return (
        <div className="relative inline-block text-left w-56">
            <div>
                <div
                    role="button"
                    className={`inline-flex items-center justify-between w-48   px-1 py-1    hover:font-semibold ${
                        isOpen ? "font-semibold" : "font-medium"
                    }`}
                    onClick={() => toggleDropdown()}
                >
                    <P
                        className={`${
                            isOpen ? "font-semibold" : "font-medium"
                        }`}
                    >
                        {label}
                    </P>
                    <P>{Icon}</P>
                </div>
            </div>

            {isOpen && items && (
                <div
                    className={`origin-bottom-right z-50 absolute ${menuClass} right-0 mt-2 w-96 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                >
                    <div
                        className="py-0.5"
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                    >
                        {items.length > 1 ? (
                            items.map((item) => (
                                <div
                                    key={item}
                                    className={`block px-2 py-1 text-sm text-gray-700 hover:bg-gray-120 ${
                                        selectedItem === item && "bg-blue-400"
                                    } w-full text-left`}
                                    onClick={() => handleItemClick(item)}
                                >
                                    {item}
                                </div>
                            ))
                        ) : (
                            <div
                                className={`block px-2 py-1 text-sm text-gray-700 hover:bg-gray-120  w-full text-left`}
                                onClick={() => handleItemClick(items)}
                            >
                                {items}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default StaticLabelDropdown;
